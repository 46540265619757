@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  color: #fff !important;
  line-height: 1.2;
  background: #f3f6f9;
}


.bgOverlay>div,
.button-spotify-row img,
.item-1,
.item-3 {
  z-index: 1
}

.addVideoPopup .ant-input-prefix,
.cartBox .bold,
.cartBoxTop .bold,
.status,
form .hs-button {
  font-weight: 700
}

h3,
h4,
h5 {
  color: #132638
}

.auth-copyrights ul,
.dailyBudgetBottom .srv-validation-message,
.discussion-box .box-content h5,
.resultShow .right-section-dashboard .two-cta .submit-btn,
.videoCheckboxClass .budgetForm>div div:first-child,
body,
h1,
h3,
h4,
h5,
h6 {
  margin: 0
}

h1,
h2,
h6 {
  color: #000;
  color: var(--site-primary-color)
}

.ColorPickerDiv,
.ant-upload-select,
.bgOverlay,
.bgOverlay .smart-link-mibile-bottom,
.bgOverlay>div,
.leptop-device-preview-right-section,
.relative,
.smart-link-right-row-mibile,
.title-section .ant-upload-list,
.top-profile-layout,
.validation-raw,
.video-box.video-contant-position,
body .layout-left .top-left a,
body .layout-right .footer-main .footer-box,
html body .overlay-match {
  position: relative
}

.item-2,
.item-3,
.loader,
.slick-slider .slick-next {
  right: 0
}

.chat-input,
.global-form .ant-checkbox {
  outline: 0 !important
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub,
body .layout-left {
  min-width: initial !important
}

.ant-menu .cta-btn,
.cta-btn.text-capitalize,
.second-menu .ant-menu li a {
  text-transform: capitalize
}

.ant-menu-submenu-title,
.auth-copyrights.sidebarFooter a,
.client-says-section h1 span.badge,
.default-cta,
.fbLoginButton,
.global-form .submit-btn,
.headerCta a,
.iframeBottom,
.shiftToTop,
.resultTabMain button,
.status,
form .hs-button,
html body .exportData,
html body .withSidebar {
  text-transform: uppercase
}

.chat-input,
.form-input-height select {
  text-overflow: ellipsis;
  white-space: nowrap
}


:root {
  --site-font-family: "Heebo", sans-serif;
  --site-font-size: 16px;
  --site-primary-color: #000;
  --site-secondary-color: #848d9c;
  --site-line-height: 28px;
  --site-primary-Bgcolor: #fbfbfb;
  --section-padding: 110px 0px 118px;
  --h1-font-size: 34px;
  --h2-font-size: 30px;
  --h3-font-size: 35px;
  --h4-font-size: 22px;
  --h5-font-size: 25px;
  --h6-font-size: 18px
}

.websiteFontFamily,
.apexcharts-text tspan {
  font-family: 'Heebo', sans-serif !important;
  font-weight: 300 !important;
}

.common-table .ant-table-tbody .ant-space .ant-space-item:first-child,
.customSelect .ant-select-selection-overflow>div,
.my-facebook-button-class .fa {
  margin-right: 10px !important
}

.customSelect .ant-select-multiple .ant-select-selection-item {
  background: #2ea5c2;
  color: #fff !important;
  border-radius: 5px !important;
  overflow: hidden !important;
  line-height: 19px !important
}

html body .customSelect .ant-select-selector {
  padding: 5px !important
}

.ant-menu .ant-menu-item.mobile-show,
.customSpin+.soundlike,
.email_error,
.title-section .ant-upload-list-item-card-actions.picture,
.title-section .ant-upload-list-item-name,
body>iframe,
form .hs-error-msgs,
form .hs-form-required,
html body .no-social-links {
  display: none !important
}

.title-section.filed-disable .user-image,
.tool-boxes .single-tool-box:hover .tool-img img.hover,
.two-cta.subscription-buuton-two,
a,
form .hs-button,
html .title-section .user-image {
  display: inline-block
}

* {
  box-sizing: border-box;
  list-style: none;
  outline: 0
}

body {
  font-family: "Heebo", sans-serif !important;
  font-family: var(--site-font-family) !important;
  font-size: 16px !important;
  font-size: var(--site-font-size) !important;
  color: #848d9c !important;
  color: var(--site-secondary-color) !important;
  line-height: 28px !important;
  line-height: var(--site-line-height) !important;
  overflow-x: hidden;
  background: #f8f7f8 !important
}

.search-form .ant-btn,
h6,
strong {
}

.topHeaderClass+div {
  padding: 100px 0 0
}

.wrapper {
  margin: 0 auto !important;
  width: 1170px;
  max-width: 100%;
  float: none !important
}

.popup-main,
.popup-main.small,
form fieldset {
  max-width: 100% !important
}

.before-login li.new-header-profile {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important
}

h1 {
  font-size: 34px;
  font-size: var(--h1-font-size);
  line-height: 40px;
  letter-spacing: .42px
}

.default-cta span,
.search-form .ant-btn,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal
}

h2 {
  font-size: 30px;
  font-size: var(--h2-font-size);
  letter-spacing: 1px;
  padding-bottom: 56px
}

h3 {
  font-size: 35px;
  font-size: var(--h3-font-size)
}

h4 {
  font-size: 22px;
  font-size: var(--h4-font-size)
}

h5 {
  font-size: 25px;
  font-size: var(--h5-font-size)
}

h6 {
  font-size: 18px;
  font-size: var(--h6-font-size)
}

.mainmenu li a,
a {
  color: #3c3c3c
}

h1,
h2,
h5,
h6 {
  clear: both;
  margin-bottom: 15px !important;
  font-weight: 700 !important
}

h3 {
  clear: both;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
  line-height: unset !important;
}

h4
 {
  clear: both;
  margin-bottom: 15px !important;
  font-weight: 300 !important
}

a {
  text-decoration: none;
  outline: 0;
  transition: .4s ease-in-out
}

.default-cta,
.global-form .submit-btn,
.sameTransition,
button,
input[type=submit] {
  -webkit-transition: .4s ease-in-out;
  -moz-transition: .4s ease-in-out;
  -o-transition: .4s ease-in-out
}

p {
  float: left;
  width: 100%;
  margin: 10px 0
}

.auth-form .Collapsible:first-child .Collapsible__trigger.is-open,
.career-page-main p+p,
.feature-tools-bg p+p,
.smart-link-left .social-iocn-top-row .toggle,
h1+p,
h2+p,
h3+p,
h4+p,
h5+p,
h6+p {
  margin-top: 0
}

.left-auto {
  float: left;
  width: auto;
  margin: 0
}

.right-auto,
.white-bg .insight-listing-content .blog-pagination {
  float: right;
  width: auto;
  margin: 0
}

.addVideoPopup .ant-modal-content,
.biolink-listingPage .view-edit-delete-row.new-listing-page,
.chart-box>div>div,
.full-width,
.level-one,
.popup-main .ant-modal-body h1,
.single-post-page h1,
.smart-link-new-listing-page-row,
form .hs_recaptcha {
  float: left;
  width: 100%
}

.ant-picker-cell-disabled::before,
.avatar-upload .avatar-edit input,
.bg-img,
.child-triggerm,
.global-popup .srv-validation-message,
.leftMenu ul li.search-box.ant-menu-item-selected:before,
.leptop-image-none .leptop-device-preview-top-img img,
.marquee-logos .overlay,
.marquee-logos .overlay::after,
.marquee-logos .overlay::before,
.preview-left .bgimg-main img,
.resultShow .right-dashboard-action h2,
.select-smart-link select option[value=""][disabled],
.select-smart-link::after,
.slick-slider button.slick-arrow::before,
.smart-link-left .social-iocn-top-row .toggle input[type=checkbox],
.smart-link-right.mobile-view-show-preview,
.step-4 .form-square-checkbox .ant-checkbox,
.sub-menu,
.title-section .user-image,
.title-section.filed-disable .upload-image-section,
.tool-boxes .single-tool-box .tool-img img.hover,
.tool-boxes .single-tool-box:hover .tool-img img.default,
.white-bg .ant-pagination-options,
ul li a.remove-data-hover:after,
ul li a.remove-data-hover:before {
  display: none
}

.bgimg-main {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important
}

.bgOverlay:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(14, 9, 9, .7)
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.flex-center.align-top {
  align-items: flex-start
}

.flex-center.flex-start {
  justify-content: flex-start
}

.biolink-eyeview-laptop-screen .preview-title,
.biolink-eyeview-laptop-screen .preview-title h5,
.biolink-eyeview-laptop-screen .preview-title p,
.biolink-eyeview-mobile-screen .preview-title,
.biolink-eyeview-mobile-screen .preview-title h5,
.biolink-eyeview-mobile-screen .preview-title p,
.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.biolink-listingPage .smart-link-new-listing-page-row,
.cartBox .videoCheckboxClass .budgetForm>div div,
.global-form .form-field.small input,
.slick-slider ul,
.smart-link-mibile-bottom.secend-section-mibile p,
.text-center,
body .layout-right .footer-main .footer-box.footer-logo,
form .hs-button {
  text-align: center
}

ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0
}

form fieldset {
  float: left;
  margin: 5px 0;
  width: 100%;
  border: 0;
  padding: 0
}

form .form-columns-1 input,
form .form-columns-2 input {
  height: 65px;
  border-radius: 10px;
  border-color: #d0d0d0
}

.white-bg .ant-pagination-next,
form .form-columns-1 .input,
form .form-columns-2 .input {
  margin-right: 0 !important
}

.addVideoPopup .global-form .video-step-content-inner .ant-select-selector,
.after-scroll-add-bottom .privacy-policy-mobile,
.after-scroll-add-bottom .smart-link-mibile-bottom,
.after-scroll-add-bottom .social-smart-link-tab,
.cartBox .budgetFormBottom>div:first-child,
.soundlike .ant-select-selector,
.video-box iframe,
form .form-columns-1 .input input,
form .form-columns-1 .input select,
form .form-columns-1 .input textarea,
form .form-columns-2 .input input,
html body .cta-btn:hover:after,
html body .resultShow .left-section-dashboard .half-form-field.half-form-field-new .form-field>div {
  width: 100% !important
}

.biolink-step-content-2 .smart-link-left,
.discussion-box.questions,
.global-form .have-accnt a:hover:after,
.global-form .recovery-text:hover:after,
.itune-change-right,
.smart-link-new-listing-page-box-left-img a,
form .form-columns-2 {
  width: 100%
}

form .hs-fieldtype-booleancheckbox .input input {
  width: auto !important;
  height: auto !important
}

form .form-columns-2 .hs-form-field:first-child {
  padding-right: 10px !important;
  padding-left: 0 !important
}

form .form-columns-2 .hs-form-field:last-child {
  padding-left: 10px !important;
  padding-right: 0 !important
}

form .form-columns-2 .hs-form-field {
  float: left;
  width: 50% !important
}

form .form-columns-1 .hs-form-field {
  padding-right: 0 !important
}

form .hs-button {
  color: #fff;
  background: #25c9da;
  padding: 8px 20px
}

form .input input.error {
  background: #f66 !important
}

.hs-blog-post input[type=email],
.hs-blog-post input[type=text],
.hs-blog-post textarea,
input[type=email],
input[type=tel],
input[type=text],
select,
textarea {
  border: 1px solid #dadadb
}

.hs-blog-post input[type=submit] {
  background: #86bd3b;
  color: #fff;
  padding: 8px 16px;
  margin: 20px 0
}

button,
input,
textarea {
  border: none;
  outline: 0
}

button,
input[type=submit] {
  cursor: pointer;
  transition: .4s ease-in-out
}

input[type=submit]:hover {
  color: #fff;
  background: #1996ff
}

ul.hs-error-msgs {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%
}

.ant-menu .ant-menu-item.right-align.login,
.ant-menu .ant-menu-item.right-align.signup,
.loader-off .loader {
  opacity: 0;
  visibility: hidden
}

.loader-off body {
  opacity: 1;
  visibility: visible
}

.loader {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto
}

.mr-5 {
  margin-right: 5px;
}

.ant-checkbox-group+.srv-validation-message,
.chat-sending-box,
.mt-20,
.right-align {
  margin-top: 20px
}

.item {
  width: 100px;
  height: 100px;
  position: absolute
}

.item-1 {
  background-color: #fa5667;
  top: 0;
  left: 0;
  -webkit-animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-1_move;
          animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-1_move
}

.item-2 {
  background-color: #7a45e5;
  top: 0;
  -webkit-animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-2_move;
          animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-2_move
}

.item-3 {
  background-color: #1b91f7;
  bottom: 0;
  -webkit-animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-3_move;
          animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-3_move
}

.item-4 {
  background-color: #fac24c;
  bottom: 0;
  left: 0;
  -webkit-animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-4_move;
          animation: 1.8s cubic-bezier(.6, .01, .4, 1) infinite item-4_move
}

@-webkit-keyframes item-1_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px)
  }

  50% {
    -webkit-transform: translate(100px, 100px);
            transform: translate(100px, 100px)
  }

  75% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0)
  }
}

@keyframes item-1_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px)
  }

  50% {
    -webkit-transform: translate(100px, 100px);
            transform: translate(100px, 100px)
  }

  75% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0)
  }
}

@-webkit-keyframes item-2_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0)
  }

  50% {
    -webkit-transform: translate(-100px, 100px);
            transform: translate(-100px, 100px)
  }

  75% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px)
  }
}

@keyframes item-2_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0)
  }

  50% {
    -webkit-transform: translate(-100px, 100px);
            transform: translate(-100px, 100px)
  }

  75% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px)
  }
}

@-webkit-keyframes item-3_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px)
  }

  50% {
    -webkit-transform: translate(-100px, -100px);
            transform: translate(-100px, -100px)
  }

  75% {
    -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0)
  }
}

@keyframes item-3_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px)
  }

  50% {
    -webkit-transform: translate(-100px, -100px);
            transform: translate(-100px, -100px)
  }

  75% {
    -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0)
  }
}

@-webkit-keyframes item-4_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0)
  }

  50% {
    -webkit-transform: translate(100px, -100px);
            transform: translate(100px, -100px)
  }

  75% {
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px)
  }
}

@keyframes item-4_move {

  0%,
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
  }

  25% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0)
  }

  50% {
    -webkit-transform: translate(100px, -100px);
            transform: translate(100px, -100px)
  }

  75% {
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px)
  }
}

.slick-slider button.slick-arrow {
  outline: 0;
  opacity: .5;
  border: 0;
  position: absolute;
  top: 50%;
  z-index: 1;
  font-size: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%) !important;
  cursor: pointer
}

.leptop-device-preview-row:after,
.slick-slider .slick-prev {
  left: 0
}

.slick-slider button.slick-arrow::after {
  position: absolute;
  left: 0;
  top: 0;
  font-family: fontAwesome;
  font-size: 30px;
  color: #fff
}

.slick-slider .slick-prev:after {
  content: "\f104"
}

.slick-slider .slick-next:after {
  content: "\f105";
  right: 0 !important
}

.slick-slider button.slick-arrow:hover,
.toolInfo:hover .toolText {
  opacity: 1
}

.slick-slider ul {
  padding: 0;
  width: 100%;
  position: relative;
  margin: 20px 0;
  z-index: 11
}

.slick-slider ul.slick-dots>li {
  display: inline-block;
  width: auto;
  margin: 0 3px
}

.slick-slider ul.slick-dots>li>button {
  background: #fff !important;
  border-radius: 100%;
  font-size: 0;
  height: 8px;
  width: 8px;
  padding: 0;
  opacity: .5
}

.ant-select-arrow *,
.slick-slider ul.slick-dots>li>button:before,
.video-steps-tab-title a.active span:after,
.video-steps-tab-title a.active~a span:after,
.videoCheckboxClass .ant-checkbox-checked .ant-checkbox-inner,
.videoCheckboxClass .ant-checkbox-input,
body .layout-right .footer-main .footer-box:first-child:after,
input[type=checkbox],
input[type=radio] {
  opacity: 0
}

.slick-slider ul.slick-dots>li.slick-active>button {
  background: #2ea5c2;
  width: 8px;
  border-radius: 20px;
  opacity: 1
}

.search-form {
  position: relative;
  left: 0;
  top: 0
}

.search-form .ant-input {
  width: 0;
  padding: 0;
  border: 0;
  border-radius: 20px;
  background: #fff !important;
  font-style: italic;
  position: absolute;
  right: 55px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.leftMenu ul li.search-box:after {
  width: 0 !important
}

.leftMenu ul li.search-box.ant-menu-item-selected {
  transition: none !important
}

.search-form.show .ant-input {
  width: 150px;
  padding: 5px 15px;
  font-size: 12px
}

html body .search-box {
  overflow: visible !important
}

.srv-validation-message {
  color: red;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 1px
}

.search-form .ant-btn {
  padding: 0 25px;
  height: 20px;
  box-shadow: none !important;
  border: 0 !important;
  border-right: 2px solid #fff !important;
  color: #fff !important;
  background: 0 0 !important;
  font-size: 18px
}

html body .search-box a {
  border-right: 1px solid #fff !important;
  padding: 0 25px;
  font-size: 18px
}

.cta-btn.ant-btn {
  line-height: 24px
}

html body .cta-btn:after {
  width: 0% !important;
  left: 0 !important;
  top: 0 !important;
  bottom: auto !important;
  content: "" !important;
  height: 100% !important;
  background: #fff !important;
  transition: .7s;
  padding: 0 !important;
  display: block !important
}

.ant-menu.ant-menu-horizontal>li .cta-btn:after,
.commonDD ul,
.marquee-logos .banner-slider-main,
.social-smart-link-tab,
html body .ant-table-column-sorters,
html body .default-dropdown.commonDD ul {
  padding: 0 !important
}

html body .cta-btn.gray {
  background: #848d9c !important;
  border-color: #848d9c !important
}

html body .cta-btn.gray:hover,
html body .cta-btn.gray:hover span {
  color: #848d9c !important
}

.pickerDivRight,
html body .cta-btn~.cta-btn {
  margin-left: 10px !important
}

.cta-btn span,
html body .overlay-match>div {
  position: relative;
  z-index: 1
}

.cta-btn.white,
.cta-btn:hover {
  color: #5d45e8
}

.cta-btn.white {
  background-color: #fff !important
}

.cta-btn.white:before {
  background: #5d45e8
}

.cta-btn.white:hover {
  color: #fff !important;
  border-color: #fff !important
}

.cta-btn.default:hover,
.cta-btn.default:hover span {
  color: #5d45e8 !important
}

.ant-menu .cta-btn {
  padding: 4px 12px !important
}

.cta-btn.default {
  padding: 5px 25px 3px;
  color: #fff;
  border: 1px solid #5d45e8 !important
}

.cta-btn.default span {
  line-height: normal;
  color: #fff
}

.biolink-preview .smart-link-mibile-bottom,
.cta-btn.default:hover,
.dark .add-biolink-dark-bg .smart-link-mibile-bottom,
html body input.ant-input {
  background: 0 0 !important
}

.cta-btn.default.small {
  padding: 2px 15px;
  font-size: 12px;
  height: 26px;
  line-height: normal
}

.title-section h2 {
  color: #1d2a3b;
  padding: 0;
  margin-bottom: 10px !important
}

.title-section {
  color: #848d9c;
  padding-bottom: 20px
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #fff !important;
  font-size: 18px
}

html body .animated-image img {
  -webkit-animation: 3s ease-in-out infinite alternate zoominoutsinglefeatured !important;
  animation: 3s ease-in-out infinite alternate zoominoutsinglefeatured !important
}

@keyframes zoominoutsinglefeatured {

  0%,
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1)
  }

  50% {
    -webkit-transform: scale(1.08, 1.08);
            transform: scale(1.08, 1.08)
  }
}

@-webkit-keyframes zoominoutsinglefeatured {

  0%,
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1)
  }

  50% {
    -webkit-transform: scale(1.08, 1.08);
            transform: scale(1.08, 1.08)
  }
}

.sub-menu li {
  float: left;
  width: 100%;
  position: relative
}

.sub-menu li a {
  padding: 10px 15px;
  display: block
}

.banner-right img,
.simple-left img {
  -webkit-animation: 3s ease-in-out infinite alternate bannerImgBounce !important;
  animation: 3s ease-in-out infinite alternate bannerImgBounce !important
}

@-webkit-keyframes bannerImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }
}

@keyframes bannerImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }
}

.popup-main.small {
  width: 320px !important
}

.biolink-eyeview-laptop-screen .preview-title p,
.biolink-eyeview-mobile-screen .preview-title p,
.engagement-bottom.noBottomMaegin,
.popup-main.small h1,
.resultShow .chart-box .graph-content h5,
.smart-link-mibile-bottom.secend-section-mibile .smart-link-mibile-bottom.secend-section-mibile p,
.smart-link-mibile-bottom.secend-section-mibile .smart-title-section p,
html body .discussion-box.questions .box-content h5,
html body .popup-main .Collapsible:last-child .margin-bottom-zero {
  margin-bottom: 0 !important
}

.popup-main {
  border-radius: 5px;
  background: #fff;
  box-shadow: -11px 11px 71px rgba(176, 182, 197, .25);
  width: 420px !important
}

.popup-main .ant-modal-body {
  float: left;
  width: 100%;
  padding: 25px 40px 15px
}

.popup-main .ant-modal-close {
  width: 35px;
  height: 35px;
  box-shadow: -11px 11px 71px rgb(176 182 197 / 25%);
  border-radius: 100%;
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -15px;
  top: -15px;
  color: #000
}

.my-facebook-button-class {
  background-color: #3b5998 !important;
  color: #fff;
  box-shadow: none;
  border: 0;
  border-radius: 5px;
  padding: 0 10px
}

.layout-right .video-steps-tab-title.smart-link-page a span:after,
.popup-main .ant-modal-close .ant-modal-close-x {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.popup-main .anticon,
.single-post-page .post-image {
  height: auto !important
}

.or-div span {
  background: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 1
}

.ant-modal-wrap {
  z-index: 9999999999999999999999999999999
}

.common-table {
  overflow: auto
}

.common-table .ant-table-thead tr th {
  background: #f2f2f2;
  color: #000;
}

.common-table .ant-table-tbody tr td,
.discussion-box.questions .box-content .question-comment>div a,
.discussion-box.questions .box-content h5 a,
.step2-biolink-social-inner .library span,
.title-section h2 a:hover {
  color: #000
}

.common-table .ant-table-tbody tr td button {
  background-color: #5d45e8;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 25px;
  color: #fff;
  min-width: 60px;
  text-align: center;
  height: 26px;
  line-height: 27px;
  padding: 0 10px;
  margin: 0 !important;
  font-size: 13px
}

.chat-sending-box .flex-start img,
.sender {
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .35)
}

.common-table .ant-table-tbody .ant-space .ant-space-item a {
  color: #2ec850;
  font-size: 18px
}

.ant-menu-submenu ul li:hover,
.button-spotify-box-deezer,
.common-table .ant-table-tbody tr td button:hover,
.fixed .barsBtn,
.fixed .barsBtn:after,
.fixed .barsBtn:before {
  background: #000
}

.common-table .ant-table tfoot>tr>td,
.common-table .ant-table tfoot>tr>th,
.common-table .ant-table-tbody>tr>td,
.common-table .ant-table-thead>tr>th {
  padding: 10px 16px;
  line-height: 20px
}

html body .common-table .ant-pagination li {
  width: 25px;
  min-width: initial;
  font-size: 14px;
  height: 25px;
  line-height: 23.5px
}

.common-table .ant-pagination li.ant-pagination-item-active {
  background-color: #5d45e8;
  color: #fff;
  border-color: #5d45e8
}

.about-us-get-starte-button:active span,
.about-us-get-starte-button:focus span,
.ant-picker-header-view button:hover,
.biolink-add-btn .default-cta span,
.common-table .ant-pagination li.ant-pagination-item-active a,
.coomig-soon-smart-link h2,
.coomig-soon-smart-link p,
.dashBoardright li a,
.find-more-btn>span,
.global-form .submit-btn,
.global-form .submit-btn.transparent:hover,
.global-popup .ant-modal-close .ant-modal-close-x:hover,
.global-popup .ant-modal-close:hover,
.layout-right .add-video-link:hover,
.resultShow .ant-select-arrow,
.resultShow .right-dashboard-action ul li a,
.single-post-page .auth-copyrights.relative ul li a i,
.smart-link-new-listing-man-row .add-video-link,
.smart-link-new-listing-man-row h2,
.tool-boxes .single-tool-box:hover,
.tool-boxes .single-tool-box:hover h5,
.tool-boxes .single-tool-box:hover p,
html body .resultShow .ant-picker-separator,
html body .resultShow .ant-picker-suffix {
  color: #fff
}

html body .ant-menu .ant-menu-item.overflow-visible {
  overflow: visible
}

html body .default-dropdown .ant-dropdown ul li {
  float: left !important;
  width: 100% !important;
  margin: 0 !important
}

.light-social-media-section .social-media-div a,
.light-social-media-section .social-media-div a img,
.pickerDivRight .ColorPickerInner,
.pickerDivRight .itune-change-left,
.preview-light-theme .social-smart-link-tab a img,
.preview-light-theme .social-smart-link-tab img,
.second-menu .ant-menu li,
.testimonial-bottom h5,
.toolInfo .insight-bar.exportButton,
.toolInfo img,
html body .commonDD ul li,
html body .default-dropdown.commonDD ul li {
  margin: 0 !important
}

html .commonDD ul li a,
html body .default-dropdown.commonDD ul li a {
  border-bottom: 1px solid #32343f;
  padding: 5px 15px;
  float: left;
  width: 100%;
  color: #141414 !important;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: uppercase;
}

.commonDD ul li:last-child a,
.contentBottom .row:last-child,
.dashBoardright li:last-child,
html .ant-menu-horizontal,
html body .default-dropdown.commonDD ul li:last-child a {
  border: 0
}

html body .default-dropdown .ant-dropdown ul li a {
  white-space: normal;
  margin: 0 !important;
  font-family: "Heebo", sans-serif !important;
  font-family: var(--site-font-family) !important;
  display: block;
  float: left;
  width: 100%
}

html body .exportData {
  text-align: left;
  color: #fff !important;
  font-size: 13px;
  padding: 0;
  border: 0;
  background: 0 0;
  font-weight: 300;
  letter-spacing: 1px;
}

.graph-content span,
.videoCheckboxClass .budgetForm>div span,
html body .exportData i {
  margin-left: 5px
}

.auth-copyrights.sidebarFooter a:hover,
.cartBox .cartBoxBottom a:hover,
.depecheMode button.ant-dropdown-open,
.headerCta:hover a,
.status.active,
.top-profile-layout ul li a:hover,
.videoAddTop .right,
.videoAddTop button.ant-dropdown-open,
html body .exportData:hover {
  color: #33ffff !important
}

.status.not-active,
.videoCheckboxClass .budgetForm .fa-pause,
.videoCheckboxClass .budgetForm>div span.de-active {
  color: red
}

.ant-menu-submenu ul li {
  float: left;
  width: 100%;
  line-height: normal !important;
  height: auto !important;
  padding: 10px 12px !important;
  margin: 0 !important;
  border-bottom: 1px solid #999 !important
}

.ant-menu-submenu ul li:last-child {
  border-bottom: 0 !important
}

.ant-menu-submenu ul li a {
  padding: 0;
  line-height: normal !important
}

.ant-menu-submenu-title {
  color: #fff !important;
  position: relative;
  padding: 7px 20px 5px 15px !important
}

.ant-menu-submenu ul li:hover a,
.ant-picker-next-icon,
.ant-picker-panels .ant-picker-cell-inner,
.ant-picker-panels .ant-picker-content thead tr th,
.ant-picker-panels .ant-picker-header-view,
.ant-picker-panels .ant-picker-super-next-icon,
.ant-picker-panels .ant-picker-super-prev-icon,
.ant-picker-prev-icon,
.cartBox .cartBoxBottom a,
.commonDD ul li a,
.feature-tools-bg h2,
.feature-tools-bg h5,
.feature-tools-bg p,
.layout-right .smart-link-left .label,
.layout-right .smart-link-left h3,
.layout-right .smart-link-left h5,
.layout-right .smart-link-left p,
.layout-right .video-steps-tab-title.smart-link-page a,
.preview-light-theme .light-theme-title h5,
.preview-light-theme .light-theme-title p,
.smart-link-mibile-bottom.secend-section-mibile .smart-link-mibile-bottom.secend-section-mibile p,
.smart-link-mibile-bottom.secend-section-mibile .smart-title-section h5,
.smart-link-mibile-bottom.secend-section-mibile .smart-title-section p,
.top-profile-layout ul li a {
  color: #fff !important
}

.ant-menu-submenu-popup {
  width: 160px !important
}

.second-menu .ant-menu li a {
  color: #fff;
  padding: 7px 15px 5px !important;
  font-size: 16px
}

.second-menu .ant-menu li.ant-menu-item-selected a {
  background-color: #5d45e8 !important;
  border-radius: 25px !important;
  -webkit-transform: none !important;
          transform: none !important
}

.second-menu .ant-menu li.ant-menu-item-selected,
.second-menu .ant-menu li.ant-menu-item-selected:hover,
ul li a.remove-data-hover {
  -webkit-transform: none !important;
          transform: none !important
}

.chat-sending-box .flex-start img {
  width: 50px;
  object-fit: initial !important
}

.chat-sending-box .flex-start .chat-form {
  font-size: 16px;
  font-family: "Segoe UI Bold";
  margin-left: 15px;
  position: relative;
  margin-bottom: 0;
  width: calc(100% - 42px)
}

.flex-start {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100%
}

.right-align {
  float: right
}

.sender {
  width: 50px;
  min-width: 50px
}

.chat-sending-box .chat-btn:hover {
  -webkit-transform: translate(5px, -50%);
          transform: translate(5px, -50%)
}

html body .chat-sending-box .attach-btn {
  position: absolute;
  right: 55px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.chat-input {
  border: 1px solid #d8d8d8 !important;
  font-size: 14px !important;
  background: 0 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: #000;
  font-family: GraphikRegular;
  background-color: transparent !important;
  padding: 12px 15px !important;
  box-shadow: none !important
}

.chat-input.invalid {
  background: #ffe6e6 !important
}

html body .DraftEditor-editorContainer {
  height: 100px;
  font-family: GraphikRegular;
  font-size: 14px
}

.mb-10,
.upload-title {
  margin-bottom: 10px
}

html body .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent
}

.choose-file-main,
.discussion-box.flex,
.singleLine {
  display: flex
}

.choose-file-main>div {
  width: 250px;
  max-width: 100%
}

.ant-select-arrow:after {
  content: "\f0d7";
  font-family: fontAwesome;
  top: -5px;
  right: -9px;
  position: absolute;
  font-size: 17px
}

.richtext-editor {
  margin-top: 4px
}

html body .ant-upload-list-item-name {
  color: #999 !important
}

.two-cta {
  display: flex;
  flex-wrap: wrap;
  margin: 7px 0 0 !important
}

.radio-span,
.two-cta button {
  margin-right: 15px
}

.loader-image {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

html body .popup-main .Collapsible:last-child .full-width.form-field {
  margin-bottom: 5px
}

html body .popup-main .Collapsible:last-child .full-width.form-field.payment-subtext {
  margin-bottom: 22px
}

.auth-form .Collapsible:last-child {
  border-bottom: 0;
  padding-bottom: 0
}

.Collapsible__trigger {
  cursor: pointer;
  transition: .4s;
  padding-right: 15px;
  color: #000;
  font-size: 18px
}

.Collapsible {
  padding: 10px 0;
  border-bottom: 1px solid #d8d8d8
}

.Collapsible,
.Collapsible__contentOuter,
.Collapsible__trigger {
  float: left;
  width: 100%;
  position: relative
}

.Collapsible__trigger:after {
  position: absolute;
  line-height: normal;
  right: 0;
  top: 14px;
  font-family: fontAwesome;
  content: "\f0d7";
  color: #000;
  transition: .4s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.Collapsible__trigger.is-open {
  margin-top: 16px
}

.Collapsible__trigger.is-open:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: -10px
}

.Collapsible .auth-form {
  margin: 15px 0
}

html body .popup-main .Collapsible .margin-bottom-zero {
  margin: 0 0 20px !important
}

.Collapsible__contentInner,
.layout-left .top-main-logo,
.step2-biolink-email-form {
  margin-top: 10px
}

.radio-span {
  position: relative;
  font-size: 15px;
  margin-bottom: 15px;
  display: inline-block
}

.radio-span input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer
}

input[type=checkbox]+label,
input[type=radio]+label {
  cursor: pointer;
  padding: 1px 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  color: #000;
  min-width: 140px;
  display: inline-block;
  text-align: center;
  text-shadow: 1px 1px 0 transparent;
  transition: .4s;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center
}

input[type=checkbox]:checked+label,
input[type=radio]:checked+label {
  background: #5d45e8;
  border: 1px solid #5d45e8;
  color: #fff
}

.dailyBudgetBottom,
.mt-10 {
  margin-top: 15px
}

.global-form .have-accnt,
.leftSidebarMenu .top-right {
  margin-bottom: 20px
}

.global-form,
.global-form .ant-radio-wrapper,
.global-form .have-accnt {
  color: #848d9c !important;
  font-size: 14px
}

.global-form .ant-input {
  border-radius: 7px;
  font-weight: 600;
  color: #000 !important;
  font-size: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08);
  border: 0 !important;
  height: 45px;
  padding: 4px 20px;
  background: #fff !important
}

.default-cta,
.global-form .submit-btn {
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0 25px !important;
  height: 42px !important;
  min-width: 110px;
  line-height: 35px !important;
  text-align: center;
  border-radius: 7px;
  border: 0 !important;
  background-image: linear-gradient(100deg, #027fc5 -62%, #92fbbc 193%) !important;
  color: #fff !important
}

.global-form .submit-btn.transparent {
  background: 0 0;
  box-shadow: none !important;
  color: #027fc5;
  border: 1px solid #027fc5 !important
}

.default-cta:hover,
.global-form .submit-btn:hover,
.how-it-works-right button:hover,
.titleWithCta .find-more-btn:hover {
  background: #2ea5c2;
  color: #fff
}

.global-form .ant-radio-inner::after {
  background: #027fc5
}

.global-form .ant-radio-checked .ant-radio-inner {
  border-color: #027fc5
}

.global-form .form-field {
  margin: 0 0 15px
}

.global-form .have-accnt a,
.global-form .recovery-text {
  color: #000;
  font-weight: 700;
  position: relative
}

.global-form .ant-input-password {
  border-radius: 7px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  border: 0 !important;
  height: 45px;
  padding: 4px 20px;
  background: #fff !important
}

.global-form .ant-input-password .ant-input {
  box-shadow: none !important;
  margin-top: -4px;
  position: relative;
  background: 0 0
}

.auth-form .ant-select,
.global-form .form-field .ant-input-password .ant-input:focus,
.global-popup .ant-select-selector .ant-select,
.innerpage-body .ant-select-selector .ant-select {
  box-shadow: none !important
}

.global-form .ant-checkbox-inner {
  border-radius: 100%
}

.ant-checkbox-checked::after,
.step2-biolink-social-inner {
  border: 0 !important
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #848d9c !important
}

.global-form .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #027fc5;
  background: #2ea5c2
}

.auth-copyrights {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 80px 60px
}

.auth-copyrights a {
  color: #848d9c !important;
  margin-right: 10px;
  transition: .4s
}

.cartBox .videoCheckboxClass .budgetForm>div:last-child,
.client-says-section .banner-updated-slider-main,
.pt-0,
html body .eyePage .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-right-section .smart-link-mibile-bottom,
html body .light-mobile-device {
  padding-top: 0 !important
}

.addVideoPopup.payment h5,
h1.mb-10 {
  margin-bottom: 10px !important
}

.auth-copyrights ul li {
  list-style: none;
  float: left;
  width: auto
}

.auth-copyrights ul li a {
  width: 32px;
  height: 32px;
  background-color: #fff;
  text-align: center;
  line-height: 32px;
  font-size: 17px;
  margin-right: 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #848d9c;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%) !important
}

.auth-copyrights ul li a i {
  position: relative;
  color: #848d9c;
  transition: .5s;
  z-index: 3
}

.auth-copyrights ul li a:hover i {
  color: #fff;
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg)
}

.auth-copyrights ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .5s;
  z-index: 2;
  background: #2ea5c2
}

.auth-copyrights ul li a:hover:before {
  top: 0
}

.global-form .have-accnt a:after,
.global-form .recovery-text:after {
  position: absolute;
  transition: .4s;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0%;
  background: #2ea5c2
}

.auth-copyrights.relative {
  background: #000;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  padding: 10px 0 !important
}

.auth-copyrights.relative ul li a {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 14px
}

.common-padding {
  padding: 50px 0
}

.common-padding.pt-40 {
  padding: 40px 0 50px
}

html body .title-section h2 {
  padding: 0;
  margin: 0 !important
}

html body .title-section h2+p {
  margin-top: 15px;
  margin-bottom: 0
}

.global-form .label {
  color: #848d9c !important;
  margin-bottom: 4px;
  font-size: 15px
}

.mt-100 {
  margin-top: 100px !important
}

.global-form .label.bold {
  font-weight: 600;
  color: #1d2a3b !important;
  padding-bottom: 5px;
  font-size: 15px
}

.global-form .form-checkbox .ant-checkbox-wrapper {
  width: 100%;
  line-height: normal;
  float: left;
  margin: 0 0 10px;
  color: #848d9c !important
}

.dailyBudgetBottom .ant-radio-inner,
.global-form .form-square-checkbox .ant-checkbox-inner,
.large.ColorPickerDiv .rc-color-picker-trigger {
  border-radius: 3px
}

.video-steps-tab-title a.active~a span {
  background: #fff;
  color: #848d9c;
  font-size: 15px
}

.video-steps-tab-title a span {
  font-size: 0;
  position: relative;
  background: #2ea5c2;
  color: #fff
}

.video-steps-tab-title a span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: fontAwesome;
  content: "\f00c";
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 14px
}

.graph-content,
.video-steps-tab-title a.active span {
  font-size: 15px
}

.add-video-link {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  transition: .4s;
  min-width: 150px;
  padding-left: 15px
}

.add-video-link img {
  max-width: 22px;
  margin-right: 5px
}

.add-video-link .fa,
.engagement-bottom>div .fa {
  margin-right: 5px
}

.add-video-link:hover {
  font-weight: 600;
  color: #000
}

.no-video-found {
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 15px
}

.no-video-found img {
  margin: 10px 0 20px;
  max-width: 200px
}

.global-form .form-checkbox.width-auto .ant-checkbox-wrapper {
  width: auto;
  margin: 5px 10px 5px 0
}

.global-form .form-field.small .input-edit-btn,
.title-section .input-edit-btn {
  background: #000;
  display: inline-block;
  padding: 0;
  font-size: 12px;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  color: #fff
}

.global-form .form-field.small {
  width: 80px;
  text-align: center;
  position: relative;
  margin-top: 5px
}

.global-form .form-field.small .input-edit-btn {
  position: absolute;
  right: -10px;
  top: -5px;
  z-index: 1
}

.white-bg .ant-pagination {
  padding: 25px 0 10px
}

.white-bg .ant-pagination-next,
.white-bg .ant-pagination-prev {
  border-radius: 10px;
  border: 1px solid #027fc5;
  color: #027fc5;
  margin: 0 10px !important
}

.white-bg .ant-pagination-next a,
.white-bg .ant-pagination-prev a {
  color: #027fc5;
  min-width: 110px;
  text-transform: uppercase;
  font-weight: 600 !important;
  border-radius: 0;
  letter-spacing: 1px
}

.white-bg .ant-pagination li a {
  border: 1px solid #027fc5 !important;
  font-size: 12px !important;
  color: #027fc5;
  font-weight: 500;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center
}

.white-bg .ant-pagination li {
  height: auto;
  border: 0;
  margin: 0
}

.white-bg .ant-pagination-item-ellipsis {
  color: #027fc5 !important;
  left: -2px !important
}

.white-bg .ant-pagination li.ant-pagination-item a {
  border: 0 !important;
  border-top: 1px solid #027fc5 !important;
  border-right: 1px solid #027fc5 !important;
  border-bottom: 1px solid #027fc5 !important;
  border-radius: 0 !important
}

html .white-bg .ant-pagination .ant-pagination-prev+li a {
  border-left: 1px solid #027fc5 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px
}

.white-bg .ant-pagination li.ant-pagination-jump-next-custom-icon+li a {
  border-right: 1px solid #027fc5 !important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px
}

.white-bg .ant-pagination li.ant-pagination-item-active a {
  background: #027fc5;
  color: #fff;
  position: relative;
  z-index: 1
}

.white-bg .ant-pagination-prev,
ul.before-login>li.ant-menu-submenu {
  margin-left: 0 !important
}

#artistDropdown ul li,
.ant-drawer-body,
.resultShow .input-feild-dashboard-desceiption,
.step-4 .form-square-checkbox .ant-checkbox+span,
.title-section.pb-0,
.video-step-content-inner .videoStepsContent,
.wrapper.smart-link-listing-page {
  padding: 0
}

.filed-disable,
.filed-disable.input-hide input,
.global-form .step-4 .form-checkbox .ant-checkbox-wrapper,
.no-hover,
.not-active,
.resultTabMain button.not-active,
.topDiv.filed-disable .label+input {
  cursor: not-allowed;
}

.step-4 .video-step-right .video-text {
  line-height: normal;
  padding: 15px !important
}

.resultShow .right-section-dashboard,
.step-4 .video-step-right .video-text h4 {
  margin-top: 0 !important
}

.step-4 .video-step-right h4 {
  margin: 15px 0 7px !important
}

.preview-title,
.title-section.pb-0.pt-30 {
  padding-top: 10px
}

.title-section .ant-upload-list-item {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  height: auto !important
}

.title-section .ant-upload-list-item-thumbnail img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 100%;
  max-width: initial !important;
  object-fit: cover
}

.global-form .form-field.mb-30 {
  margin-bottom: 20px !important
}

.global-form .ant-input-group-addon {
  padding: 0;
  width: 65px;
  background: #fff !important;
  height: 100%;
  box-shadow: none !important;
  border: 0 !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11
}

.cartBoxHeight .scrollbarsDiv,
.global-form .ant-select-selector .ant-select-selection-search input,
.video-box iframe {
  height: 100% !important
}

.global-form .ant-input-group-addon:after {
  position: absolute;
  right: 0;
  height: 50%;
  content: "";
  background: #848d9c;
  width: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.global-form .ant-input-group-addon+.ant-input {
  padding-left: 78px
}

.global-form .ant-input-group-addon .ant-select {
  width: 100% !important;
  height: 100%;
  margin: 0
}

.global-form .ant-select-selector {
  width: 40px !important;
  padding: 0 !important;
  height: 100% !important
}

.global-form .ant-select-selection-item {
  font-weight: 700;
  color: #000;
  line-height: 43px !important;
  padding: 0 0 0 10px !important;
  width: 100% !important
}

.global-form .ant-select-arrow {
  right: -1px;
  top: 22px
}

.global-form .ant-select-arrow>span {
  top: -1px;
  position: relative
}

.title-section .ant-upload-select {
  position: absolute;
  right: 0;
  z-index: 1111;
  bottom: 0
}

.title-section .ant-upload-list-picture-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px !important;
  background: #fff;
  height: 80px !important;
  border-radius: 100%
}

.title-section .ant-upload-list-picture-container:last-child {
  z-index: 111
}

.filed-disable.input-hide,
.title-section.filed-disable,
.topDiv.filed-disable {
  pointer-events: initial
}

.global-popup .ant-input {
  border: 0;
  border-bottom: 1px solid #a2a2a2;
  padding-left: 0 !important;
  font-family: CambriaBold
}

.global-popup .ant-input:hover {
  border-bottom-color: #a2a2a2
}

.global-popup .ant-input:focus {
  box-shadow: none !important;
  outline: 0
}

.global-popup .ant-input.invalid {
  border-bottom: 1px solid red
}

.auth-form .ant-select-selector,
.global-popup .ant-select-selector,
.innerpage-body .ant-select-selector {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  cursor: pointer;
  box-shadow: none !important;
  border: 0 !important;
  border-bottom: 1px solid #a2a2a2 !important
}

.auth-form .ant-select-selector:focus,
.auth-form .ant-select-selector:hover,
.global-popup .ant-select-selector:focus,
.global-popup .ant-select-selector:hover,
.innerpage-body .ant-select-selector:focus,
.innerpage-body .ant-select-selector:hover {
  box-shadow: none !important;
  border: 0 !important;
  border-bottom: 1px solid #a2a2a2 !important
}

.global-popup .label {
  float: left;
  width: 100%;
  color: #000;
  font-size: 15px;
  margin-bottom: 5px;
  font-family: CambriaBold
}

.ant-modal.global-popup {
  padding: 0;
  max-width: 90%
}

.ant-modal.global-popup.large {
  width: 691px !important
}

.ant-modal.global-popup.extra-large {
  width: 800px !important;
  font-size: 16px
}

.ant-modal.global-popup .ant-modal-body {
  padding: 0;
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden
}

.global-popup h1 {
  font-size: 26px;
  margin: 0
}

.global-popup .popup-title {
  padding: 12px 20px;
  border-bottom: 1px solid #c9c9c9
}

.global-popup .popup-content {
  padding: 15px 20px 20px
}

.global-popup .popup-content h6 {
  font-style: initial;
  margin: 0 0 15px
}

.global-popup .star-image {
  margin: -8px 0 20px
}

.global-popup .gradient-btn {
  margin-top: 15px;
  min-width: 120px;
  letter-spacing: 1px
}

.global-popup textarea.ant-input {
  padding-left: 10px !important;
  min-height: 80px;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px
}

.global-popup .ant-modal-close {
  background: #000;
  color: #fff;
  border-radius: 100%;
  top: 34px;
  right: 35px
}

.global-popup .ant-modal-close .ant-modal-close-x {
  width: 30px;
  height: 30px;
  line-height: 31px
}

.global-popup .ant-modal-body {
  padding: 30px 35px !important
}

.upload-image-section {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  position: relative
}

.video-box .left p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.default-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px
}

.graph-content span,
.no-video-height,
.single-line-field .ant-picker .ant-picker-input input {
  color: #000;
  font-weight: 600
}

.div-width-auto {
  float: left;
  width: auto
}

.addVideoPopup .video-steps-tab-title button.active,
.ant-picker-header-view button,
.global-form .form-field.small .prefix-label {
  font-weight: 600
}

.global-form .form-field.small .prefix-label input {
  padding: 4px 10px !important;
  margin-left: 10px;
  border: 1px solid #848d9c !important
}

ul.before-login li a.default-cta {
  height: 35px !important;
  color: #fff !important
}

.title-section .fees-btn {
  background: #000;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  min-width: 100px;
  margin: 0 0 5px;
  text-align: center;
  padding: 5px
}

.about-us-get-starte-button:active,
.about-us-get-starte-button:focus,
.ant-picker-panels,
.budgetForm .submit-btn:hover,
.default-cta:focus,
.desktopBgPreviewBtn:hover,
.fees-btn:hover,
.publish-button button:hover,
.social-iocn-top-row .ant-switch-checked,
.video-steps-tab-title.smart-link-page button:hover {
  background: #2ea5c2
}

.dailyBudgetBottom h5,
.title-section.pb-10 {
  padding-bottom: 10px
}

.global-popup h3,
.single-post-modal.how-it-work-light-box h3 {
  border-bottom: 1px solid #132638;
  padding-bottom: 15px;
  margin-bottom: 25px !important;
  padding-right: 40px
}

.underline {
  text-decoration: underline
}

.contentBottom,
.font-14 {
  font-size: 14px
}

.ml-15 {
  margin-left: 15px
}

.color-blue {
  color: #027fc5
}

.disc-list-style {
  padding-left: 15px
}

.disc-list-style,
.disc-list-style li {
  list-style-type: disc
}

.global-popup .ant-modal-body {
  font-size: 16px;
  color: #848d9c
}

.smart-link-main .video-step-right img {
  width: 400px;
  max-width: 100%
}

.blog-view-page,
.mt-50,
.payment-approve-modal .submit-btn {
  margin-top: 30px
}

.form-field .ant-input {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: .4s
}

.chart-box>div,
.content-result-box {
  border-radius: 10px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, .08);
  background: #fff
}

.form-field .ant-input~.focus-border:after,
.form-field .ant-input~.focus-border:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #027fc5;
  transition: .3s
}

.form-field .ant-input~.focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0
}

.form-field .ant-input~.focus-border i:after,
.form-field .ant-input~.focus-border i:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #027fc5;
  transition: .4s
}

.form-field .ant-input~.focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0
}

.form-field .ant-input:focus~.focus-border:after,
.form-field .ant-input:focus~.focus-border:before {
  width: 100%;
  transition: .3s
}

.form-field .ant-input:focus~.focus-border i:after,
.form-field .ant-input:focus~.focus-border i:before {
  height: 100%;
  transition: .4s
}

.single-line-field .label {
  width: auto !important;
  padding-right: 15px
}

.half-form-field-new.half-form-field>.form-field {
  width: auto !important
}

.single-line-field .default-dropdown {
  min-width: 140px
}

.single-line-field .default-dropdown .ant-btn {
  height: 32px !important;
  border-radius: 5px;
  color: #000;
  font-weight: 600;
  margin: 0 !important
}

.global-form .form-field.mt-30 {
  margin-top: 20px !important
}

.chart-box {
  margin: 20px 0 30px
}

.chart-box>div {
  padding: 15px;
  margin-right: 30px;
  width: 350px;
  max-width: 100%
}

html body .default-dropdown#video_dd .ant-dropdown ul li {
  text-align: left;
  padding: 0 !important
}

.content-result-box {
  padding: 30px 40px 40px
}

.title-section h2 a {
  color: #027fc5;
  font-weight: 600;
  margin-right: 15px;
  font-size: 25px;
  position: relative;
  top: -2px
}

.w-50 {
  width: 50%
}

.w-50 .video-box {
  width: 400px !important
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  float: left
}

.footer-section {
  margin-top: auto
}

.chart-box .graph-content {
  padding-left: 16px
}

.chart-box .graph-content h4 {
  margin: 0 0 5px !important
}

.cursor-none,
.pointer-none a {
  cursor: not-allowed;
  opacity: .7
}

.single-line-field .ant-select {
  width: 150px !important;
  border: 1px solid #d9d9d9 !important
}

.single-line-field .ant-select .ant-select-selector {
  width: 100% !important;
  border: 0 !important;
  border-radius: 5px !important
}

.global-form .single-line-field .ant-select-selection-item {
  line-height: 32px !important
}

.global-form .single-line-field .ant-select-arrow {
  top: 16px
}

.result-chart-box {
  margin: 20px 0 10px;
  width: 380px !important;
  max-width: 90% !important;
  padding: 0 0 20px;
  border-top: 0 solid #ccc;
  border-bottom: 1px solid #ccc
}

.pl-20 {
  padding-left: 20px
}

.global-popup .ant-modal-body ul {
  margin-bottom: 15px !important
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff !important
}

.ant-picker-content td:hover .ant-picker-cell-inner,
.ant-picker-content th:hover .ant-picker-cell-inner,
.topDiv .have-accnt a,
.video-steps-tab-title.smart-link-page a {
  color: #000 !important
}

.ant-picker-range-arrow::after {
  border-color: #027fc5 #027fc5 transparent transparent
}

.user-left.full-width {
  width: 100% !important;
  margin: 0 0 25px
}

.single-post-modal .ant-modal-body {
  float: left;
  width: 100%;
  background: #fff
}

.single-post-modal {
  width: 700px !important;
  max-width: 90% !important
}

.single-post-modal .ant-modal-close-x {
  background: #2ea5c2;
  border-radius: 100%;
  -webkit-transform: scale(.5);
          transform: scale(.5);
  color: #fff
}

.single-post-modal .anticon-close {
  font-size: 25px;
  position: relative;
  top: 3px
}

html body .banner-updated-section .slider-left {
  width: 100%;
  padding-right: 0
}

html body .banner-updated-section .slider-left h1 span {
  color: #56c8bf
}

html body .banner-updated-section .div-width-auto {
  float: none;
  width: auto;
  margin: 10px auto 0;
  display: inline-block
}

.banner-updated-slider-main .slick-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 20px !important;
  opacity: 0
}

.banner-updated-slider-main .slick-slide.slick-active {
  justify-content: flex-end;
  opacity: 1
}

.banner-updated-slider-main .slick-slide.slick-active.slick-center+.slick-slide.slick-active {
  justify-content: flex-start;
  opacity: 1
}

.banner-updated-slider-main .slick-slide.slick-active.slick-center {
  justify-content: center;
  opacity: 1
}

.banner-updated-slider-main .slick-slide>div {
  -webkit-transform: scale(.5);
          transform: scale(.5);
  opacity: .2;
  transition: -webkit-transform .3s cubic-bezier(.4, 0, .2, 1);
  transition: transform .3s cubic-bezier(.4, 0, .2, 1);
  transition: transform .3s cubic-bezier(.4, 0, .2, 1), -webkit-transform .3s cubic-bezier(.4, 0, .2, 1)
}

.banner-updated-slider-main .slider-image img {
  max-width: 700px
}

.banner-updated-slider-main .slick-center>div,
.client-says-section .banner-updated-slider-main .slick-slide>div {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1
}

.feature-tools-bg {
  padding: 60px 0;
  margin-top: 30px;
  background: #000;
  color: #fff
}

html body .banner-updated-logo-section h5 {
  font-size: 25px;
  color: rgba(255, 255, 255, .6);
  padding-bottom: 40px !important
}

.marketing-tools-main.common-padding {
  padding-top: 70px
}

html body .banner-updated-slider-main {
  padding: 70px 0 40px !important
}

html body .banner-updated-logo-section .slick-slide {
  padding: 0 15px !important
}

.marquee-logos .slider-image img,
html body .banner-updated-logo-section .slick-slide img {
  width: 125px;
  height: 40px;
  object-fit: contain
}

.tool-boxes {
  text-align: left;
  display: flex;
  flex-wrap: wrap
}

.tool-boxes .single-tool-box h5 {
  margin: 35px 0 10px !important;
  display: block;
  float: left;
  width: 100%
}

.tool-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px
}

.tool-img img {
  max-width: 100%;
  max-height: 100%
}

.tool-boxes .single-tool-box {
  text-align: center;
  float: none;
  display: inline-block;
  margin: 28px 25px -3px 0;
  width: 31.5%;
  padding: 40px 32px 25px;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  box-shadow: rgb(0 0 0 / 6%) 0 0 10px 0;
  background-color: #fff;
  border-radius: 8px;
  vertical-align: top
}

.dashBoardSocialTab button:last-child,
.smart-link-new-listing-page-box:nth-child(2n),
.social-smart-link-tab a:nth-child(7) img,
.tool-boxes .single-tool-box:nth-child(3n) {
  margin-right: 0
}

.tool-boxes .single-tool-box:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  color: #fff;
  background: #2ea5c2
}

.feature-tools-bg h5 {
  font-size: 24px
}

.feature-tools-bg p {
  opacity: .5
}

.full-width.small-btn {
  display: flex;
  justify-content: center;
  margin: 50px 0 0
}

.feature-main .feature-left,
.feature-main .feature-right {
  float: left;
  width: 48%
}

.feature-main .feature-right {
  text-align: left;
  width: 450px;
  max-width: 100%
}

.client-says-section .banner-updated-slider-main .slider-image img,
.feature-main .feature-left img {
  max-width: 100%
}

.feature-main>.feature-raw:nth-child(odd),
.how-it-works-contant>.how-it-works-contant-raw:nth-child(odd) {
  flex-direction: row-reverse
}

.feature-main>.feature-raw:nth-child(2n) {
  padding: 100px 0
}

.feature-main .feature-right ul li {
  position: relative;
  padding-left: 22px;
  margin-bottom: 20px
}

.feature-main .feature-right ul li::before {
  position: absolute;
  left: 0;
  top: 0;
  background: #2ea5c2;
  width: 15px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 23px;
  content: "";
  font-family: fontAwesome;
  content: "\f058"
}

.client-says-section h1 {
  color: #fff !important;
  text-align: center;
  padding-bottom: 20px
}

.client-says-section h1 span.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: -3px;
  border-radius: 17px;
  padding: 0 16px
}

.client-says-section h1 span.badge:hover {
  cursor: pointer !important
}

.client-says-section.common-padding.banner-section.banner-updated-section {
  padding-bottom: 25px
}

.client-says-section .banner-updated-logo-section {
  margin-top: 25px
}

.client-says-section .banner-updated-slider-main .slick-slide {
  justify-content: initial !important
}

.testimonial-content {
  text-shadow: rgb(0 0 0 / 0%) 1px 1px 1px;
  padding: 100px 100px 50px;
  background: #fff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #132638;
  position: relative
}

.testimonial-content:after {
  position: absolute;
  left: 60px;
  top: 50px;
  font-family: fontAwesome;
  content: "\f10d";
  color: #56c8bf
}

.testimonial-bottom {
  margin-top: 50px
}

.client-says-section .slick-dots {
  bottom: 0;
  display: none !important
}

.cartBoxTop>div,
.mb-15 {
  margin-bottom: 15px
}

.marquee-logos .slider-image {
  padding: 0 35px
}

.marquee-logos .slider-image.client-logos-home-page img {
  width: 160px
}

.tools-bottom-main {
  margin: 50px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column
}

.tools-bottom-main .div-width-auto {
  display: flex;
  justify-content: center;
  margin: 15px 0 0
}

.career-page-main {
  padding: 40px 0
}

.add-video-link.large {
  font-size: 25px
}

.insight-listing {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
  flex-direction: column
}

.insight-listing-content {
  max-width: 100%;
  width: 573px;
  display: flex;
  position: relative;
  flex-direction: column;
  left: -10px;
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: hidden
}

.cartBoxBottom button .fa,
.headerCta a .fa,
.menuIcon {
  margin-right: 10px
}

.cartBox .cartBoxBottom button img,
.menuIcon img {
  max-width: 20px
}

.insight-listing-content .left,
.insight-listing-content .right {
  width: 50%;
  float: left;
  padding: 5px 15px
}

.insight-listing-content>div {
  width: 100%;
  text-align: left
}

.contentBottom .row {
  border-bottom: 1px solid #fff
}

.ColorPickerInnerLeft,
.contentBottom .row .left {
  border-right: 1px solid #fff
}

.insight-listing-content .title {
  background: #fff;
  color: #141414;
  font-weight: 700
}

.privacy-policy-mobile ul,
.sidebarFooter.auth-copyrights ul,
.threeButton {
  display: flex;
  align-items: center;
  justify-content: center
}

.insight-bar {
  background: #2ea5c2;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  width: 28px;
  height: 28px;
  display: inline-block
}

.insight-bar span {
  display: inline-block;
  width: 3px;
  height: 45%;
  position: absolute;
  left: 7px;
  bottom: 6px;
  background: #fff
}

body .layout-left,
html body .layout-right {
  background: #000
}

.insight-bar span:nth-child(2) {
  height: 60%;
  left: 13px
}

.insight-bar span:nth-child(3) {
  height: 35%;
  left: 19px
}

.insight-listing-content.emailList-pagination {
  border: 0;
  justify-content: flex-end
}

.white-bg .insight-listing-content .blog-pagination .ant-pagination-item-active {
  border-color: #92fbbc !important
}

.white-bg .insight-listing-content .blog-pagination .ant-pagination li {
  display: inline-block;
  margin: 5px 0 0 8px !important
}

.white-bg .insight-listing-content .blog-pagination .ant-pagination .ant-pagination-item a {
  color: rgba(0, 0, 0, .85) !important;
  margin: 0 !important;
  width: 35px !important;
  height: 35px !important
}

.fbLoginButton {
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0 25px !important;
  height: 42px !important;
  min-width: 110px;
  line-height: normal !important;
  text-align: center;
  transition: .8s;
  border-radius: 7px;
  border: 0 !important
}

.fbLoginButton .fa {
  margin-right: 7px !important
}

.social-buttons {
  flex-wrap: wrap;
  justify-content: flex-start
}

.social-buttons>span {
  margin: 0 10px
}

.dashBoardSocialTab button:first-child,
.social-buttons>span+span,
.social-smart-link-tab a:first-child img,
.step-1-push-btn .publish-button {
  margin-left: 0
}

.social-buttons .recovery-text {
  margin-left: auto
}

.sqrbx .anticon {
  cursor: pointer;
  transition: .2s ease-in-out;
  z-index: 1;
  font-size: 14px;
  position: relative;
  left: 5px;
  bottom: 5px
}

.ant-layout-header .anticon:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused
}

body .layout-header {
  z-index: 111;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  padding: 20px 40px 20px 0 !important;
  background: #000
}

body .layout-header+div {
  padding-top: 73px
}

.layout-header-left {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  line-height: normal
}

body .layout-right .layout-right-padding {
  padding-right: 40px
}

body .layout-left {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1111;
  flex: initial !important;
  max-width: initial !important;
  width: 270px !important
}

body .layout-left.ant-layout-sider-collapsed {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%)
}

.ant-layout-sider-collapsed+.layout-right .footer-section.withSidebar,
.ant-layout-sider-collapsed+.layout-right .layout-right-padding {
  padding-left: 52px
}

.layout-right .wrapper {
  width: 100% !important;
  padding: 0
}

.menuIcon {
  min-width: 25px;
  display: inline-block;
  text-align: center;
  font-size: 18px
}

.layout-header::before {
  display: none;
  top: 15px;
  left: -72px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.sqrbx {
  content: "";
  border-radius: 0 9px;
  width: 40px;
  height: 40px;
  background: #33ffff;
  position: absolute;
  display: flex;
  top: 20px;
  right: -20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  align-items: center;
  justify-content: center
}

.sqrbx .anticon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #141414
}

.ant-layout.main-pages,
.leftSidebarMenu,
body .layout-left .top-section,
body .layout-left .top-section-inner {
  min-height: 100vh
}

html body .withSidebar {
  letter-spacing: 12px;
  color: #2ea5c2 !important;
  text-align: center;
  padding-bottom: 23px;
  padding-top: 23px
}

.ant-select-dropdown {
  z-index: 9999999999
}

.withSidebar .auth-copyrights.relative {
  padding-left: 40px !important;
  margin-left: -40px;
  width: calc(100% + 40px);
  padding-right: 40px !important
}

.ant-layout-sider-collapsed+.layout-right .withSidebar .auth-copyrights.relative {
  margin-left: -50px;
  padding-left: 50px !important
}

body .leftSidebarPadding {
  padding-left: 320px !important
}

.ant-layout-sider-collapsed+.layout-right .leftSidebarPadding {
  padding-left: 52px !important
}

.default-cta,
.global-form .submit-btn,
.sameTransition {
  transition: .4s ease-in-out
}

.headerCta {
  margin-left: 10px;
  position: relative;
  padding-bottom: 40px !important;
  margin-bottom: -40px
}

.headerCta a {
  display: inline-block;
  color: #fff;
  font-weight: 400;
  border-radius: 20px;
  padding: 7px 0 10px 20px
}

.headerCta a.headerLoginBtn:hover {
  background: #141414
}

.headerCta a.headerLoginBtn,
html body .white-bg .insight-listing-content .blog-pagination .ant-pagination-item-active.ant-pagination-item a {
  background: #2ea5c2;
  color: #fff !important
}

.default-cta:hover,
.global-form .submit-btn:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  box-shadow: rgba(17, 12, 46, .15) 0 48px 100px 0
}

body .layout-right .footer-main {
  background: 0 0;
  font-weight: 400 !important;
  color: #33ffff !important
}

.layout-right1 .smart-link-new-listing-man-row .add-video-link,
.layout-right1 .smart-link-new-listing-man-row h2,
.layout-right1 .smart-link-new-listing-page-box-right-contant a,
.layout-right1 .smart-link-new-listing-page-box-right-contant h5,
.layout-right1 .smart-link-new-listing-page-box-right-contant p,
body .layout-right .footer-main .footer-box a {
  color: #141414
}

body .layout-right .footer-main .footer-box a:hover {
  color: #2ea5c2
}

.top-section-inner {
  display: flex;
  flex-direction: column
}

.blog-listing-main .post-box .post-content h4,
.detailsContentLeft,
.resultTabMain,
.video-step-main.smart-link-fix-scroll-img {
  justify-content: space-between;
  display: flex
}

.auth-copyrights.sidebarFooter {
  margin-top: auto;
  padding: 0;
  color: #fff;
  position: relative;
  line-height: normal;
  font-size: 14px;
  text-align: center
}

.auth-copyrights.sidebarFooter a {
  margin: 0 10px;
  letter-spacing: 2px;
  color: #fff !important;
  font-size: 15px
}

.sidebarFooter.auth-copyrights ul li a {
  margin: 0 3px 12px;
  -webkit-transform: scale(.9);
  transform: scale(.9)
}

body .layout-left .top-section-inner {
  padding-bottom: 40px
}

body .layout-right .footer-main .footer-box:after {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  content: "";
  width: 1px;
  height: 120px;
  background: #ccc
}

#artistDropdown ul li:hover,
.layout-right .smart-link-new-listing-man-row,
.layout-right .smart-link-new-listing-page-box,
.videoAdd button,
html .ant-layout,
html body .resultShow .left-section-dashboard .half-form-field.half-form-field-new .form-field>div,
html body .resultShow .single-line-field .ant-select .ant-select-selector {
  background: 0 0
}

.layout-right1 .biolink-listingPage .smart-link-new-listing-page-box,
.layout-right1 .smart-link-new-listing-page-box {
  box-shadow: rgba(0, 0, 0, .24) 0 3px 8px
}

.layout-right .view-edit-delete-row img,
.layout-right .view-edit-delete-row.new-listing-page img {
  box-shadow: rgb(50 50 93 / 25%) 0 2px 5px -1px, rgb(0 0 0 / 30%) 0 1px 3px -1px;
  border-radius: 100%
}

.dashBoardTop .dashBoardleft {
  float: left;
  width: calc(100% - 320px);
  padding-right: 30px
}

.resultTabMain {
  margin: 20px 0
}

.resultTabMain button {
  width: 24%;
  letter-spacing: 2px;
  padding: 7px 5px;
  background: 0 0;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 300;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  opacity: 0 !important;
  display: none !important;
  visibility: hidden !important;
}

.resultTabMain button.active {
  border-color: #33ffff;
  font-weight: 900 !important;
  color: #33ffff !important;
}

.resultTabMain button:hover {
  color: #33ffff;
  border-color: #33ffff
}

.detailsVideo {
  width: 30%
}

.detailsVideo iframe {
  height: 180px !important
}

.detailsVideoText {
  width: 68%;
  float: left
}

.detailsVideoText .notactive {
  color: #FF0000;
}

.detailsVideoText small {
  margin: 0 4px;
  font-size: 100%;
  position: relative;
  top: -1px;
}

.detailsVideoText .active {}

.iframeBottom {
  margin-top: 10px;
  line-height: 22px
}

html body .videoCheckboxClass {
  height: auto !important;
}

.resultShow .form-field.flex-start {
  width: 100% !important;
  margin-top: 0 !important
}

.dashBoardTop .dashBoardright {
  float: left;
  width: 320px;
  max-width: 100%;
  background: #141414;
  color: #fff;
  padding: 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  min-height: calc(100vh - 150px)
}

.detailsContent {
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between
}

.linkIconsMain,
.videoAdd .scrollbarsDiv>div:first-child {
  flex-wrap: wrap;
  display: flex
}

.cartBox,
.detailsContentRight button {
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase
}

.detailsContent .big,
.large.ColorPickerDiv .ColorPickerInner .fa,
.single-post-page .post-content h5 {
  font-size: 16px
}

.detailsContentRight button {
  background: 0 0;
  margin-top: 5px !important;
  margin-left: 15px !important;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  padding: 3px 15px;
  margin: 17px 0 0;
  min-width: 100px
}

.detailsContentRight button:hover {
  background: #33ffff;
  color: #141414;
  border-color: #33ffff;
  font-weight: 700
}

.linkIconsMain {
  float: left;
  width: 100%
}

.linkIcons {
  margin-left: 5px;
  min-width: 45px
}

.linkIcons i {
  margin-left: 7px;
  font-size: 12px
}

.linkIcons a {
  color: #33ffff;
  cursor: pointer
}

.saveButton button {
  background: 0 0;
  border: 1px solid #33ffff;
  color: #33ffff
}

#artistDropdown ul,
.top-profile-layout ul {
  color: #141414;
  background: #33ffff;
  min-width: 150px;
  padding: 8px 12px 5px
}

.detailsContent .small {
  font-size: 11px;
  line-height: 20px
}

.samePadding.videoAdd {
  padding: 15px 20px 20px;
  color: #fff
}

.resultShow .chart-box .graph-content h4,
.resultShow .chart-box .graph-content h5 {
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300 !important
}

.engagement-bottom span,
.resultShow .chart-box .graph-content h4 {
  color: #33ffff;
  font-weight: 700 !important
}

.resultShow .chart-box>div {
  background: 0 0;
  padding: 15px 0 0
}

.resultShow .chart-box .apexcharts-menu-icon svg,
.resultShow .chart-box text {
  fill: #fff !important
}

.resultShow .right-dashboard-action,
.resultShow .right-dashboard-action div {
  height: auto;
  color: #fff
}

.resultShow .input-feild-dashboard-desceiption,
.resultShow .input-feild-dashboard-desceiption a,
.resultShow .input-feild-dashboard-desceiption p {
  color: #fff;
  border: 0
}

.resultShow .right-dashboard-action {
  min-height: 330px;
  padding: 15px;
  background: 0 0;
  border: 1px solid #fff;
  border-radius: 0;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column
}

.resultShow .right-section-dashboard .two-cta {
  justify-content: flex-end
}

.box-content-bg {
  background: #000;
  border-radius: 5px;
  padding: 7px 12px 10px
}

.right-section-dashboard .engagement-bottom.bottom {
  padding: 0;
  margin: 10px 0 0;
  flex-wrap: wrap
}

.engagement-bottom {
  display: flex;
  margin: 10px 0 -10px;
  padding: 0 10px
}

.engagement-bottom.discussionBottom>div {
  display: flex;
  align-items: flex-start;
  line-height: 15px
}

.cartBox,
.top-profile-layout ul {
  z-index: 9999;
  top: 50px;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  position: absolute
}

.scrollbarsDiv {
  overflow-x: hidden !important
}

.scrollbarsDiv>div:last-child>div {
  background: #fff !important
}

.engagement-bottom>div {
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center
}

.cartBoxBottom button:hover,
.dashBoardSocialTab button.active,
.dashBoardSocialTab button:hover,
.default-cta,
.global-form .submit-btn,
.playPaush button,
.stepsNav button:hover {
  background: #33ffff;
  color: #141414
}

.top-profile-layout ul {
  left: 0;
  transition: .3s
}

.cartBox {
  background: #000723;
  padding: 15px 20px;
  color: #fff;
  text-align: right;
  right: 0;
  width: 310px;
  transition: .3s;
  opacity: 0;
  visibility: hidden
}

.cartBoxTop {
  border-bottom: 1px solid #32343f;
  padding-bottom: 5px
}

.cartBoxBottom,
.cartBoxBottom button {
  padding: 20px 0 0;
  color: #33ffff;
  font-weight: 700 !important
}

.cartBoxBottom button {
  background: 0 0;
  border: 1px solid #33ffff;
  border-radius: 0;
  color: #33ffff;
  padding: 5px 15px;
  margin: 17px 0 0
}

.top-profile-layout ul li {
  margin-bottom: 8px !important
}

.top-profile-layout .profile-circle-new {
  cursor: pointer;
  padding-bottom: 40px !important;
  margin-bottom: -40px;
  display: flex;
  align-items: center;
  color: #fff;
  width: auto !important;
  height: auto !important
}

.top-profile-layout .profile-circle-new span {
  margin: 0 10px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase
}

.depecheMode+.headerCta.NewheaderCta a {
  letter-spacing: 2px;
  min-width: 85px;
}

.headerCta.NewheaderCta a {
  font-weight: 300;
}

.top-profile-layout:hover .profile-circle-new,
.videoCheckboxClass .budgetForm .fa-play,
.videoCheckboxClass .budgetForm>div div span,
.videoCheckboxClass .budgetForm>div span.active {
  color: #33ffff
}

.leftSidebarMenu ul li a .menuText {
  letter-spacing: 1.5px;
}

.top-profile-layout .profile-circle-new img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: contain;
  border: 1px solid #fff;
}

.videoAddTop button:focus {
  color: #fff !important;
}

.headerCta:hover .cartBox,
.top-profile-layout:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0
}

.countParent {
  position: relative;
}

.countParent .count {
  position: absolute;
  text-indent: 2px;
  right: 0;
  top: 0;
  border-radius: 100%;
  background: #33ffff;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #141414;
  font-weight: 700;
}

.layout-header-left .fa,
.leptop-device-preview-man-row .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box a,
.single-post-page .post-content h3,
.video-steps-tab-title.smart-link-page a.active~a span,
p.subscribe-client-say {
  font-size: 20px
}

.layout-header-left .fa.fa-cart-plus,
.single-post-page .post-content h4 {
  font-size: 18px
}

.biolink-listingPage .url-copy-row,
.dashBoardSocialTab {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.dashBoardSocialTab {
  flex-wrap: nowrap;
  justify-content: space-between
}

.dashBoardSocialTab button {
  background: #fff;
  margin: 5px;
  transition: .4s ease-in-out;
  width: 12%;
  font-size: 30px;
  padding: 9px 5px;
  color: #141414;
  display: flex;
  justify-content: center;
  align-items: center
}

.dashBoardSocialTab button img {
  max-width: 50px;
  max-height: 25px;
  object-fit: contain
}

.videoAdd {
  padding: 0 5px 10px 20px;
  min-height: 170px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: #141414;
  position: relative
}

.addIconbtn {
  color: #141414;
  font-size: 70px
}

.border-l-0 {
  border-left: 0
}

.border-lb {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc
}

.budgetForm>div {
  padding: 5px 15px;
  color: #141414;
  font-weight: 600 !important
}

.budgetText {
  min-width: 200px
}

.budgetForm .ant-input {
  border-top: 0;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  outline: 0;
  border: 0;
  border-color: #ccc !important
}

.budgetForm .submit-btn {
  height: initial;
  background: #141414;
  width: 5%;
  min-width: 50px;
  color: #fff;
  border: 0;
  font-size: 18px
}

.budgetFormMain {
  padding: 0 50px
}

.budgetForm .activeRaw,
.budgetForm .blank,
.budgetForm .budgetText {
  width: 27%;
  text-align: center
}

.budgetForm .singleLine {
  width: 14%
}

.videoCheckboxClass {
  width: 23.6%;
  min-width: 170px;
  margin-right: 15px !important;
  margin-left: 0 !important;
  margin-top: 10px;
  margin-bottom: 10px
}

.checkBoxVideoTop {
  line-height: 0;
  overflow: hidden;
  position: relative
}

.checkBoxVideoTop:after {
  opacity: 0;
  font-family: fontAwesome;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  content: "\f01d";
  color: #2ea5c2;
  font-size: 42px
}

.checked .checkBoxVideoTop:after {
  content: "\f04b";
  font-size: 30px
}

.checkBoxVideoTop iframe,
.checkBoxVideoTop video {
  width: 100% !important;
  height: 150px !important
}

.videoCheckboxClass .ant-checkbox {
  /* position: absolute; */
  left: 0;
  top: 0;
  margin-left: -1rem;
}

.videoCheckboxClass>span {
  padding: 0 !important;
  display: flex;
  flex-direction: column
}

.videoCheckboxClass .budgetForm {
  line-height: normal;
  font-size: 12px;
  padding: 10px 12px;
  letter-spacing: 1px
}

.videoCheckboxClass .budgetForm>div {
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  border: 0;
  text-transform: uppercase;
  font-weight: 300 !important
}

.mobile-image-none .button-spotify-row img,
.mobile-image-none .social-smart-link-tab img,
.playPaush button.active {
  display: block
}

.playPaush button {
  font-size: 16px;
  display: none;
  height: 22px;
  width: 24px;
  padding: 0 !important;
  transition: all 400ms ease-in-out;
  border: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.playPaush button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background: #33ffff;
  color: #141414;
}

.playPaush button:focus {
  background: #33ffff;
  color: #141414;
}

.budgetFormBottom {
  display: flex !important;
  flex-direction: row !important;
  align-items: center
}

.playPaush .ant-btn-loading-icon+.fa {
  opacity: 0;
}

.playPaush .ant-btn-loading-icon .anticon {
  padding: 0 !important;
  margin-left: 10px !important;
  -webkit-transform: scale(.8);
          transform: scale(.8);
  position: relative;
  top: 0.5px;
}

.playPaush .ant-btn-loading-icon svg {
  fill: #141414;
}

.playPaush .ant-btn::before {
  opacity: 1;
  background: transparent;
}

.budgetFormBottom>div {
  width: auto !important;
  margin-left: auto
}

.budgetFormBottom>div:first-child {
  width: 80% !important
}

.playPaush button .fa,
.playPaush button .fa-play {
  color: inherit !important
}

.budgetMiddle {
  margin: 3px 0
}

.button-spotify-box-appleMusic,
.playPaush button.pause {
  background: red
}

.videoCheckboxClass .budgetForm>div div span,
.videoCheckboxClass .budgetForm>div span {
  font-weight: 700 !important
}

.videoCheckboxClass .budgetForm>div div {
  width: 100%;
  float: left;
  border: 0;
  text-align: left;
  color: #fff
}

.videoCheckboxClass .budgetForm .submit-btn {
  padding: 0;
  color: #141414;
  width: auto;
  background: 0 0;
  min-width: initial
}

.videoCheckboxClass .budgetForm img {
  max-width: 15px;
  height: auto;
  object-fit: contain;
  margin: 0 0 0 10px
}

.videoCheckboxClass .budgetForm>div:first-child {
  width: 100% !important;
  border-right: 0 !important;
  border-bottom: 1px solid #ccc;
  padding-right: 0 !important;
  padding-top: 0 !important
}

.videoCheckboxClass .budgetForm>div:last-child {
  padding-left: 0 !important;
  width: 100% !important
}

.videoCheckboxClass .budgetForm .singleLine {
  padding-right: 5px
}

.videoAdd button.new span {
  font-size: 20px;
  text-transform: uppercase;
  margin: 20px 0 0
}

.videoAdd button.new img {
  max-width: 110px
}

.videoAdd button.new {
  margin: 10px 0;
  background: #fff;
  font-size: 90px;
  margin-left: 0 !important;
  border: 0
}

.dashBoardright ul {
  padding: 5px 10px 0 25px
}

.dashBoardright li,
.resultShow .right-dashboard-action ul li {
  line-height: 24px;
  margin: 0 0 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  position: relative
}

.dashBoardright li:after {
  position: absolute;
  content: "\f0c8";
  top: 0;
  left: -20px;
  font-family: fontAwesome;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #33ffff;
  font-size: 8px
}

.resultShow .right-dashboard-action ul li {
  border: 0;
  list-style: disc;
  margin: 0
}

.reactVideo {
  width: 100% !important;
  height: 100% !important
}

.editDetailsPopupModal .stepsNav {
  padding: 0;
  justify-content: center;
}

.leftShifted {
  margin-right: 354px;
}

.headerCta.NewheaderCta {
  margin-left: 0;
  position: fixed;
  width: auto;
  top: 16px;
  right: 40px;
  z-index: 999;
}

.headerCta.NewheaderCta a {
  float: right;
  display: inline-block;
  padding-left: 0;
  min-width: 77px;
}

.headerCta.NewheaderCta .cartBox a {
  font-size: inherit;
  padding-top: 0;
  font-weight: 300;
  float: initial;
}

.headerCta.NewheaderCta a img {
  max-width: 20px;
  margin-right: 10px;
}

.countParent {
  margin-right: 7px
}

.leftSidebarMenu .ant-menu li.ant-menu-item-active.not-active a,
.leftSidebarMenu .ant-menu li.ant-menu-item-selected.not-active a,
.auth-copyrights.sidebarFooter a.not-active,
html body .exportData:hover {
  color: #fff !important
}

.depecheMode.not-active button {
  cursor: not-allowed;
}

.dashBoardSocialTab button.not-active:hover {
  background: #fff;
}

.resultTabMain button.not-active:hover,
.depecheMode.not-active button.ant-dropdown-open {
  color: #fff !important;
  border-color: #fff !important;
}

.depecheMode.not-active .ant-dropdown {
  display: none !important;
}

.depecheMode.not-active .fa-caret-down {
  -webkit-transform: none !important;
          transform: none !important;
}

.videoCheckboxClass .ant-checkbox-input+.ant-checkbox-inner {
  opacity: 0 !important;
}

.dashBoardSocialTab button.not-active {
  opacity: .2;
  cursor: pointer;
  pointer-events: all;
}

.resultTabMain button.not-active,
.depecheMode button.not-active,
.leftSidebarMenu .ant-menu li.not-active,
.not-active,
.leftSidebarMenu .ant-menu li.not-active a,
.exportData {
  opacity: .2;
  cursor: not-allowed;
  pointer-events: none;
}

.left-section-dashboard .ant-select-selection-placeholder {
  color: #fff !important;
  padding-left: 10px !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 12px !important
}

.separator>span {
  position: relative;
  line-height: normal;
  padding-right: 5px;
  margin-right: 5px;
  display: inline-block;
}

.separator>span:after {
  position: absolute;
  right: 0px;
  bottom: 0;
  content: ",";
}

.separator>span:last-child:after {
  opacity: 0;
}

.layout-right .title-section h2 {
  color: #fff
}

.boldInfo {
  font-weight: 500 !important;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px !important;
  line-height: normal;
}

.layout-right .userProfileMainPage .video-steps-tab-title a.active {
  background: #141414;
  color: #fff;
}

.layout-right .userProfileMainPage .white-bg {
  background: #141414;
  border-radius: 0;
}

.layout-right .userProfileMainPage .global-form .label,
.layout-right .userProfileMainPage h3 {
  color: #fff !important
}

html body .updatedCta,
.global-form .submit-btn.updatedCta {
  font-weight: 700 !important;
  border-radius: 0 !important;
  color: #141414 !important;
  background: #33ffff !important;
  border: 0 !important
}

.layout-right .userProfileMainPage .common-padding.pt-40 {
  padding-top: 20px;
  padding-bottom: 0px;
}

.layout-right .userProfileMainPage .borderRadius {
  border-radius: 7px;
  overflow: hidden;
}

.layout-right .userProfileMainPage .white-bg .user-left {
  padding-top: 25px;
}

@media (max-width:1600px) {
  html .leftShifted {
    margin-right: 310px;
  }

  .dashBoardTop .dashBoardright {
    width: 280px
  }

  .dashBoardTop .dashBoardleft {
    width: calc(100% - 280px)
  }

  .resultShow .right-section-dashboard {
    margin-left: 15px
  }

  .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 10px !important;
    margin-top: 7px;
    margin-bottom: 7px
  }
}

.emptyCartText {
  padding: 15px 0;
  border-top: 2px solid #33ffff !important;
}

.ant-tooltip-inner {
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-size: 11px !important;
}

.summaryMain,
.letterSpacing {
  letter-spacing: 1px;
}

@media (max-width:1600px) and (min-width:768px) {
  .banner-updated-logo-section {
    padding-bottom: 0 !important
  }

  .client-says-section .banner-updated-logo-section.banner-updated-logo-section {
    padding-bottom: 40px !important
  }
}

@media (max-width:1500px) {
  html .cartBoxHeight {
    height: 350px !important;
  }

  html .commonDD ul li a,
  html body .default-dropdown.commonDD ul li a {
    font-size: 15px !important;
  }

  html .cartBoxHeight {
    height: 60vh
  }

  .banner-updated-slider-main .slider-image img {
    max-width: 600px
  }

  html body .banner-updated-slider-main {
    padding: 50px 0 0 !important
  }

  .title-section {
    padding-bottom: 20px
  }
}

@media (max-width:1300px) {
  .layout-left:not(.ant-layout-sider-collapsed)+.layout-right .footer-box {
    width: 25%
  }

  .layout-left:not(.ant-layout-sider-collapsed)+.layout-right .footer-main .footer-box.footer-logo {
    width: 100%;
    padding: 0 0 30px
  }
}

@media (max-width:1100px) {
  body .leftSidebarPadding {
    padding-left: 295px !important
  }

  body .layout-right .layout-right-padding {
    padding-right: 30px
  }

  body .layout-left {
    width: 256px !important
  }

  .banner-updated-slider-main .slider-image img {
    max-width: 500px
  }

  .tool-boxes .single-tool-box {
    margin-right: 22px;
    margin-top: 25px
  }
}

@media (max-width:1000px) {
  .tool-boxes .single-tool-box {
    padding: 35px 20px 16px;
    width: 31%
  }

  .marketing-tools-main.common-padding {
    padding: 50px 0 20px
  }

  .marketing-tools-main.common-padding.feature-tools-bg {
    padding-bottom: 60px
  }

  .tool-img img {
    max-width: 64px
  }

  .tool-boxes .single-tool-box h5 {
    margin: 25px 0 10px !important
  }
}

@media (max-width:767px) {
  .auth-copyrights.sidebarFooter a {
    margin: 0 8px;
  }

  .depecheMode+.headerCta.NewheaderCta a {
    min-width: 99px;
  }

  html .cartBox {
    padding-bottom: 125px;
  }

  html .layout-right .userProfileMainPage .common-padding.pt-40 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .discussion-box .questions-author {
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important
  }

  html .discussion-box .questions-author-text {
    padding-left: 12px
  }

  .resultShow .right-dashboard-action {
    padding: 15px 12px
  }

  .videoAdd button.new img {
    max-width: 80px
  }

  .dashBoardTop .dashBoardleft,
  .dashBoardTop .dashBoardright,
  .detailsVideoText,
  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img {
    width: 100%
  }

  .top-profile-layout ul {
    left: auto;
    right: 0
  }

  html body .layout-left {
    width: 220px !important
  }

  .budgetFormTop.mobileSpacing {
    padding-bottom: 9px !important;
    margin-bottom: 6px !important;
  }

  .budgetForm>div.singleLine {
    width: 20%
  }

  .dashBoardTop .dashBoardright {
    position: relative;
    top: auto
  }

  .dashBoardTop {
    flex-direction: column-reverse;
    display: flex
  }

  html .dashBoardSocialTab button img {
    max-width: 30px
  }

  .dashBoardTop .dashBoardleft,
  html body .banner-updated-slider-main {
    padding: 30px 0 0 !important
  }

  .social-buttons .recovery-text {
    float: left;
    width: 100%;
    margin: 10px 0 0
  }

  html .publish-button.buttonSpace {
    margin: 0
  }

  html .publish-button.buttonSpace .ant-btn {
    margin-left: 0 !important;
    margin-right: 15px !important
  }

  html .pickerDivLeft .srv-validation-message {
    bottom: -44px
  }

  html .pickerDivLeft .ColorPickerInner {
    height: 44px !important
  }

  html .desktopBgPreviewBtn {
    height: 42px;
    padding: 0 20px
  }

  html .desktopBgPreviewBtn span {
    font-size: 16px !important
  }

  html body .single-post-page .post-box h1 {
    margin: 0 0 10px !important;
    font-size: 30px !important
  }

  html body .single-post-page .auth-copyrights.relative.single-post-social-icons {
    top: 25px
  }

  html .single-post-right {
    padding-left: 25px
  }

  .leptop-device-preview .mobile-view-show-preview.viewpagelink-mobile-view {
    margin-top: 0 !important
  }

  html body .payment-approve-modal .submit-btn {
    margin-top: 20px
  }

  .biolink-preivew-mobile-screen .biolink-preview-bg-color {
    min-height: 400px
  }

  .biolink-preivew-mobile-screen .biolink-preview-bg-color+.privacy-policy-mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .eyepage-mobile-screen-top-image {
    width: 100%;
    height: 370px !important;
    background-position: top center !important
  }

  .eyepage-mobile-screen-top-image img {
    display: none
  }

  html body .step2-biolink-email-form input {
    height: 41px
  }

  html body .step2-biolink-email-form .form-field .ant-btn {
    font-size: 14px !important
  }

  html body .step2-biolink-email-form .form-field .ant-btn span {
    width: 70px
  }

  html body .step2-biolink-social-inner a {
    font-size: 14px;
    padding: 8px 10px
  }

  html body .step2-biolink-social-inner a img {
    width: 25px !important;
    min-width: 25px !important;
    height: 25px !important
  }

  html body .step2-biolink-social-inner a span {
    padding-left: 7px
  }

  html body .biolink-step-content-2 .smart-link-form-box-left {
    width: 40px;
    min-width: 40px
  }

  html body .biolink-step-content-2 .smart-link-form-box-right {
    width: calc(100% - 40px)
  }

  .video-btn-added .flex-center {
    flex-direction: column;
    align-items: flex-start
  }

  .video-btn-added .flex-center .find-more-btn {
    top: 0
  }

  html .video-step-right.updated-full-width .ant-checkbox-group .ant-checkbox-wrapper {
    width: 50%
  }

  html .video-step-right.updated-full-width {
    margin-bottom: 20px !important
  }

  .mobile-menu .ant-drawer-content-wrapper {
    width: 300px !important
  }

  .client-says-section .marquee-logos {
    margin-top: 20px !important
  }

  .marquee-logos .slider-image {
    padding: 0 20px
  }

  .marketing-tools-main.common-padding.feature-tools-bg {
    padding-bottom: 10px
  }

  .feature-main>.feature-raw {
    flex-direction: column !important;
    padding: 30px 0 20px
  }

  .feature-tools-bg h5 {
    font-size: 20px
  }

  .feature-main .feature-left,
  .feature-main .feature-right {
    width: 100%;
    text-align: left !important
  }

  .feature-main .feature-left {
    text-align: center !important
  }

  .feature-main .feature-left img {
    max-width: 300px;
    margin: 0 0 30px
  }

  .tool-boxes .single-tool-box {
    padding: 30px 20px 16px;
    width: 46%;
    margin-right: 22px
  }

  .tool-boxes .single-tool-box:nth-child(2n) {
    margin-right: 0
  }

  .tool-boxes .single-tool-box:nth-child(3n) {
    margin-right: 22px
  }

  .banner-updated-slider-main .slider-image img {
    max-width: 300px
  }

  html body .banner-updated-logo-section h5 {
    font-size: 22px;
    padding-bottom: 20px !important
  }

  .banner-updated-logo-section.common-padding {
    padding-bottom: 0 !important;
    padding-top: 20px !important
  }

  html body .banner-updated-slider-main .slick-dots {
    bottom: -10px
  }

  .common-padding.banner-section.banner-updated-section {
    padding: 10px 0 25px !important
  }

  .marketing-tools-main.common-padding {
    padding: 40px 0 10px
  }

  .common-padding.banner-section.banner-updated-section.banner-updated-slider-main {
    padding-top: 20px !important
  }
}

@media (max-width:500px) {
  html .cartBox {
    padding-bottom: 123px;
  }

  .cartBox .cartBoxBottom {
    padding-bottom: 10px !important;
  }

  .headerCta.NewheaderCta .cartBox a {
    margin-top: 5px !important
  }

  html .checkBoxVideoTop iframe,
  html .checkBoxVideoTop video {
    height: 150px !important;
  }

  html .videoAdd button.new {
    min-height: 200px !important;
  }

  html .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 0px !important;
  }

  html body .videoCheckboxClass {
    width: 100% !important;
    margin: 10px 0 !important;
    height: auto !important;
  }

  .detailsContent {
    flex-direction: column-reverse
  }

  .detailsContentRight button {
    margin: 10px 0 15px !important;
    width: 100%
  }

  .dashBoardSocialTab {
    justify-content: space-between !important
  }

  html .dashBoardSocialTab button {
    width: 48.5%;
    margin-left: 0;
    margin-right: 0
  }

  .top-profile-layout .profile-circle-new span,
  .top-profile-layout .profile-circle-new span+.fa {
    display: none
  }

  html .video-step-right.updated-full-width .ant-checkbox-group .ant-checkbox-wrapper {
    width: 100%
  }

  .banner-updated-slider-main .slider-image img {
    max-width: 250px
  }

  .tool-boxes .single-tool-box {
    padding: 33px 20px 16px;
    width: 100%;
    margin: 20px 0 0 !important
  }

  html body .common-padding.banner-section.banner-updated-section {
    padding-bottom: 20px !important
  }
}

@media (max-width:700px) {
  html body .eyepage-mobile-screen .smart-link-right-mibile-top-img {
    width: 375px;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 100% !important
  }
}

@media (min-width:2000px) {

  .header-width,
  body {
    max-width: 1800px;
    margin: 0 auto !important
  }

  .headerCta.NewheaderCta {
    max-width: 1800px;
    margin: 0 auto !important;
    left: 0;
    width: initial;
    right: 80px;
  }

  .sqrbx {
    pointer-events: none
  }

  body .layout-header {
    left: 50%;
    -webkit-transform: translateX(-920px);
            transform: translateX(-920px);
    width: 1800px
  }

  body .layout-left {
    left: 50%;
    -webkit-transform: translateX(-920px);
    transform: translateX(-920px)
  }

  .header-width {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
  }

  .header-progress {
    opacity: 0
  }

  .left-auth {
    left: 50% !important;
    -webkit-transform: translateX(-920px);
    transform: translateX(-920px);
    width: 590px !important
  }

  .large-screen-height-center {
    display: flex;
    align-items: center
  }
}

.playPaush {
  display: flex;
}

.borderLeft {
  border-left: 1px solid #141414 !important;
  margin-left: 5px;
}

@media (min-width:768px) {
  .setMinHeight {
    min-height: 400px;
  }

  .desktopHide {
    display: none
  }

  .addVideoPopup .video-steps-tab-title button span:after,
  .addVideoPopup .video-steps-tab-title button span:before,
  .addVideoPopup .video-steps-tab-title button:after {
    transition: .4s
  }

  .addVideoPopup .video-steps-tab-title button:after {
    position: absolute;
    bottom: -20px;
    z-index: 11;
    left: 50%;
    background: #33ffff;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    content: "";
    opacity: 1;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .addVideoPopup .video-steps-tab-title button span:after {
    position: absolute;
    bottom: -19px;
    z-index: 11;
    left: 50%;
    background: #fff;
    border: 2px solid #141414;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    content: "";
    opacity: 1;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .addVideoPopup .video-steps-tab-title button.active~button:after,
  .addVideoPopup .video-steps-tab-title button:first-child span:before,
  .ant-menu .ant-menu-item.right-align.signup:after,
  .leftMenu ul li.active:after,
  .leftMenu ul li.ant-menu-item-selected:after,
  .sub-menu {
    opacity: 0
  }

  .header-progress,
  ul.after-login>li {
    opacity: 1 !important
  }

  .addVideoPopup .video-steps-tab-title button span:before {
    position: absolute;
    bottom: -15px;
    z-index: 1;
    right: -8px;
    background: #33ffff;
    width: 171px;
    height: 2px;
    content: "";
    opacity: 1;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
  }

  .addVideoPopup .video-steps-tab-title button.active~button span:before {
    background: #141414
  }

  .addVideoPopup .video-steps-tab-title button:first-child:before {
    position: absolute;
    left: 0;
    bottom: -15.5px;
    content: "";
    background: #fff;
    height: 2px;
    width: 37px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1
  }

  .addVideoPopup .video-steps-tab-title button:last-child:before {
    position: absolute;
    right: 0;
    bottom: -15.5px;
    content: "";
    background: #fff;
    height: 2px;
    width: 37px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1
  }

  html body .resultShow .left-section-dashboard .half-form-field.half-form-field-new .form-field {
    width: 50% !important;
    flex-direction: column;
    margin-bottom: 0
  }

  .custom-profile-menu,
  .profile-circle-new,
  ul.after-login li.new-header-profile,
  ul.after-login>li {
    position: relative !important
  }

  .insight-listing-content.emailList-pagination .ant-pagination {
    display: flex;
    justify-content: flex-end
  }

  .eyeView-light-right .privacy-policy-mobile.link-view-page-privacy {
    margin-top: initial !important
  }

  .biolink-eyeview-laptop-screen .smart-link-mibile-bottom.secend-section-mibile {
    background: 0 0 !important
  }

  .biolink-eyeview-laptop-screen {
    display: flex;
    flex-direction: column
  }

  html body .biolink-eyeview-laptop-screen .privacy-policy-mobile {
    margin-top: auto !important
  }

  .eyeView-dark-right .privacy-policy-mobile,
  .eyeView-light-right .privacy-policy-mobile,
  .preview-dark-theme .privacy-policy-mobile,
  .preview-light-theme .privacy-policy-mobile,
  html body .biolink-preview-bg-color .social-smart-link-tab {
    width: 100%;
    margin-top: auto
  }

  html body .biolink-preview-bg-color .privacy-policy-mobile {
    margin-top: initial
  }

  html body .biolink-preview-bg-color .smart-link-mibile-bottom {
    width: 100%
  }

  .biolink-preview-desktop-screen-bg .step2-biolink-email-form .form-field .ant-btn,
  .biolink-preview-desktop-screen-bg .step2-biolink-social-inner a {
    font-size: 15px !important
  }

  html body .biolink-preview-desktop-screen-bg .step2-biolink-email-form .form-field .ant-btn span {
    width: 80px
  }

  .biolink-preview-desktop-screen-bg .step2-biolink-email-form input {
    height: 45px
  }

  html body .leptop-device-preview-man-row .preview-light-theme .smart-link-mibile-bottom.secend-section-mibile,
  html body .video-step-right.pt-0 {
    padding-top: 0 !important
  }

  .eyeView-light-right,
  .preview-light-theme {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #ccc
  }

  .eyeView-dark-right,
  .preview-dark-theme {
    background-color: #000;
    display: flex;
    flex-direction: column
  }

  .eyeView-dark-right .smart-link-mibile-bottom,
  .eyeView-light-right .smart-link-mibile-bottom,
  .preview-dark-theme .smart-link-mibile-bottom,
  .preview-light-theme .smart-link-mibile-bottom {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: initial
  }

  .eyeView-dark-right .smart-link-mibile-bottom::-webkit-scrollbar,
  .eyeView-light-right .smart-link-mibile-bottom::-webkit-scrollbar,
  .preview-dark-theme .smart-link-mibile-bottom::-webkit-scrollbar,
  .preview-light-theme .smart-link-mibile-bottom::-webkit-scrollbar {
    display: none
  }

  .eyeView-dark-right .smart-link-mibile-bottom,
  .eyeView-dark-right .social-smart-link-tab,
  .eyeView-light-right .smart-link-mibile-bottom,
  .eyeView-light-right .social-smart-link-tab {
    width: 100% !important
  }

  html body .leptop-device-preview-right-section .social-smart-link-tab {
    bottom: initial
  }

  html body .leptop-device-preview-row-desktop.viewpageurl .eye-view-page .leptop-device-preview-top-img {
    width: 470px !important
  }

  html body .eye-view-page>.leptop-device-preview-right-section,
  html body .eye-view-page>.preview-left {
    width: 50% !important
  }

  .profile-circle-new,
  ul.after-login li.new-header-profile {
    opacity: 1 !important;
    order: initial !important;
    width: 20px !important;
    height: 20px !important;
    overflow: initial !important;
    pointer-events: initial !important;
    padding: 0 !important
  }

  ul.after-login>li a {
    position: relative;
    z-index: 11111 !important
  }

  html body ul.before-login>li:nth-child(-n+5) {
    margin-left: 25px !important
  }

  .after-login li a,
  html body ul.before-login>li:nth-child(-n+5) a {
    font-weight: 600 !important
  }

  .feature-main>.feature-raw:nth-child(2n) .feature-right {
    padding-left: 30px
  }

  .feature-main>.feature-raw:nth-child(odd) .feature-right {
    padding-right: 30px
  }

  .after-login.ant-menu.ant-menu-horizontal>li:hover>span>a,
  .ant-menu.ant-menu-horizontal>li:hover>a,
  .before-login.ant-menu.ant-menu-horizontal>li:nth-child(-n+4):hover>span>a {
    -webkit-transform: translateY(100%);
            transform: translateY(100%)
  }

  .after-login.ant-menu.ant-menu-horizontal>li>span>a:after,
  .ant-menu.ant-menu-horizontal>li>a:after,
  .before-login.ant-menu.ant-menu-horizontal>li:nth-child(-n+4)>span>a:after {
    position: absolute;
    bottom: 100%;
    left: 0;
    content: attr(data-hover);
    color: #fff;
    white-space: nowrap;
    padding: 0 !important
  }

  .ant-menu li.ant-menu-item.desktop-menu-hide,
  .header-progress,
  ul.after-login li.new-header-profile.ant-menu-submenu>.ant-menu-submenu-title::after,
  ul.after-login>li.header-profile-pic+li.ant-menu-submenu,
  ul.before-login>li.ant-menu-submenu:after {
    display: none !important
  }

  .profile-circle-new {
    border-radius: 100% !important;
    z-index: 1111;
    top: -1px
  }

  .custom-profile-menu ul li {
    padding: 1px 0 !important;
    float: left;
    width: 100%
  }

  .custom-profile-menu:after {
    display: none !important;
    opacity: 0 !important
  }

  html body .custom-profile-menu.header-profile-pic span {
    font-size: 16px !important
  }

  .custom-profile-menu ul {
    opacity: 0;
    position: absolute !important;
    padding: 5px;
    right: -15px;
    top: 0;
    background: #fff !important;
    width: 140px !important;
    display: none;
    border-top: 35px solid #141414
  }

  .custom-profile-menu ul li.custom-sub-li-main {
    position: relative
  }

  .custom-profile-menu ul li.custom-sub-li-main .custom-sub-ul {
    border: 0 !important;
    right: 135px;
    left: auto;
    opacity: 0;
    display: none
  }

  .custom-profile-menu ul li.custom-sub-li-main:hover .custom-sub-ul,
  .custom-profile-menu:hover ul {
    opacity: 1;
    display: block
  }

  .app-page-header .fixed .top-left a img.default,
  .app-page-header .top-left a img.sticky,
  .custom-profile-menu ul li.custom-sub-li-main .custom-sub-ul:after,
  .desktop-hide,
  .leftMenu ul li.ant-menu-item-selected.not-active:before,
  .mainmenu .child-triggerm,
  .mobile-show,
  html body .barsMenu {
    display: none
  }

  .custom-profile-menu ul:after {
    position: absolute;
    right: 15px;
    top: -10px;
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #fff
  }

  .custom-profile-menu ul li a {
    color: #000 !important;
    padding: 5px 10px !important
  }

  .custom-profile-menu ul li:hover a {
    color: #027fc5 !important
  }

  ul.after-login li {
    order: initial !important
  }

  ul.before-login li {
    margin-left: 10px !important
  }

  .ant-menu.ant-menu-horizontal>li.mobile-hide-menu {
    margin-right: 0 !important;
    border: 1px solid #fff !important
  }

  html .ant-menu-submenu ul li:hover {
    background: #2ea5c2
  }

  .sub-menu li a,
  html .ant-menu-submenu ul li:hover a,
  html body .app-page-header .ant-menu .ant-menu-item.ant-menu-item-selected a,
  html body .app-page-header .fixed .ant-menu .ant-menu-item a {
    color: #fff
  }

  .ant-menu.ant-menu-horizontal>li.ant-menu-item-selected>a:after {
    color: #92fbbc
  }

  .header-profile-pic span {
    font-size: 0
  }

  .auth-signin-btn {
    margin-bottom: 5px
  }

  .ant-menu-submenu-title:after {
    position: absolute;
    top: 50%;
    right: 0;
    font-family: fontAwesome;
    content: "\f0d7";
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }

  .wrapper.flex-center.special-case {
    flex-wrap: wrap
  }

  html body .app-page-header .ant-menu .ant-menu-item a {
    color: #000
  }

  .app-page-header .top-left a {
    position: relative;
    min-width: 156px
  }

  .app-page-header .fixed .top-left a img.sticky,
  .app-page-header .top-left a img.default {
    display: inline-block
  }

  .mainmenu {
    position: relative;
    z-index: 9;
    float: right;
    width: auto;
    margin-top: 0
  }

  .mainmenu>li {
    float: left;
    width: auto;
    margin-left: 40px;
    position: relative
  }

  .mainmenu li:hover>ul.sub-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 5px
  }

  .mainmenu>li:last-child .sub-menu {
    left: auto;
    right: 0
  }

  .mainmenu>li:last-child .sub-menu .sub-menu {
    left: auto;
    right: 100%
  }

  .mainmenu li:hover>ul.sub-menu .sub-menu {
    margin: 0
  }

  .sub-menu {
    text-align: left;
    min-width: 150px;
    visibility: hidden;
    transition: .5s ease-in-out;
    position: absolute;
    top: 100%;
    left: 0;
    background: #000;
    padding: 0;
    display: initial;
    margin-top: 10px
  }

  .sub-menu>li:hover>a {
    background: #ff0;
    color: #3c3c3c
  }

  .sub-menu .sub-menu {
    right: 0;
    left: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    width: 100%
  }

  .ant-menu .ant-menu-item {
    display: inline-block !important;
    line-height: normal
  }

  .ant-menu .ant-menu-item.right-align {
    margin-left: auto !important;
    position: absolute !important;
    right: 0 !important
  }

  .leftMenu .ant-menu {
    display: flex;
    position: relative;
    align-items: center
  }

  .ant-menu .ant-menu-item.right-align.login {
    right: 115px !important
  }

  .ant-menu .ant-menu-item.right-align.signup {
    top: -5px
  }

  .temporary-css1 .top-right {
    position: relative;
    top: -10px
  }

  .leftMenu ul li.active span,
  .leftMenu ul li.ant-menu-item-selected span {
    position: relative;
    z-index: 1
  }

  .leftMenu ul li.active:before {
    content: "";
    background: #5d45e8;
    border-radius: 25px !important;
    position: absolute;
    width: 150%;
    height: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: -6px
  }

  .stakingBox .two-column-right img {
    position: relative;
    top: -30px
  }

  .header-progress {
    display: block !important
  }

  .eyePage .leptop-device-preview-right-section,
  html body .eyePage .leptop-device-preview-row-desktop.viewpageurl .eye-view-page .leptop-device-preview-top-img {
    height: 467px !important
  }

  .ant-menu.ant-menu-sub {
    margin-top: 8px !important
  }

  .ant-menu.ant-menu-sub li:hover,
  .ant-menu.ant-menu-sub li:hover a {
    color: #fff !important
  }

  ul.before-login>li.ant-menu-submenu {
    padding: 0 !important;
    margin-right: 0 !important;
    margin-left: 25px !important
  }

  ul.before-login>li.ant-menu-submenu .ant-menu-title-content {
    font-weight: 600 !important;
    text-transform: capitalize !important
  }

  html .ant-menu-horizontal {
    line-height: normal
  }

  html .ant-menu-horizontal li {
    border: 0 !important
  }

  .ant-menu.ant-menu-horizontal>li {
    overflow: hidden;
    margin: 0 15px;
    line-height: normal
  }

  .ant-menu.ant-menu-horizontal>li>a {
    position: relative;
    transition: .4s;
    display: inline-block
  }

  .ant-menu.ant-menu-horizontal>li.ant-menu-item-selected a {
    color: #92fbbc;
    font-weight: 600
  }
}

@media (max-width:1400px) {
  html .shiftToTop {
    letter-spacing: 20px;
  }

  body .layout-left {
    width: 250px !important
  }

  body .leftSidebarPadding {
    padding-left: 250px !important
  }

  .resultShow .chart-box .graph-content h5 {
    font-size: 16px
  }

  html body .resultShow .left-section-dashboard .half-form-field.half-form-field-new .form-field>div {
    font-size: 14px
  }

  .default-cta,
  .fbLoginButton,
  .global-form .submit-btn {
    height: 40px !important
  }

  .auth-copyrights {
    padding: 10px 60px 40px
  }

  .chart-box>div {
    width: 300px
  }
}

@media (max-width:1350px) {

  .resultShow .right-section-dashboard,
  .samePadding.videoAdd .left-section-dashboard {
    width: 100% !important
  }

  .resultShow .right-section-dashboard {
    margin: 0
  }
}

@media (max-width:1200px) {
  .sqrbx:after {
    position: absolute;
    left: -7px;
    top: 87%;
    height: 100%;
    width: 171%;
    content: "";
    background: #000;
    -webkit-transform: rotate(45deg) translateX(-50%);
            transform: rotate(45deg) translateX(-50%);
  }

  html .addVideoPopup .video-step-content {
    padding: 0 50px
  }

  .detailsContentRight button {
    min-width: 80px
  }

  .title-section br {
    display: none
  }

  .mt-100 {
    margin-top: 60px !important
  }

  .title-section {
    padding-bottom: 15px
  }
}

@media (max-width:991px) {
  body .layout-left.onClickRemoveMenu {
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
  }

  .headerCta.NewheaderCta {
    right: 25px;
  }

  html .leftShifted {
    margin-right: 90px;
  }

  html .cartBoxHeight {
    height: 250px !important;
  }

  html .addVideoPopup .video-step-content {
    padding: 0 30px
  }

  .detailsVideo,
  .detailsVideoText {
    width: 100%
  }

  .detailsVideo {
    margin-bottom: 20px
  }

  .detailsContentLeft {
    flex-direction: column
  }

  h3 {
    font-size: 24px
  }

  html body .button-spotify-row>div {
    padding: 7px 10px !important
  }

  html body .button-spotify-row a {
    font-size: 16px
  }

  html body .button-spotify-row a img {
    margin-left: 0 !important
  }
}

@media (max-width:993px) {
  .mt-100 {
    margin-top: 50px !important
  }

  .footer-bottom .wrapper>div:first-child {
    order: 3
  }

  .footer-bottom .wrapper>div:nth-child(2) {
    order: 2;
    margin: 10px 0 6px !important;
    left: 0 !important
  }

  .more-about-app-main {
    padding: 75px 0 25px
  }
}

@media (min-width:992px) {
  .budgetForm>div {
    padding: 3px 10px
  }

  html body .biolink-step-one .form-field .label,
  html body .smartlink-step-two .form-field .label {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    font-size: 17px
  }

  html body .validation-raw .srv-validation-message {
    left: 122px
  }

  .smart-link-right-mibile-top-img .mobile-device-image-square {
    height: 360px;
    max-height: 360px;
    width: 94%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px
  }

  .smart-link-right-mibile-top-img .mobile-device-image-square img {
    display: none
  }

  .smart-link-parallax-height .react-parallax {
    min-height: 700px
  }
}

@media (max-width:992px) {

  .checkBoxVideoTop iframe,
  .checkBoxVideoTop video {
    height: 120px !important
  }

  .videoCheckboxClass {
    width: 145px;
    min-width: 145px
  }

  html body .videoCheckboxClass {
    height: 235px
  }

  .dashBoardright {
    font-size: 15px
  }

  .dashBoardright ul {
    padding: 5px 5px 0 18px
  }

  .budgetForm {
    font-size: 14px
  }

  .budgetForm .activeRaw,
  .budgetForm .blank,
  .budgetForm .budgetText {
    width: 19%
  }

  .budgetForm>div {
    padding: 5px
  }

  .budgetForm>div.singleLine {
    padding-left: 10px
  }

  .budgetText {
    min-width: 150px
  }

  .dashBoardSocialTab button {
    font-size: 25px
  }

  .dashBoardTop .dashBoardleft {
    padding-right: 20px
  }

  .addIconbtn {
    font-size: 50px
  }

  .dashBoardSocialTab button img {
    max-width: 40px
  }

  html body .video-step-right.updated-full-width {
    padding: 30px 20px 0 50px !important
  }

  html body .after-scroll-add {
    height: 768px
  }

  html body .after-scroll-add-bottom .smart-link-mibile-bottom {
    padding-bottom: 150px
  }

  .testimonial-content {
    padding: 60px 60px 50px;
    font-size: 26px;
    line-height: 34px
  }

  .testimonial-bottom {
    margin-top: 30px
  }

  .testimonial-content:after {
    left: 40px;
    top: 25px
  }

  .content-result-box .video-step-left .video-box {
    width: 90% !important
  }

  .content-result-box .single-line-field.flex-start {
    flex-direction: column
  }

  .content-result-box .single-line-field.flex-start>div {
    width: 100% !important;
    margin: 2px 0
  }

  .smart-link-main .video-step-right img {
    width: 300px;
    max-width: 100%
  }

  .single-line-field .ant-select {
    width: 150px !important
  }

  html body .auth-copyrights {
    padding: 10px 40px 30px
  }

  .auth-copyrights ul li a {
    margin-right: 5px
  }

  .ant-menu .cta-btn,
  .second-menu .ant-menu li a {
    font-size: 15px !important
  }

  .second-menu .ant-menu li a {
    padding: 7px 10px 5px !important
  }

  .ant-menu-submenu-title {
    padding: 7px 15px 5px 10px !important
  }

  .topHeaderClass+div,
  body1 {
    padding: 80px 0 0
  }

  .ant-menu .ant-menu-item.right-align.login {
    right: 103px !important
  }

  .slick-slider ul {
    margin: 10px 0;
    position: relative;
    z-index: 11
  }
}

@media (min-width:1650px) {
  .banner-updated-logo-section .marquee-logos {
    margin-bottom: -20px
  }
}

@media (max-width:767px) {
  .headerCta.ctaClicked .cartBox {
    opacity: 1;
    visibility: visible;
    margin: 0;
  }

  .headerCta:not(.ctaClicked) .cartBox {
    opacity: 0;
    visibility: hidden;
  }

  html .headerCta.NewheaderCta a {
    min-width: 87px;
  }

  html .headerCta.NewheaderCta {
    right: 16px;
  }

  html .cartBoxHeight {
    height: 220px !important;
  }

  html .topHeaderClass+div.home-page {
    padding: 63px 0 0;
  }

  html .addVideoPopup .video-step-content {
    padding: 0 5px
  }

  html .addVideoPopup .ant-checkbox-group .ant-col-8 {
    width: 50%;
    flex: initial;
    max-width: 100%
  }

  .desktopHide,
  .desktopHide p,
  .mobile-center {
    text-align: center
  }

  .desktopHide {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center
  }

  .desktopHide p {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 0 2px;
    line-height: 20px;
    background: #141414;
    color: #fff;
    float: none;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .detailsContent {
    flex-direction: column;
    margin: 25px 0 0
  }

  .detailsContentRight button {
    margin: 15px 0 0 !important
  }

  html body .layout-header {
    padding-top: 13px !important;
    padding-bottom: 13px !important
  }

  html body .withSidebar {
    letter-spacing: 5px;
    padding-bottom: 15px;
    padding-top: 15px
  }

  html body .shiftToTop {
    font-size: 18px;
    letter-spacing: 8px;
    top: 20px;
  }

  .boldInfo {
    font-size: 13px;
  }

  body .resultShow .right-section-dashboard .label,
  html body .resultShow .left-section-dashboard .label {
    letter-spacing: 4px !important
  }

  html body .resultShow .left-section-dashboard .half-form-field.half-form-field-new .form-field {
    display: flex;
    padding-bottom: 7px;
    flex-direction: column
  }

  .videoAdd {
    padding: 10px 12px
  }

  .videoCheckboxClass .budgetForm>div:first-child,
  .videoCheckboxClass .budgetForm>div:last-child {
    width: 100%;
    padding: 3px 0
  }

  .videoCheckboxClass .budgetForm>div:first-child {
    justify-content: space-between
  }

  .checkBoxVideoTop iframe,
  .checkBoxVideoTop video {
    height: 80px !important
  }

  html body .videoCheckboxClass {
    height: 190px
  }

  .videoCheckboxClass {
    width: 105px;
    min-width: 105px;
    margin-right: 12px;
    margin-top: 6px;
    margin-bottom: 6px
  }

  .budgetFormMain {
    padding: 0 15px
  }

  .budgetForm {
    flex-wrap: wrap
  }

  .budgetForm .activeRaw,
  .budgetForm .blank,
  .budgetForm .budgetText {
    width: 50%;
    min-width: initial;
    line-height: normal
  }

  .budgetForm .blank,
  .budgetForm>div.singleLine {
    border-right: 1px solid #ccc
  }

  .budgetForm .budgetText,
  html body .cta-btn.default {
    font-size: 13px
  }

  html body .layout-header+div {
    padding-top: 90px
  }

  .dashBoardright ul {
    padding: 5px 5px 0 20px
  }

  .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom,
  html body .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile {
    min-height: 400px
  }

  .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.linkViewCss,
  html body .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile.linkViewCss {
    min-height: 200px
  }

  .Collapsible__trigger.is-open,
  .banner-updated-logo-section .marquee-logos {
    margin-top: 0
  }

  .testimonial-content {
    padding: 30px 30px 35px;
    font-size: 20px;
    line-height: 28px
  }

  .testimonial-bottom {
    margin-top: 25px;
    flex-direction: column !important
  }

  .testimonial-bottom h5 {
    margin: 20px 0 0 !important
  }

  .testimonial-content:after {
    left: 22px;
    top: 15px
  }

  .user-left.full-width {
    margin: 0 0 5px
  }

  .user-profile-flex.mb-10 {
    margin-bottom: 0
  }

  .animated {
    -webkit-animation: none !important;
            animation: none !important;
    opacity: 1 !important
  }

  .flex-center.user-profile-flex,
  .half-form-field.half-form-field-new.flex-start,
  .mobile-full-width,
  .three-column .ant-row,
  .title-section.flex-center {
    flex-direction: column
  }

  .content-result-box {
    padding: 26px 25px 25px
  }

  .content-result-box .video-step-left .video-box,
  .half-form-field-new.half-form-field.flex-start>.form-field,
  .mobile-full-width .w-50 {
    width: 100% !important
  }

  .content-result-box .video-step-left .video-box {
    margin-bottom: 15px !important
  }

  .global-form .form-field.mt-30.flex-start {
    margin-top: 10px !important;
    margin-bottom: 0 !important
  }

  .chart-box>div {
    margin-right: 15px
  }

  .ml-15 {
    margin-left: 0
  }

  .smart-link-main .video-step-main {
    flex-direction: column-reverse;
    display: flex
  }

  .global-popup .ant-modal-close {
    -webkit-transform: scale(.8);
            transform: scale(.8);
    top: 27px;
    right: 22px
  }

  .global-popup .ant-modal-body {
    padding: 25px 27px !important
  }

  .color-blue {
    margin-bottom: 10px
  }

  .app-page-header .top-left a img.default,
  .barsMenu,
  li.mobile-hide-menu {
    display: none !important
  }

  .two-cta button {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 7px
  }

  .three-column .ant-row .ant-col-8 {
    width: 100%;
    max-width: 100%
  }

  .global-form .form-field.mb-30 {
    margin-bottom: 10px !important
  }

  .white-bg .ant-pagination li {
    margin: 3px !important
  }

  .white-bg .ant-pagination-next a,
  .white-bg .ant-pagination-prev a {
    min-width: 100px;
    border-radius: 7px !important
  }

  .white-bg .ant-pagination li.ant-pagination-item a {
    width: 30px !important;
    height: 30px !important;
    border-radius: 0 !important;
    border: 1px solid #027fc5 !important
  }

  .white-bg .ant-pagination li a {
    width: 30px !important;
    height: 30px !important;
    border-radius: 0 !important
  }

  .default-cta,
  .fbLoginButton,
  .global-form .submit-btn {
    padding: 0 18px !important
  }

  .two-cta button:last-child {
    margin-right: 0
  }

  .no-video-found img {
    max-width: 120px
  }

  .no-video-found {
    margin-bottom: 15px
  }

  .add-video-link {
    padding: 15px 0 0
  }

  .mt-100 {
    margin: 0 !important
  }

  .global-form .ant-checkbox-wrapper {
    color: #fff !important
  }

  .header-profile-pic {
    background-image: none !important
  }

  .video-step-main.step-1 {
    display: flex;
    flex-direction: column-reverse
  }

  html body .change-password-screen .global-form .submit-btn.transparent {
    border-color: #fff !important;
    color: #fff !important
  }

  html body .change-password-screen .auth-copyrights {
    margin-top: 40px
  }

  html body .change-password-screen .right-auth-left-text {
    padding: 0 0 15px !important
  }

  input[type=checkbox]+label,
  input[type=radio]+label {
    min-width: initial;
    font-size: 13px
  }

  .radio-span {
    margin-right: 10px;
    margin-bottom: 10px
  }

  .ant-menu-item,
  .ant-menu-submenu-title,
  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item {
    line-height: normal !important;
    height: auto !important;
    margin: 0 !important
  }

  html {
    overflow-x: hidden
  }

  textarea.ant-input {
    margin-bottom: 5px
  }

  html body .cta-btn~.cta-btn {
    margin-left: 7px !important
  }

  .popup-main {
    max-width: 88% !important
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    width: 100%
  }

  .ant-menu-submenu-popup,
  .ant-modal-mask {
    z-index: 999999999 !important
  }

  .ant-modal-wrap {
    z-index: 9999999999999999999 !important
  }

  .ant-menu-submenu-title,
  .mobile-menu .ant-menu li a {
    color: #000 !important;
    padding: 2px 0 !important;
    font-size: 14px !important
  }

  .cta-btn,
  .mobile-menu .ant-menu li a {
    font-size: 14px
  }

  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
  .ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
  .ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
    right: 0;
    color: #000 !important
  }

  .popup-main .ant-modal-body h1 {
    margin-bottom: 15px !important;
    line-height: normal
  }

  .popup-main .ant-modal-body {
    padding: 18px 30px 4px
  }

  html .forgot-pwd-link {
    margin: 0
  }

  .search-form .ant-input {
    width: 100%;
    position: relative;
    left: 0;
    top: initial;
    -webkit-transform: none;
            transform: none;
    padding: 0 15px
  }

  .mainmenu,
  .mobile-menu .ant-drawer-body {
    padding: 0
  }

  html body .search-box {
    background: #000
  }

  html body .footer-box-4 {
    margin-bottom: 0 !important
  }

  html body .footer-box-1 {
    position: relative;
    padding-bottom: 15px;
    margin: 0 0 21px !important;
    text-align: center !important
  }

  html body .footer-box-1:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    background: #fff;
    width: 2000%;
    height: 1px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  html body .mobile-flex>.flex {
    flex-direction: column
  }

  .app-page-header {
    background: #1d2a3b
  }

  .app-page-header .top-left a img.sticky {
    display: inline-block !important
  }

  .ant-drawer-body li.ant-menu-item-selected:after {
    border: 0 !important
  }

  .mobile-menu .ant-drawer-header {
    background: #1d2a3b;
    border-radius: 0 !important
  }

  .mobile-menu .ant-drawer-header img {
    max-width: 130px
  }

  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item {
    color: #1d2a3b !important;
    border-bottom: 1px solid #1d2a3b !important;
    padding: 10px 20px !important
  }

  .ant-menu .ant-menu-item.right-align.login {
    right: 0 !important
  }

  html body .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item:last-child {
    border-bottom: 1px solid #1d2a3b !important
  }

  html body .mobile-menu .ant-drawer-body .close-menu {
    padding: 0 !important;
    border: 0 !important;
    position: absolute;
    top: 12px;
    right: 18px;
    color: #fff;
    font-size: 18px
  }

  .mainmenu li.menu-item-has-children,
  .menu-cover,
  html body .mobile-menu .ant-drawer-wrapper-body {
    position: relative
  }

  html body .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn {
    background: 0 0 !important
  }

  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn {
    border-radius: 0 !important;
    border: 0 !important
  }

  .mainmenu,
  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn:before,
  .sub-menu {
    display: none
  }

  .mainmenu {
    background-color: #256cb5;
    margin: 0;
    float: left;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    display: block;
    transition: .5s;
    position: fixed;
    right: 0;
    width: 320px;
    max-width: 100%;
    top: 100px;
    height: 100%
  }

  .mainmenu li a.child-triggerm {
    opacity: 1;
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important
  }

  .mainmenu li a,
  .mobile-open .mainmenu li.menu-item-has-children a+ul li a {
    opacity: 0;
    -webkit-transform: translateX(90px);
            transform: translateX(90px)
  }

  .mainmenu>li,
  .mainmenu>li>ul>li {
    float: left;
    width: 100%;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #fff;
    position: relative
  }

  .mainmenu li {
    box-sizing: border-box
  }

  .mainmenu li a {
    text-decoration: none;
    font-weight: 700
  }

  .mainmenu li a,
  .mainmenu>li>ul>li>a {
    display: inline-block;
    color: #fff;
    padding: 8px 18px
  }

  .mainmenu li ul li {
    border: 0;
    border-top: 1px solid #fff
  }

  .mainmenu li ul li ul li a {
    padding: 10px 40px
  }

  .child-triggerm {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border-left: 1px solid #fff;
    padding: 10px 18px !important
  }

  .child-triggerm .fa {
    transition: .5s ease-in-out;
    display: inline-block
  }

  .child-triggerm.active .fa {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
  }

  .mobile-show {
    display: block !important
  }

  .mobile-open .mainmenu li a,
  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li a {
    transition: .5s cubic-bezier(.03, .46, .31, .97);
    transition-delay: 0s;
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:first-child a,
  .mobile-open .mainmenu li:first-child a {
    transition-delay: 0.24s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(2) a,
  .mobile-open .mainmenu li:nth-child(2) a {
    transition-delay: 0.44s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(3) a,
  .mobile-open .mainmenu li:nth-child(3) a {
    transition-delay: 0.64s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(4) a,
  .mobile-open .mainmenu li:nth-child(4) a {
    transition-delay: 0.84s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(5) a,
  .mobile-open .mainmenu li:nth-child(5) a {
    transition-delay: 1.04s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(6) a,
  .mobile-open .mainmenu li:nth-child(6) a {
    transition-delay: 1.2s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(7) a,
  .mobile-open .mainmenu li:nth-child(7) a {
    transition-delay: 1.4s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(8) a,
  .mobile-open .mainmenu li:nth-child(8) a {
    transition-delay: 1.6s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(9) a,
  .mobile-open .mainmenu li:nth-child(9) a {
    transition-delay: 1.8s;
    opacity: 1
  }

  .mobile-open .mainmenu li.menu-item-has-children a.active+ul li:nth-child(10) a,
  .mobile-open .mainmenu li:nth-child(10) a {
    transition-delay: 2s;
    opacity: 1
  }

  .cta-btn {
    padding: 6px 15px 5px
  }

  body.swipe-menu {
    background: gray;
    background-position: top center !important
  }

  .mobile-open.swipe-menu {
    -webkit-transform: translateX(-320px);
            transform: translateX(-320px)
  }

  .swipe-menu .mainmenu {
    transition: none
  }

  body.swipe-menu .mainmenu {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    left: 100%;
    right: auto;
    padding: 50px 0 0
  }

  body.mobile-open.swipe-menu .dl-menuwrapper {
    left: 330px
  }

  .mobile-open .mainmenu {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  .sub-menu {
    padding: 0;
    float: left;
    width: 100%;
    background: #000
  }

  .mainmenu>li:first-child {
    border-top: 1px solid #fff
  }

  .dl-menuwrapper {
    float: right
  }

  .dfd-click-menu-activation-button a,
  .dfd-side-slide-header-button-wrap a,
  .dl-menuwrapper a {
    font-size: 0;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 42px;
    height: 36px;
    margin-top: -18px;
    margin-left: -26px;
    transition: background .3s, border-color .3s;
    cursor: pointer
  }

  .dfd-click-menu-activation-button,
  .dfd-side-slide-header-button-wrap,
  .dl-menuwrapper {
    position: relative;
    width: 36px;
    height: 50px;
    margin: 0 auto;
    z-index: 1003;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  .dfd-click-menu-activation-button a .icon-wrap,
  .dfd-side-slide-header-button-wrap a .icon-wrap,
  .dl-menuwrapper a .icon-wrap {
    position: absolute;
    display: block;
    width: 34px;
    height: 2px;
    top: 50%;
    right: 0;
    margin-left: -12px;
    background: #ccc;
    transition: .3s;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    cursor: pointer
  }

  .dfd-click-menu-activation-button a .icon-wrap.dfd-top-line,
  .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-top-line,
  .dl-menuwrapper a .icon-wrap.dfd-top-line {
    margin-top: -7px;
    cursor: pointer
  }

  .dfd-click-menu-activation-button a .icon-wrap.dfd-middle-line,
  .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-middle-line,
  .dl-menuwrapper a .icon-wrap.dfd-middle-line {
    width: 24px;
    margin-right: 0
  }

  .dfd-click-menu-activation-button a .icon-wrap.dfd-bottom-line,
  .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-bottom-line,
  .dl-menuwrapper a .icon-wrap.dfd-bottom-line {
    margin-top: 7px
  }

  .dl-menuwrapper a .icon-wrap.dfd-bottom-line {
    width: 18px
  }

  .dfd-click-menu-activation-button a:hover .icon-wrap.dfd-top-line,
  .dfd-side-slide-header-button-wrap a:hover .icon-wrap.dfd-top-line,
  .dl-menuwrapper a:hover .icon-wrap.dfd-top-line,
  .mobile-open .dfd-click-menu-activation-button a .icon-wrap.dfd-top-line,
  .mobile-open .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-top-line,
  .mobile-open .dl-menuwrapper a .icon-wrap.dfd-top-line {
    -webkit-animation: .8s linear infinite dfdHamburgerButton;
    animation: .8s linear infinite dfdHamburgerButton
  }

  .dfd-click-menu-activation-button a:hover .icon-wrap.dfd-middle-line,
  .dfd-side-slide-header-button-wrap a:hover .icon-wrap.dfd-middle-line,
  .dl-menuwrapper a:hover .icon-wrap.dfd-middle-line,
  .mobile-open .dfd-click-menu-activation-button a .icon-wrap.dfd-middle-line,
  .mobile-open .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-middle-line,
  .mobile-open .dl-menuwrapper a .icon-wrap.dfd-middle-line {
    -webkit-animation: 1.2s ease-in infinite dfdHamburgerButton;
    animation: 1.2s ease-in infinite dfdHamburgerButton
  }

  .dfd-click-menu-activation-button a:hover .icon-wrap.dfd-bottom-line,
  .dfd-side-slide-header-button-wrap a:hover .icon-wrap.dfd-bottom-line,
  .dl-menuwrapper a:hover .icon-wrap.dfd-bottom-line,
  .mobile-open .dfd-click-menu-activation-button a .icon-wrap.dfd-bottom-line,
  .mobile-open .dfd-side-slide-header-button-wrap a .icon-wrap.dfd-bottom-line,
  .mobile-open .dl-menuwrapper a .icon-wrap.dfd-bottom-line {
    -webkit-animation: .6s ease-out infinite dfdHamburgerButton;
    animation: .6s ease-out infinite dfdHamburgerButton
  }

  @-webkit-keyframes dfdHamburgerButton {

    0%,
    100%,
    50% {
      -webkit-transform: scaleX(1)
    }

    25% {
      -webkit-transform: scaleX(.9)
    }

    75% {
      -webkit-transform: scaleX(1.1)
    }
  }

  @keyframes dfdHamburgerButton {

    0%,
    100%,
    50% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1)
    }

    25% {
      -webkit-transform: scaleX(.9);
              transform: scaleX(.9)
    }

    75% {
      -webkit-transform: scaleX(1.1);
              transform: scaleX(1.1)
    }
  }
}

.separatorMain,
.separatorRight,
.separatorLeft {
  display: flex;
}

.detailsVideoText .mt-10 {
  margin-top: 0px
}

.detailsVideoText .big {
  text-transform: uppercase;
  font-size: 14px !important;
  min-width: 120px;
}

@media (min-width:992px) and (max-width:1240px) {
  html body .shiftToTop {
    font-size: 30px;
    letter-spacing: 25px;
  }

  .wrapper {
    width: 100%;
    padding: 0 50px
  }

  .loader {
    -webkit-transform: scale(.6);
            transform: scale(.6)
  }

  h1 {
    font-size: 31px
  }

  html body .right-auth-left {
    width: 45% !important;
    padding-right: 10px !important
  }

  html body .right-auth-right {
    width: 55% !important
  }

  .signup-tab-title a {
    font-size: 15px
  }
}

@media (min-width:768px) and (max-width:991px) {
  html body .videoCheckboxClass .budgetForm {
    font-size: 11px !important;
  }

  html .leftShifted {
    margin-right: 240px;
  }

  :root {
    --h1-font-size: 30px;
    --h2-font-size: 26px
  }

  .wrapper {
    width: 100%;
    padding: 0 35px
  }

  .banner-updated-logo-section.common-padding {
    padding-bottom: 0 !important;
    padding-top: 50px !important
  }

  ul.before-login li a.default-cta {
    min-width: 90px;
    padding: 0 15px !important
  }

  .change-password-screen .global-form {
    padding-top: 20px
  }

  .client-says-section.common-padding.banner-section.banner-updated-section {
    padding-top: 0 !important
  }

  .feature-main>.feature-raw {
    padding-top: 60px
  }

  .loader {
    -webkit-transform: scale(.5);
            transform: scale(.5)
  }

  .title-section {
    font-size: 16px
  }

  .search-form .ant-btn {
    padding: 0 17px 0 15px
  }

  .search-form .ant-input {
    right: 45px
  }
}

@media (min-width:30px) and (max-width:767px) {
  :root {
    --section-padding: 70px 0px;
    --h1-font-size: 27px;
    --h2-font-size: 22px
  }

  h1 {
    line-height: normal !important
  }

  body {
    line-height: 24px !important;
    font-size: 15px !important
  }

  .depecheMode {
    order: 1
  }

  html .videoCheckboxClass {
    width: 47.8%;
  }

  html .videoCheckboxClass .budgetForm {
    font-size: 11px;
    padding: 10px 11px;
    letter-spacing: .3px;
  }

  html .depecheMode button {
    min-width: 150px;
    font-size: 14px
  }

  .topHeaderClass+div {
    padding: 75px 0 0
  }

  .wrapper {
    width: 100%;
    padding: 0 25px
  }

  html body .video-step-right .signup-tab-title a {
    color: #848d9c !important
  }

  .client-says-section br {
    display: none
  }

  .testimonial-content {
    padding: 40px 45px;
    font-size: 22px;
    line-height: 30px
  }

  html body .client-says-section .common-padding.banner-section.banner-updated-section {
    padding-top: 0 !important
  }

  .client-says-section h1 {
    padding-bottom: 0
  }

  html body .mobile-menu .ant-drawer-body .ant-menu.before-login {
    padding-top: 10px !important
  }

  html body .mobile-menu .ant-drawer-body .ant-menu.before-login .ant-menu-item,
  html body .mobile-menu .ant-drawer-body .ant-menu.before-login .ant-menu-submenu {
    border: 0 !important;
    padding: 8px 15px 0 !important
  }

  html body .mobile-menu .ant-drawer-body .ant-menu.before-login .ant-menu-item:last-child,
  html body .mobile-menu .ant-drawer-body .ant-menu.before-login .ant-menu-submenu:last-child {
    border: 0 !important
  }

  .loader {
    -webkit-transform: scale(.3);
            transform: scale(.3)
  }

  .mainmenu li a {
    font-size: 15px
  }

  .title-section {
    font-size: 16px
  }

  h2 {
    line-height: 28px;
    margin-bottom: 15px !important
  }

  .global-form .ant-input {
    height: 40px;
    padding: 4px 15px
  }

  .global-form .form-field {
    margin: 0 0 12px
  }

  .default-cta,
  .fbLoginButton,
  .global-form .submit-btn {
    height: 35px !important;
    min-width: 80px
  }

  .auth-copyrights {
    position: relative;
    padding: 15px 0 0 !important;
    display: flex;
    flex-direction: column
  }

  .auth-copyrights ul li a {
    margin: 0 4px 10px;
    font-size: 14px !important
  }

  .auth-copyrights.relative .flex-center {
    flex-direction: column
  }

  .auth-copyrights.relative {
    padding: 15px 0 !important;
    font-size: 15px
  }

  .global-form,
  .global-form .ant-radio-wrapper,
  .global-form .have-accnt,
  .global-form .have-accnt a,
  .global-form .recovery-text {
    color: #fff !important
  }
}

.shiftToTop {
  float: left;
  width: auto;
  color: #fff;
  position: fixed;
  left: 0;
  top: 17px;
  z-index: 999;
  margin: 0 !important;
  font-size: 40px;
  color: #33ffff;
  font-weight: 100 !important;
  letter-spacing: 30px;
}

@media (max-width:400px) {

  html body .updatedCta,
  .global-form .submit-btn.updatedCta {
    width: 100%;
  }

  html body .shiftToTop {
    font-size: 17px;
    letter-spacing: 6px;
  }

  .loginFbBtn {
    flex-direction: column;
  }

  .loginFbBtn .submit-btn,
  .loginFbBtn .submit-btn.next-fb-btn+span,
  .loginFbBtn .submit-btn.next-fb-btn+span .fbLoginButton,
  .auth-signin-btn {
    width: 100% !important;
    margin: 0;
  }

  .loginFbBtn .submit-btn.next-fb-btn+span {
    margin-top: 12px;
  }

  html body .checkBoxVideoTop iframe,
  html body .checkBoxVideoTop video {
    height: 130px !important;
  }

  html body .videoAdd button.new {
    min-height: 180px !important;
  }

  .depecheMode,
  .discussion-box.flex {
    flex-direction: column
  }

  .discussion-box.flex {
    padding-right: 0
  }

  .discussion-box .questions-author {
    margin-bottom: 10px
  }

  .discussion-box {
    padding-right: 0
  }

  html body .discussion-box .questions-author-text {
    padding-left: 0
  }

  html .videoAdd button.new img {
    max-width: 60px
  }

  html .taglineStep .threeColumn .ant-checkbox-group .ant-checkbox-wrapper,
  html .taglineStep .video-step-left,
  html .taglineStep .video-step-right {
    width: 100%
  }

  html .taglineStep .video-step-right {
    padding: 20px 0 0
  }

  .videoAdd button.new {
    min-height: 200px;
    margin-right: 0 !important
  }

  .mainmenu li a,
  .videoAdd button.new span {
    font-size: 15px
  }

  .cartBox {
    min-width: 270px;
    top: 43px;
    width: 260px
  }

  .depecheRight {
    margin: 10px 0 0
  }

  .depecheMode button {
    margin: 0;
    min-width: 100% !important;
    font-weight: 100;
  }

  .budgetForm>div.singleLine {
    width: 50%
  }

  .budgetForm .submit-btn {
    width: 100%;
    font-size: 15px
  }

  .insight-listing-content {
    overflow: auto
  }

  .insight-listing-content .contentInner {
    width: 410px !important
  }

  .single-post-page .auth-copyrights.relative.single-post-social-icons ul li a {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 13px !important
  }

  html .single-post-right {
    padding-left: 22px
  }

  .biolink-edit-mobile-view .social-smart-link-tab {
    margin-bottom: 0 !important
  }

  .banner-updated-slider-main .slider-image img {
    max-width: 200px
  }

  .feature-main .feature-left img {
    max-width: 250px
  }

  .addIconbtn {
    font-size: 35px
  }

  .mobile-view-show-preview .biolink-eyeview-mobile-screen.smart-link-mibile-bottom {
    left: 0 !important
  }

  .biolink-edit-mobile-view .after-scroll-add-bottom {
    display: flex;
    flex-direction: column
  }

  .biolink-edit-mobile-view .after-scroll-add-bottom .social-smart-link-tab {
    margin-top: auto;
    position: relative
  }

  .biolink-edit-mobile-view .after-scroll-add-bottom .social-smart-link-tab:after {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #302d31;
    content: "";
    height: 100%;
    width: 198%;
    top: 0;
    left: 0
  }

  .biolink-edit-mobile-view .after-scroll-add-bottom .have-social-links {
    position: relative;
    z-index: 1
  }

  html body .biolink-edit-mobile-view .smart-link-mibile-bottom {
    padding-bottom: 20px !important
  }

  .testimonial-content {
    padding: 30px;
    font-size: 18px;
    line-height: 25px
  }

  .testimonial-bottom img {
    max-width: 110px !important
  }

  .slider-left img.mb-15 {
    margin-bottom: 5px !important;
    max-width: 120px !important
  }

  .testimonial-bottom h5 {
    font-size: 16px
  }

  .mainmenu {
    width: 270px
  }

  .mobile-open.swipe-menu {
    -webkit-transform: translateX(-270px);
            transform: translateX(-270px)
  }

  body.mobile-open.swipe-menu .dl-menuwrapper {
    left: 285px
  }

  .mainmenu li a.child-triggerm {
    font-size: 14px
  }

  html body .cta-btn {
    font-size: 12px;
    padding: 4px 15px
  }
}

html body.hideMe .cartBox {
  opacity: 0 !important;
  visibility: hidden !important;
}

@media (max-width:500px) {
  html body .videoCheckboxClass .budgetForm {
    letter-spacing: 1px;
  }

  html .countParent .count {
    right: -6px;
    top: -14px;
  }

  html body .shiftToTop {
    padding-left: 40px !important;
  }

  html body .sqrbx {
    -webkit-transform: scale(1.2) rotate(45deg);
    transform: scale(1.2) rotate(45deg);
  }

  html body .headerCta.NewheaderCta a,
  .depecheMode+.headerCta.NewheaderCta a {
    min-width: 30px;
  }

  html body .headerCta.NewheaderCta a img {
    margin-right: 0 !important;
    max-width: 22px;
  }

  html body .layout-header-left {
    position: relative;
    top: 2px;
  }

  html body .layout-header {
    padding-bottom: 21px !important;
  }

  html .top-profile-layout .profile-circle-new img {
    width: 25px;
    height: 25px;
  }

  html .addVideoPopup .ant-checkbox-group .ant-col-8 {
    width: 100%;
    flex: initial;
    max-width: 100%
  }

  .headerCta a {
    font-size: 0;
    padding: 0 0 0 5px !important
  }

  .headerCta a .fa {
    font-size: 20px;
    margin: 0
  }

  html .headerCta.NewheaderCta {
    right: 31px;
    top: 19px;
  }

  html body .leftShifted {
    margin-right: 40px;
  }

  body .resultShow .chart-box>div {
    width: 100%
  }

  .chart-box {
    flex-direction: column;
    margin: 0 0 20px
  }

  .chart-box>div {
    margin-right: 0;
    width: 100%;
    margin-top: 20px
  }

  .flex-start.single-line-field {
    flex-direction: column
  }

  .flex-start.single-line-field>div {
    width: 100% !important
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@media (max-width:480px) {
  .map-slider .map-dotes:after {
    width: 100% !important
  }
}

.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: #5d45e8;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  -webkit-animation: 2s linear infinite sonarWave;
          animation: 2s linear infinite sonarWave
}

@keyframes sonarWave {
  from {
    opacity: .4
  }

  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes sonarWave {
  from {
    opacity: .4
  }

  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@media (min-width:1200px) and (max-width:1500px) {
  .mt-100 {
    margin-top: 70px !important
  }

  .videoCheckboxClass {
    width: 31%
  }
}

@media (min-width:1200px) and (max-width:1400px) {
  .videoCheckboxClass {
    width: 32.15%;
  }

  .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 7px !important;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  html .playPaush button {
    font-size: 13px;
    height: 20px;
    width: 20px;
  }

  .videoCheckboxClass .budgetForm {
    letter-spacing: .5px;
    padding: 9px 10px;
    font-size: 11px;
  }

  .borderLeft {
    margin-left: 4px;
  }

  .videoAdd,
  .samePadding.videoAdd {
    padding-left: 15px;
  }
}

@media (min-width:1200px) and (max-width:1400px) {
  .videoCheckboxClass {
    width: 32.15%;
  }

  .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 7px !important;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  html .playPaush button {
    font-size: 13px;
    height: 20px;
    width: 20px;
  }

  .videoCheckboxClass .budgetForm {
    letter-spacing: .5px;
    padding: 9px 10px;
    font-size: 11px;
  }

  .borderLeft {
    margin-left: 4px;
  }

  .videoAdd,
  .samePadding.videoAdd {
    padding-left: 15px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  html body .videoCheckboxClass {
    width: 32%;
  }

  .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 7px !important;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  html .playPaush button {
    font-size: 13px;
    height: 20px;
    width: 20px;
  }

  .videoCheckboxClass .budgetForm {
    letter-spacing: .1px;
    padding: 9px 9px;
    font-size: 11px;
  }

  .borderLeft {
    margin-left: 4px;
  }

  .videoAdd,
  .samePadding.videoAdd {
    padding-left: 15px;
  }
}

@media (min-width:1501px) and (max-width:1700px) {
  .videoCheckboxClass {
    width: 32%;
    margin-right: 10px !important;
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

@media (min-width:1351px) and (max-width:1500px) {
  html .leftShifted {
    margin-right: 285px;
  }

  body .layout-left {
    width: 245px !important;
  }

  html body .leftSidebarPadding {
    padding-left: 250px !important;
  }

  body .dashBoardTop .dashBoardright {
    width: 260px;
  }

  .dashBoardTop .dashBoardleft {
    width: calc(100% - 260px);
    padding-right: 20px;
  }

  .resultTabMain button {
    font-size: 14px;
  }

  .resultShow .right-section-dashboard {
    margin-left: 5px;
  }

  body .discussion-box .questions-author {
    width: 35px !important;
    min-width: 35px !important;
    height: 35px !important;
  }

  body .discussion-box .questions-author-text {
    padding-left: 10px;
  }

  body .discussion-box.questions .box-content h5 {
    font-size: 15px;
  }

  body .engagement-bottom.bottom>div {
    margin-right: 12px;
  }

  body .right-section-dashboard {
    width: 37%;
  }
}

.stepsNav button.not-active {
  opacity: 0;
  visibility: hidden;
}

@media (min-width:1201px) and (max-width:1350px) {
  .playPaush .ant-btn-loading-icon .anticon {
    margin-left: 7px !important;
  }

  html .leftShifted {
    margin-right: 285px;
  }

  .playPaush button {
    font-size: 14px;
  }

  body .layout-left {
    width: 245px !important;
  }

  html .shiftToTop {
    font-size: 32px;
    letter-spacing:25px;
  }

  html body .leftSidebarPadding {
    padding-left: 250px !important;
  }

  body .dashBoardTop .dashBoardright {
    width: 260px;
  }

  .dashBoardTop .dashBoardleft {
    width: calc(100% - 260px);
    padding-right: 20px;
  }

  .resultTabMain button {
    font-size: 14px;
  }

  body .discussion-box .questions-author {
    width: 35px !important;
    min-width: 35px !important;
    height: 35px !important;
  }

  body .discussion-box .questions-author-text {
    padding-left: 10px;
  }

  body .discussion-box.questions .box-content h5 {
    font-size: 15px;
  }

  body .engagement-bottom.bottom>div {
    margin-right: 12px;
  }

  body .right-section-dashboard {
    width: 37%;
  }

  .resultShow .right-section-dashboard {
    margin-top: 30px !important;
  }
}

.text-radio>.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  float: left;
  width: 65px;
  border-radius: 30px;
  overflow: hidden;
  height: 30px;
  border: 0 !important;
  background: #f6f6f6 !important;
  color: #848d9c !important;
  color: var(--site-secondary-color) !important;
  font-size: 14px;
  padding: 0;
  min-width: 65px;
  margin: 15px 10px 0 0;
  text-align: center;
  line-height: 33px
}

.text-radio>.container input {
  position: relative;
  opacity: 0;
  cursor: pointer
}

.text-radio>.container .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 100%;
  width: 100%
}

.text-radio>.container input:checked~.checkmark {
  background-color: #5d45e8;
  border-color: #5d45e8;
  color: #fff
}

.text-radio>.container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  -webkit-transform: scale(1.3);
  transform: scale(1.3)
}

@media (max-width:1240px) {
  .auth-copyrights ul li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 15px
  }

  .auth-copyrights {
    padding: 10px 50px 50px
  }
}

@media (max-width:993px) {
  .text-radio>.container {
    margin-right: 4px;
    width: 52px;
    min-width: 52px;
    font-size: 13px
  }
}

.menuBar {
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1
}

.ant-btn.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: 0 0
}

.barsBtn {
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  position: relative;
  top: -2px
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 3px;
  background: #fff
}

.barsBtn:after {
  top: auto;
  bottom: -6px
}

ul.before-login>li.ant-menu-submenu .ant-menu-title-content {
  font-weight: 600 !important;
  text-transform: capitalize !important
}

.ant-menu.ant-menu-horizontal>li.cta-btn:hover>a {
  -webkit-transform: none;
          transform: none;
  color: #000
}

.ant-menu.ant-menu-horizontal>li.search-box>a,
.large.ColorPickerDiv .ColorPickerInner .rc-color-picker-wrap {
  -webkit-transform: none;
          transform: none
}

.global-form .form-field .ant-input:focus {
  border: 0 !important;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%) !important
}

.global-form .form-field .ant-input::-webkit-input-placeholder,
.single-line-field .ant-picker .ant-picker-input input::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important
}

.global-form .form-field .ant-input::-moz-placeholder,
.single-line-field .ant-picker .ant-picker-input input::-moz-placeholder {
  color: #000 !important;
  opacity: 1 !important
}

.global-form .form-field .ant-input:-ms-input-placeholder,
.single-line-field .ant-picker .ant-picker-input input:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important
}

.global-form .form-field .ant-input:-moz-placeholder,
.single-line-field .ant-picker .ant-picker-input input:-moz-placeholder {
  color: #000 !important;
  opacity: 1 !important
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    -webkit-transform: translate3d(0, 40px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    -webkit-transform: translate3d(0, 40px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-60px, 0, 0);
    transform: translate3d(-60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-60px, 0, 0);
    transform: translate3d(-60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.loader-full {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center
}

.how-it-works-contant .how-it-works-left,
.how-it-works-contant .how-it-works-right,
.smart-link-left {
  width: 55%;
  float: left
}

.soundlike .ant-select-selection-item {
  line-height: 1 !important;
  font-weight: 400 !important
}

@media (min-width:768px) and (max-width:800px) {
  html body ul.before-login>li:nth-child(-n+5) {
    margin-left: 12px !important
  }
}

li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child span {
  color: #fff;
  opacity: 1 !important;
  height: auto !important
}

li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  opacity: 1 !important;
  height: auto !important;
  overflow-y: clip !important;
  pointer-events: painted !important
}

.career-page-full-man {
  padding: 49px 0 100px;
  margin-top: -7px;
  background: #000
}

.career-page-contant-section {
  text-align: center;
  margin: 0 auto;
  display: inline-block
}

.career-page-contant-section img {
  margin-bottom: 34px;
  max-width: 270px;
  max-height: 284px
}

.career-page-contant-section p {
  color: #fff;
  line-height: 1.5;
  letter-spacing: .76px;
  font-size: 22px;
  margin: 0 0 50px;
  font-weight: 400
}

.career-page-contant-section p span {
  color: #39b8ff;
  font-weight: 700
}

.career-page-contant-section p:last-child {
  margin-bottom: 30px
}

.how-it-works-page-full-man {
  padding: 70px 0 0;
  margin-top: -7px;
  background: #000
}

.how-it-works-left img {
  width: 100%;
  height: auto;
  max-width: 611px
}

.how-it-works-contant-raw:first-child .how-it-works-right {
  width: 1110px;
  margin-left: 22px;
  max-width: 100%
}

.how-it-works-contant-raw:first-child .how-it-works-left img {
  max-width: 231px;
  margin-left: 0 !important;
  max-height: 206px
}

.how-it-works-contant-raw:first-child .how-it-works-left {
  margin-left: 26px
}

.how-it-works-right span {
  display: inline-block;
  border-radius: 50%;
  font-size: 26px;
  color: #fff;
  padding: 13px 20px;
  border: 1px solid rgba(255, 255, 255, .5);
  background-color: rgba(255, 255, 255, .2)
}

.how-it-works-right h5 {
  font-size: 30px;
  color: #fff;
  letter-spacing: .76px;
  padding: 20px 0 14px;
  margin: 0 !important;
  line-height: 1
}

.how-it-works-right p {
  margin-bottom: 24px;
  line-height: 1.3;
  color: #fff;
  letter-spacing: .4px;
  font-size: 18px
}

.how-it-works-right button span {
  padding: 0;
  background: 0 0;
  font-size: 16px;
  text-transform: capitalize;
  border: none;
  border-radius: 0;
  display: inherit
}

.how-it-works-right button {
  margin-top: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 33px !important;
  height: 45px !important;
  min-width: 110px;
  line-height: 35px !important;
  text-align: center;
  transition: .8s;
  border-radius: 7px;
  border: 0 !important;
  background: #2ea5c2
}

.how-it-works-contant-raw {
  margin-bottom: 96px
}

.how-it-works-right {
  max-width: 400px
}

.how-it-works-contant {
  max-width: 1120px;
  margin: 0 auto
}

.how-it-works-contant>.how-it-works-contant-raw:nth-child(odd) .how-it-works-left img {
  margin-left: 40px
}

.how-it-works-contant-raw:last-child {
  margin-bottom: 87px
}

.single-post-modal.how-it-work-light-box .ant-modal-root p {
  padding: 0 30px 0 0;
  float: left;
  margin: 0;
  line-height: 1.3;
  color: #1d2a3b;
  letter-spacing: .4px;
  font-size: 20px
}

.single-post-modal.how-it-work-light-box .ant-modal-body {
  padding: 30px 35px;
  border-radius: 8px
}

.single-post-modal.how-it-work-light-box .ant-modal-close-x {
  background: #000;
  border-radius: 100%;
  -webkit-transform: scale(.5);
          transform: scale(.5);
  color: #fff
}

.single-post-modal.how-it-work-light-box .ant-modal-close {
  color: #fff;
  border-radius: 100%;
  top: 20px;
  right: 20px
}

.single-post-modal.how-it-work-light-box .anticon-close {
  font-size: 31px !important;
  position: relative;
  top: 5px
}

.single-post-modal.how-it-work-light-box .ant-modal-body p {
  font-size: 18px;
  color: #848d9c
}

.global-form.videofacebook input {
  width: 70%;
  margin: 22px 0 17px;
  padding: 12px 22px;
  box-shadow: 0 7px 30px rgb(37 37 37 / 22%);
  color: #000 !important;
  letter-spacing: .4px
}

.facebook-text {
  text-align: center !important
}

.facebook-text:hover {
  cursor: pointer !important;
  background: -webkit-linear-gradient(#027fc5, #92fbbc) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important
}

.global-form.videofacebook .ant-input:focus {
  box-shadow: 0 7px 30px rgb(37 37 37 / 22%) !important
}

.global-form.videofacebook .ant-input::-webkit-input-placeholder {
  color: #c3c3c3 !important
}

.global-form.videofacebook .ant-input::placeholder {
  color: #c3c3c3 !important
}

.about-new-page {
  padding: 49px 0 60px;
  margin-top: -7px;
  height: 100%;
  background: #000
}

.about-new-page h2 {
  color: #fff;
  font-size: 30px
}

.about-new-page .title-section {
  color: #fff;
  font-size: 18px;
  line-height: 1.3
}

.about-new-page .title-section strong {
  color: #39b8ff
}

.video-steps-tab-title.smart-link-page a span {
  border-radius: 10px !important;
  font-size: 20px;
  border: 1px solid #8e8e8e !important;
  width: 50px !important;
  height: 50px !important;
  font-weight: 400;
  margin-right: 14px !important
}

.video-steps-tab-title.smart-link-page a.active span {
  border: none !important
}

.video-steps-tab-title.smart-link-page a span:after {
  padding: 6px 10px;
  background: #2ea5c2;
  font-size: 20px
}

.video-steps-tab-title.smart-link-page a.active {
  font-weight: 400 !important
}

.video-steps-tab-title.smart-link-page {
  padding-bottom: 24px !important;
  position: relative
}

.video-steps-tab-title.smart-link-page button {
  height: 59px !important;
  position: absolute;
  right: 0;
  border-radius: 10px;
  border: none !important;
  background: #2ea5c2;
  margin: 0 !important;
  padding: 0 36px
}

.desktopBgPreviewBtn span,
.publish-button button span,
.video-steps-tab-title.smart-link-page button span {
  color: #fff;
  letter-spacing: 1.2px;
  font-weight: 700;
  font-size: 18px !important
}

.video-steps-tab-title.smart-link-page .preview-smart-ling span {
  letter-spacing: 2.7px;
  font-weight: 700;
  font-size: 18px !important;
  margin: 0 !important;
  background: 0 0;
  width: auto !important;
  border: none !important;
  color: #fff
}

.smart-link-right {
  width: 40%;
  float: right
}

.smart-link-left h3 {
  font-size: 30px;
  line-height: 1;
  color: #000;
  margin-bottom: 23px !important;
  float: left;
  width: 100%
}

.smart-link-left input {
  font-style: italic;
  font-size: 16px;
  color: #000 !important;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08);
  background-color: #fff;
  width: 100%;
  padding: 17px 19px
}

.smart-link-left .label {
  line-height: 1;
  font-size: 18px;
  color: #2b2b2b !important;
  margin-bottom: 15px
}

.smart-link-left p {
  color: #000;
  font-size: 18px;
  margin-bottom: 40px
}

.smart-link-form-box,
.smart-link-form-box-link-setting {
  float: left;
  width: 100%;
  margin-bottom: 30px
}

.smart-link-form-box-logo-left-full {
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 100%;
  display: flex;
  margin-bottom: 20px
}

.close-box-icon {
  float: right;
  text-align: right
}

.close-box-icon img,
.close-image-icon img,
.view-edit-delete-row img {
  cursor: pointer
}

.smart-link-form-box-logo {
  float: left
}

.smart-link-form-box-link-setting .label {
  width: 100px !important;
  float: left !important;
  margin: 0 20px 0 0 !important
}

.smart-link-form-box-link-setting input {
  float: left;
  width: 85%
}

.smart-link-form-box-link-setting .full-width.form-field {
  display: flex;
  margin: 0;
  align-items: center;
  position: relative
}

input.page-setting-first-input {
  box-shadow: none;
  background: #e6e6e6;
  border: none
}

.select-smart-link {
  width: 100%;
  position: relative;
  max-width: 300px
}

.select-smart-link select {
  -webkit-appearance: none;
  font-style: italic;
  padding: 17px 20px;
  width: 100%;
  color: #2b2b2b;
  border: 1px solid #e8eaed;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: 150ms
}

.avatar-upload img,
.url-edit-row {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08)
}

.select-smart-link select:required:invalid {
  color: #2b2b2b
}

.select-smart-link select option {
  color: #223254
}

.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none
}

.select-smart-link:after {
  z-index: 99999;
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 19px;
  top: 18px;
  font-size: 37px;
  width: 20px;
  color: #000;
  height: 20px
}

.avatar-upload {
  float: left;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center
}

.avatar-upload .avatar-edit {
  z-index: 1;
  top: 10px
}

.smart-link-left input::-webkit-input-placeholder {
  color: #2b2b2b8c !important
}

.smart-link-left input::placeholder {
  color: #2b2b2b8c !important
}

.smart-link-left input:-ms-input-placeholder {
  color: #2b2b2b8c !important
}

.smart-link-left input::-ms-input-placeholder {
  color: #2b2b2b8c !important
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: none;
  box-shadow: none
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.avatar-upload img {
  width: 204px;
  border-radius: 10px;
  height: 204px;
  background: #fff
}

.smart-link-form-box-link-setting textarea {
  float: left;
  width: 85%;
  font-style: italic;
  font-size: 16px;
  color: #000 !important;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  background-color: #fff;
  padding: 17px 19px;
  height: 140px
}

.smart-link-form-box-link-setting textarea::-webkit-input-placeholder {
  color: #2b2b2b8c !important
}

.smart-link-form-box-link-setting textarea::placeholder {
  color: #2b2b2b8c !important
}

.smart-link-form-box-link-setting textarea:-ms-input-placeholder {
  color: #2b2b2b8c !important
}

.smart-link-form-box-link-setting textarea::-ms-input-placeholder {
  color: #2b2b2b8c !important
}

.amsrt-link-logo-row {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  width: 83%;
  float: left
}

.amsrt-link-logo-img {
  border-bottom: 1px solid #e0e0e0;
  padding: 25px 30px
}

.smart-link-left h5 {
  font-size: 20px;
  color: #000;
  margin: 0 !important
}

.smart-link-left .social-iocn-top-row .toggle label {
  color: none !important;
  border: none;
  float: left;
  background: 0 0;
  width: 0;
  height: auto;
  min-width: 0;
  padding: 0;
  position: relative
}

.smart-link-left .social-iocn-top-row .toggle input[type=checkbox]+label::before {
  content: " ";
  display: block;
  height: 33px;
  width: 63px;
  border-radius: 16.5px;
  position: absolute;
  top: -15px;
  left: -86px;
  background: #2ea5c2
}

.smart-link-left .social-iocn-top-row .toggle input[type=checkbox]+label::after {
  content: " ";
  display: block;
  height: 17px;
  width: 10px;
  border-radius: 5px;
  position: absolute;
  top: -6px;
  left: -75px;
  background: #fff;
  transition: .3s ease-in
}

.smart-link-left .social-iocn-top-row .toggle input[type=checkbox]:checked+label::after {
  left: -40px;
  transition: .3s ease-in
}

.social-iocn-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px
}

.social-icon-row {
  align-items: center;
  display: flex;
  margin-bottom: 40px
}

.social-icon-row img {
  margin-right: 30px;
  max-width: 76px;
  max-height: 76px;
  border-radius: 100%
}

.smart-link-left .social-icon-row img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%)
}

.smart-link-right-row {
  position: -webkit-sticky;
  position: sticky;
  margin: 0 auto;
  max-width: 400px;
  top: 100px
}

.smart-link-right-row img {
  margin: 0 auto;
  overflow: hidden;
  max-width: 400px
}

.smart-link-right-mibile-top-img {
  position: absolute;
  top: 25px;
  right: 0;
  left: 24px
}

.smart-link-right-mibile-top-img img {
  height: 390px;
  max-height: 390px;
  position: relative;
  z-index: -1;
  overflow: hidden;
  width: 96%
}

.smart-link-mibile-bottom {
  height: 364px;
  overflow-x: auto;
  border-radius: 0 0 30px 30px;
  float: left;
  width: 93.4%;
  background: #241e24;
  padding: 24px 37px 80px
}

.button-spotify-box-amazonMusic,
.button-spotify-box-appleMusic,
.button-spotify-box-deezer,
.button-spotify-box-itunes,
.button-spotify-box-pandora,
.button-spotify-box-spotify,
.button-spotify-box-tidal,
.button-spotify-box-youtubeMusic {
  padding: 7px 20px;
  border-radius: 2px;
  margin-bottom: 8.5px
}

.smart-link-mibile-bottom h5 {
  font-size: 21px;
  color: #fff;
  margin: 0 0 10px !important;
  padding: 0
}

.smart-link-mibile-bottom p {
  font-size: 13px !important;
  color: #babbc1;
  margin: 0 0 10px;
  padding: 0
}

.button-spotify-row {
  overflow: hidden;
  height: auto;
  float: left;
  width: 100%;
  margin-top: 8px
}

.privacy-policy-mobile,
.social-smart-link-tab {
  position: absolute;
  width: 93.5%;
  display: inline-block
}

.button-spotify-box-amazonMusic a,
.button-spotify-box-deezer a,
.button-spotify-box-itunes a,
.button-spotify-box-pandora a,
.button-spotify-box-spotify a,
.button-spotify-box-tidal a,
.button-spotify-box-youtubeMusic a {
  display: flex;
  align-items: center;
  font-size: 21px;
  display: flex;
  align-items: center;
  color: #fff
}

.button-spotify-box-amazonMusic a img,
.button-spotify-box-appleMusic a img,
.button-spotify-box-deezer a img,
.button-spotify-box-itunes a img,
.button-spotify-box-pandora a img,
.button-spotify-box-spotify a img,
.button-spotify-box-tidal a img,
.button-spotify-box-youtubeMusic a img {
  padding: 0;
  margin: 0 12px 0 15px;
  z-index: 1;
  width: 35px;
  height: 35px;
  max-width: 35px
}

.button-spotify-box-spotify,
.step2-biolink-social-inner .spotify {
  background: #42d16e
}

.button-spotify-box-appleMusic a {
  display: flex;
  align-items: center;
  font-size: 21px;
  display: flex;
  align-items: center;
  color: #000
}

.button-spotify-box-itunes,
.step2-biolink-social-inner .iTunes {
  background: #a770ed
}

.button-spotify-box-tidal {
  background: #0cac99
}

.button-spotify-box-amazonMusic {
  background: #4f0ff9
}

.button-spotify-box-youtubeMusic,
.step2-biolink-social-inner .youtube {
  background: #f40418
}

.button-spotify-box-pandora {
  background: #00a0ee
}

.social-smart-link-tab {
  margin: 10px -20px 12px;
  text-align: center;
  left: 20px;
  z-index: 9;
  background: #241e24;
  bottom: 0
}

.social-smart-link-tab img {
  padding: 0;
  z-index: 1;
  margin: 0 4px;
  text-align: center;
  object-fit: unset;
  width: 35px;
  height: 41px
}

.privacy-policy-mobile {
  z-index: 11;
  border-radius: 13px;
  background: #171617;
  bottom: -5px;
  left: 0
}

.privacy-policy-mobile ul li a {
  font-size: 13px;
  color: #8b8b8b;
  line-height: .9;
  padding: 10px;
  text-align: center
}

.leptop-device-preview-right-section .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box img,
.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img.black-bg .button-spotify-row img {
  visibility: visible
}

.leptop-device-preview .leptop-device-preview-row-desktop .button-spotify-row img,
.leptop-device-preview-man-row .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row img {
  -webkit-filter: blur(0px);
          filter: blur(0px)
}

.privacy-policy-mobile ul li:first-child a,
.privacy-policy-mobile.link-view-page-privacy ul li:nth-child(2) a {
  border-right: 1px solid #8b8b8b
}

.smart-link-mibile-bottom::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px
}

.smart-link-mibile-bottom::-webkit-scrollbar-track {
  background: #f1f1f1
}

.smart-link-mibile-bottom::-webkit-scrollbar-thumb {
  background: #888
}

.smart-link-mibile-bottom::-webkit-scrollbar-thumb:hover {
  background: #555
}

.smart-link-mibile-bottom.secend-section-mibile h5 {
  text-align: center;
  color: #000
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-box {
  padding: 0;
  background: 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.leptop-device-preview-right-section .smart-link-mibile-bottom.secend-section-mibile button,
.smart-link-mibile-bottom.secend-section-mibile .button-spotify-box a {
  color: #484040
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-box img {
  max-height: 35px;
  max-width: initial;
  margin-left: 0
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-box:nth-child(4) img {
  width: 85px
}

.button-spotify-box button {
  font-size: 17px;
  width: 121px;
  padding: 12px 11px;
  border-radius: 10px;
  border: 1px solid #babbc1;
  background-color: #fff
}

.addVideoPopup .video-steps-tab-title button span,
.leptop-device-preview-row,
.leptop-device-preview-row-desktop {
  position: relative;
  width: 100%
}

.smart-link-mibile-bottom.secend-section-mibile {
  border-radius: 0 0 30px 30px;
  padding: 24px 25px 87px;
  background: #fff
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
  height: auto
}

.ant-modal.single-post-modal.how-it-work-light-box.smartlink-preview {
  width: 100% !important;
  max-width: 1170px !important
}

.leptop-device-preview-row {
  height: auto;
  overflow: hidden
}

.leptop-device-preview-row-desktop img {
  position: relative;
  width: 100%;
  height: auto
}

.desktop-middle-img {
  overflow: hidden;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0
}

.desktop-middle-img img {
  height: 530px;
  display: flex;
  margin: 0 auto;
  width: 78%;
  visibility: visible;
  overflow: hidden;
  text-align: center;
  max-height: 530px;
  -webkit-filter: blur(40px);
  filter: blur(40px);
  -webkit-transform: scale(1);
          transform: scale(1)
}

.desktop-middle-img>img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1)
}

.leptop-device-preview-man-row {
  left: 0;
  justify-content: space-between;
  position: absolute;
  max-width: 620px;
  margin: 40px auto 0;
  display: flex;
  right: 0;
  top: 13%
}

.leptop-device-preview-top-img {
  float: left;
  height: 365.3px !important
}

.leptop-device-preview-top-img img {
  width: 280px;
  height: 100%;
  visibility: visible !important;
  -webkit-filter: blur(0);
          filter: blur(0);
  object-fit: cover
}

.leptop-device-preview-right-section .smart-link-mibile-bottom p {
  font-size: 13px !important
}

.leptop-device-preview-right-section .privacy-policy-mobile {
  border-radius: 0;
  position: inherit
}

.leptop-device-preview-right-section .smart-link-mibile-bottom {
  border-radius: 0
}

.logo-man-preview {
  display: flex;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  align-items: center
}

.logo-man-preview img {
  max-width: 150px;
  height: auto;
  -webkit-filter: blur(0px);
          filter: blur(0px);
  visibility: visible !important
}

.leptop-device-preview-man-row .smart-link-mibile-bottom {
  border-radius: 0;
  padding: 29px 12px 70px 15px;
  margin-right: 46px
}

.black-bg img,
.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img.black-bg img {
  visibility: hidden
}

.desktop-middle-img.black-bg {
  top: 33px;
  right: 0;
  background: #000;
  width: 78%;
  left: 0;
  margin: 0 auto;
  height: 86%
}

.button-baselayout-box-appleMusic {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 8.5px
}

.button-baselayout-box-amazonMusic a,
.button-baselayout-box-appleMusic a,
.button-baselayout-box-deezer a,
.button-baselayout-box-itunes a,
.button-baselayout-box-pandora a,
.button-baselayout-box-spotify a,
.button-baselayout-box-tidal a,
.button-baselayout-box-youtubeMusic a {
  display: flex;
  font-size: 21px;
  color: #484040;
  float: left;
  align-items: center
}

.button-baselayout-box-amazonMusic a img,
.button-baselayout-box-appleMusic a img,
.button-baselayout-box-deezer a img,
.button-baselayout-box-itunes a img,
.button-baselayout-box-pandora a img,
.button-baselayout-box-spotify a img,
.button-baselayout-box-tidal a img,
.button-baselayout-box-youtubeMusic a img {
  width: 38px;
  height: 38px;
  margin: 0 10px 0 0
}

.button-baselayout-box-appleMusic button {
  font-size: 18px;
  color: #484040;
  padding: 12px 39px;
  border-radius: 10px;
  border: 1px solid #babbc1;
  background-color: #fff
}

.button-baselayout-box-amazonMusic,
.button-baselayout-box-deezer,
.button-baselayout-box-itunes,
.button-baselayout-box-pandora,
.button-baselayout-box-spotify,
.button-baselayout-box-tidal,
.button-baselayout-box-youtubeMusic {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 8.5px
}

.button-baselayout-box-amazonMusic button,
.button-baselayout-box-deezer button,
.button-baselayout-box-itunes button,
.button-baselayout-box-pandora button,
.button-baselayout-box-spotify button,
.button-baselayout-box-tidal button,
.button-baselayout-box-youtubeMusic button {
  font-size: 18px;
  color: #484040;
  padding: 11px 39px;
  border-radius: 10px;
  border: 1px solid #babbc1;
  background-color: #fff
}

.publish-button button,
.publish-button button:active,
.publish-button button:focus {
  height: 55px !important;
  border-radius: 10px;
  border: none !important;
  background: #2ea5c2;
  margin: 0 !important;
  padding: 0 36px;
  box-shadow: 0 6px 35px 0 rgb(0 0 0 / 15%)
}

.publish-button {
  margin-left: 107px
}

.close-image-icon {
  position: absolute;
  right: 0;
  top: 0
}

.leptop-device-preview-right-section .social-smart-link-tab img {
  visibility: visible !important;
  padding: 0;
  z-index: 1;
  margin: 0 4px;
  text-align: center;
  object-fit: unset;
  width: 30px;
  height: 30px;
  -webkit-filter: blur(0px);
          filter: blur(0px);
  border-radius: 100%
}

.leptop-device-preview-top-img.bgimg-main img,
.smart-link-listing {
  object-fit: cover !important
}

.desktop-middle-img.black-bg .button-spotify-row img {
  visibility: visible;
  -webkit-filter: blur(0px);
          filter: blur(0px)
}

.url-edit-row {
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 14px 13px
}

.smart-link-listing {
  height: 248px !important;
  border-radius: 20px 20px 0 0 !important
}

.url-link-row a {
  line-height: 1;
  font-size: 14px;
  color: #027fc5
}

.amsrt-link-logo-img:last-child {
  border-bottom: none
}

.leptop-device-preview-row-desktop.viewpageurl img {
  visibility: hidden;
  height: 0
}

.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img img {
  visibility: visible;
  width: 100%;
  object-fit: cover;
  height: 100vh;
  max-height: 100%
}

.leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-top-img img {
  width: 450px;
  object-fit: fill;
  height: 100%
}

.leptop-device-preview-row-desktop.viewpageurl .button-spotify-row img {
  height: 35px;
  -webkit-filter: blur(0px);
          filter: blur(0px)
}

.leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab img {
  height: 30px;
  width: 30px;
  border-radius: 100%
}

.leptop-device-preview-row-desktop.viewpageurl .logo-man-preview img {
  height: auto !important;
  max-width: 190px
}

.leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-man-row {
  max-width: 930px;
  margin: 20px auto 0;
  width: 100%;
  height: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.leptop-device-preview-row-desktop.viewpageurl .logo-man-preview {
  top: 90px
}

.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img.black-bg {
  top: 0;
  width: 100%;
  height: 100vh
}

.leptop-device-preview.viewpageurl-row .leptop-device-preview-row {
  overflow: inherit
}

.leptop-device-preview-row-desktop.viewpageurl .smart-link-mibile-bottom.secend-section-mibile .social-smart-link-tab {
  width: 108%
}

.leptop-device-preview-row-desktop.viewpageurl .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row img {
  visibility: visible;
  width: 35px
}

.leptop-device-preview.viewpageurl-row {
  margin-top: -71px
}

.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img {
  position: static
}

.leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-right-section .smart-link-mibile-bottom {
  height: 625px
}

.leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-top-img {
  height: 658px !important
}

.leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab {
  margin: 0;
  width: 93.5%;
  border: none;
  left: 0;
  padding: 12px 0;
  background: #241e24
}

.feature-right p {
  opacity: 1 !important
}

html body .ant-menu .ant-menu-item.none-display {
  display: none !important;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child:first-child a:hover {
  -webkit-transform: translateY(0) !important;
          transform: translateY(0) !important
}

button.ant-btn.ant-btn-primary.submit-btn.free-trial-color-button {
  border-radius: 50px;
  background: #35d36e
}

.feature-tools-bg .title-section h2 {
  margin-bottom: 60px !important
}

.video-step-right.text-center.comming-soon-page img {
  max-width: 300px
}

h1.coming-soon-title {
  font-size: 50px;
  color: #fff
}

.coomig-soon-smart-link {
  min-height: 710px;
  background: url(https://fanify-public.s3.ap-south-1.amazonaws.com/fanify/images/Union+3.svg) no-repeat #000;
  margin-top: -7px;
  background-attachment: unset !important;
  background-size: contain !important
}

.url-copy-row,
.we-are-coming {
  display: flex;
  align-items: center
}

.subscription-buuton-right {
  float: right !important
}

.csFGk.background-image-main {
  background-image: url(https://fanify-public.s3.ap-south-1.amazonaws.com/fanify/images/Union+3.svg);
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important
}

.leptop-device-preview-row:after,
.leptop-device-preview-row:before {
  background: #fff;
  height: 564px;
  width: 106px;
  top: 0;
  position: absolute;
  content: ""
}

.leptop-device-preview-row:before {
  z-index: 9;
  right: 0
}

.mobile-image-none .smart-link-mibile-bottom {
  border-radius: 30px 30px 0 0;
  height: 752px
}

.mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
  border-radius: 30px
}

p.subscribe-client-say-italic {
  font-style: italic
}

.leptop-device-preview-man-row.leptop-image-none {
  max-width: 340px
}

.smart-link-new-listing-page-box {
  padding: 10px;
  align-items: center;
  float: left;
  width: 49%;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, .5);
  display: flex;
  margin: 0 22px 22px 0;
  background: #000
}

.smart-link-new-listing-page-box-left-img {
  width: 192px;
  float: left;
  display: flex;
  align-items: center;
  padding-right: 15px
}

.smart-link-new-listing-page-box-left-img img {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  object-fit: cover
}

.smart-link-new-listing-page-box-right-contant {
  width: 381px;
  float: left
}

.smart-link-new-listing-page-box-right-contant a {
  color: #fff;
  font-weight: 600;
  line-height: 1;
  font-style: italic;
  font-size: 18px
}

.smart-link-new-listing-page-box-right-contant h5 {
  font-size: 20px;
  color: #fff;
  line-height: 1;
  padding: 11px 0;
  margin: 0 !important
}

.smart-link-new-listing-page-box-right-contant p {
  letter-spacing: .3px;
  color: #fff;
  margin: 0;
  line-height: 1.4;
  font-size: 15px
}

.smart-link-new-listing-man-row {
  background-color: #000;
  margin-top: -7px;
  min-height: 720px;
  height: 100%
}

.url-copy-row img {
  cursor: pointer;
  padding: 0 0 0 40px
}

.view-edit-delete-row.new-listing-page {
  padding-top: 15px;
  float: left
}

.view-edit-delete-row.new-listing-page img {
  width: 28px;
  margin-right: 10px;
  height: 28px
}

.leptop-device-preview.viewpageurl-row .leptop-device-preview-row:after,
.leptop-device-preview.viewpageurl-row .leptop-device-preview-row:before {
  content: none
}

.smart-link-new-listing-man-row .add-video-link img {
  -webkit-filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(222deg) brightness(106%) contrast(100%);
          filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(222deg) brightness(106%) contrast(100%)
}

.leptop-device-preview-right-section .social-smart-link-tab {
  display: inline-block;
  width: 93%;
  text-align: center;
  right: 43px;
  z-index: 9;
  background: #241e24;
  bottom: 16px
}

.social-smart-link-tab.social-media-tab-white,
.step2-biolink-social-inner .library {
  background: #fff
}

.social-smart-link-tab.social-media-tab-white.light-social-media-section {
  background: #302d31
}

.leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-man-row.leptop-image-none {
  max-width: 577px
}

.full-width.form-field.form-checkbox.checkbox-with-scroll.form-square-checkbox.top-margin {
  border-radius: 20px;
  padding: 30px 20px;
  box-shadow: 0 15px 50px rgb(201 201 201 / 69%)
}

.free-trial-mobile-device-show {
  font-size: 15px;
  border-radius: 37px;
  background-color: #fff4a3 !important;
  color: #000 !important;
  padding: 5px 20px 4px;
  margin: 0 15px 0 0;
  display: none
}

.price-first-section i {
  padding: 0 0 0 3px
}

.leftMenu ul li {
  position: static !important;
  height: auto !important;
  opacity: 1 !important
}

.ant-menu-submenu-disabled {
  margin: 0 !important;
  visibility: hidden;
  height: 0 !important;
  width: 0 !important
}

.error-page.man-error-page-smartlink {
  text-align: center;
  padding: 200px 0
}

.error-page.man-error-page-smartlink h1 {
  text-align: center;
  font-size: 50px;
  width: 100%;
  display: inline-block;
  margin: 50px auto 20px !important
}

.error-content {
  text-align: center;
  margin: -4px 0 30px
}

.error-page.man-error-page-smartlink .default-cta {
  padding: 7px 44px !important;
  height: auto !important;
  margin: 0 auto;
  display: inline-block
}

.left-section-dashboard {
  float: left;
  width: 63%
}

.right-section-dashboard {
  margin-left: 23px;
  width: 35%;
  margin-top: 20px;
  float: left
}

.right-dashboard-action {
  background: #fff;
  border-radius: 20px;
  float: left;
  height: 370px;
  box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 8%);
  width: 100%
}

.right-dashboard-action h2 {
  border-radius: 20px 20px 0 0;
  padding: 20px 36px;
  background: #e4e4e4;
  font-size: 28px;
  letter-spacing: .56px;
  margin: 0 !important;
  color: #000;
  line-height: 1
}

.input-feild-dashboard-desceiption p {
  line-height: 1.2;
  margin: 0 0 2px;
  font-size: 18px;
  color: #000;
  letter-spacing: .44px
}

.input-feild-dashboard-desceiption a {
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
  color: #027fc5;
  font-size: 15px;
  line-height: 1
}

.input-feild-dashboard-desceiption-row-man {
  overflow-x: auto;
  height: 280px
}

.input-feild-dashboard-desceiption {
  border-bottom: 1px solid #c8c8c8;
  padding: 25px 36px;
  float: left;
  width: 100%
}

.input-feild-dashboard-desceiption-row-man::-webkit-scrollbar {
  width: 5px;
  height: 5px
}

.input-feild-dashboard-desceiption-row-man::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, .1)
}

.input-feild-dashboard-desceiption-row-man::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, .2)
}

.input-feild-dashboard-desceiption-row-man::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .4)
}

.input-feild-dashboard-desceiption-row-man::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9)
}

.spin-box.video-contant-row-box .ant-spin.ant-spin-spinning span {
  top: 44%
}

.spin-box.video-contant-row-box .ant-spin.ant-spin-spinning {
  width: 100%;
  background: #b2b4c0cc;
  height: 100%
}

.spin-box.video-contant-row-box {
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0
}

.comming-soon-page-biolink img {
  max-width: 100%;
  width: 100% !important
}

@media only screen and (max-width:1500px) {
  .samePadding.videoAdd .left-section-dashboard {
    width: 62%
  }
}

@media only screen and (max-width:1260px) {
  .jvAuOB .post-box.smart-listing-page:nth-child(2n) {
    margin-right: 0
  }

  .post-box.smart-listing-page {
    width: 48% !important
  }

  .jvAuOB .post-box.smart-listing-page:nth-child(3n) {
    margin: 20px 0 35px -3px
  }

  .jvAuOB .post-box.smart-listing-page:nth-child(10),
  .jvAuOB .post-box.smart-listing-page:nth-child(4) {
    margin: 20px 0 35px 33px
  }

  .leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img {
    top: 0
  }

  .left-section-dashboard {
    width: 100%
  }

  .chart-box>div {
    width: 420px
  }

  .right-section-dashboard {
    margin: 40px 0 0;
    width: 50%
  }
}

@media only screen and (max-width:1210px) {
  .insight-listing-content {
    left: 0;
    width: 100%;
    margin-right: 0
  }

  .smart-link-new-listing-page-box {
    width: 100%
  }

  .smart-link-new-listing-page-box-right-contant {
    width: 83%
  }
}

@media only screen and (max-width:1160px) {
  .desktop-middle-img img {
    max-height: 523px
  }

  .leptop-device-preview-row::after,
  .leptop-device-preview-row:before {
    visibility: hidden
  }
}

@media only screen and (max-width:1150px) {
  .desktop-middle-img img {
    max-height: 519px
  }
}

@media only screen and (max-width:1130px) {
  .desktop-middle-img img {
    max-height: 510px
  }
}

@media only screen and (max-width:1110px) {
  .desktop-middle-img img {
    max-height: 500px
  }
}

@media only screen and (max-width:1100px) {
  .how-it-works-contant>.how-it-works-contant-raw:nth-child(2n) .how-it-works-right {
    margin-left: 40px
  }

  .smart-link-right-row img {
    max-width: 100%
  }

  .smart-link-right-mibile-top-img {
    top: 18px;
    left: 22px
  }

  .desktop-middle-img.black-bg {
    top: 30px
  }
}

@media only screen and (max-width:1092px) {
  .desktop-middle-img img {
    max-height: 491px
  }
}

@media only screen and (max-width:1074px) {
  .desktop-middle-img img {
    max-height: 481px
  }
}

@media only screen and (max-width:1067px) {
  .social-smart-link-tab img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0
  }

  .leptop-device-preview-row .smart-link-mibile-bottom {
    padding-bottom: 120px
  }

  .leptop-device-preview-right-section .social-smart-link-tab img {
    visibility: visible !important;
    height: 30px;
    width: 100%
  }

  .leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab img {
    height: 30px;
    width: 30px
  }

  .smart-link-mibile-bottom {
    height: 352px
  }

  .desktop-middle-img img {
    max-height: 478px
  }
}

@media only screen and (max-width:1060px) {
  .button-spotify-box {
    padding: 5px 20px
  }

  .button-spotify-row {
    height: auto
  }

  .leptop-device-preview-top-img {
    height: 352.3px
  }

  .logo-man-preview {
    top: 15px
  }

  .leptop-device-preview-man-row {
    margin: 23px auto 0
  }

  .smart-link-mibile-bottom {
    height: 341px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 732px
  }
}

@media only screen and (max-width:1050px) {
  .smart-link-mibile-bottom {
    height: 324px
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
    height: auto
  }

  .desktop-middle-img img {
    max-height: 469px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 721px
  }
}

@media only screen and (max-width:1032px) {
  .desktop-middle-img img {
    max-height: 460px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 706px
  }

  .leptop-device-preview-man-row {
    overflow-x: auto;
    height: 299px
  }
}

@media only screen and (max-width:1018px) {
  .smart-link-mibile-bottom {
    height: 306px
  }

  .desktop-middle-img img {
    max-height: 453px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 695px
  }
}

@media only screen and (max-width:1001px) {
  .desktop-middle-img img {
    max-height: 444px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 683px
  }
}

@media only screen and (max-width:1200px) {
  html .playPaush .ant-btn-loading-icon .anticon {
    margin-left: 5px !important;
  }

  .headerCta.NewheaderCta {
    top: 13px;
  }

  .leftShifted {
    margin-right: 100px;
  }

  .playPaush button {
    font-size: 12px;
    margin-left: 3px;
    height: 20px;
    width: 20px;
  }

  body .layout-header+div {
    padding-top: 103px
  }

  .desktop-middle-img img {
    width: 61%
  }

  .videoCheckboxClass {
    width: 31%
  }

  .resultTabMain button {
    font-size: 14px
  }

  body .layout-header {
    padding: 17px 0 !important;
    border-bottom: 1px solid #29faff
  }

  .ant-layout-sider-collapsed+.layout-right .leftSidebarPadding,
  body .leftSidebarPadding {
    padding-left: 30px !important
  }

  body .layout-header,
  body .layout-right .layout-right-padding {
    padding-right: 25px !important
  }

  body .layout-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%)
  }

  body .layout-left.ant-layout-sider-collapsed {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }

  .sqrbx {
    width: 35px;
    height: 35px;
    right: -17px;
    top: 18px
  }
}

@media only screen and (max-width:991px) {
  .sqrbx:after {
    position: absolute;
    left: -7px;
    top: 87%;
    height: 100%;
    width: 171%;
    content: "";
    background: #000;
    -webkit-transform: rotate(45deg) translateX(-50%);
            transform: rotate(45deg) translateX(-50%)
  }

  .videoCheckboxClass {
    width: 47%
  }

  .dashBoardTop .dashBoardleft {
    width: calc(100% - 220px)
  }

  .dashBoardTop .dashBoardright {
    width: 220px
  }

  .resultTabMain button {
    font-size: 12px
  }

  .videoAddTop .right,
  .videoAddTop button {
    font-size: 12px
  }

  body .layout-header {
    padding: 17px 0 !important
  }

  .ant-layout-sider-collapsed+.layout-right .leftSidebarPadding,
  body .leftSidebarPadding {
    padding-left: 30px !important
  }

  body .layout-header,
  body .layout-right .layout-right-padding {
    padding-right: 25px !important
  }

  body .layout-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%)
  }

  body .layout-left.ant-layout-sider-collapsed {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }

  .sqrbx {
    width: 35px;
    height: 35px;
    right: -17px
  }

  .sqrbx .anticon {
    font-size: 13px
  }

  html .shiftToTop {
    font-size: 22px;
    letter-spacing: 12px
  }

  body .layout-right .footer-main .footer-box.footer-logo {
    margin-bottom: 10px
  }

  html body .smart-link-right-mibile-top-img .mobile-device-image-square {
    width: 94%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px
  }

  .career-page-contant-section p {
    font-size: 34px
  }

  .how-it-works-contant-raw {
    margin-bottom: 60px
  }

  .how-it-works-right h5 {
    font-size: 30px
  }

  .video-step-main.smart-link-fix-scroll-img {
    display: inline-block
  }

  .smart-link-left {
    width: 100%;
    float: left
  }

  .smart-link-right {
    margin-top: 40px;
    width: 100%
  }

  .button-spotify-row,
  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
    height: auto
  }

  .button-spotify-box {
    padding: 7px 20px
  }

  .privacy-policy-mobile {
    bottom: -3px
  }

  .leptop-device-preview-man-row {
    max-width: 510px;
    overflow-x: auto;
    height: 299px
  }

  .leptop-device-preview-top-img img {
    width: 192px !important
  }

  .leptop-device-preview-top-img {
    height: 363.3px
  }

  .desktop-middle-img img {
    width: 61%;
    max-height: 442px
  }

  .desktop-middle-img,
  .desktop-middle-img.black-bg {
    top: 27px
  }

  .leptop-device-preview-man-row .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box a,
  .leptop-device-preview-row-desktop.viewpageurl .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row a {
    font-size: 17px
  }

  .leptop-device-preview-man-row .smart-link-mibile-bottom.secend-section-mibile {
    padding: 27px 12px 60px 15px
  }

  .leptop-device-preview-right-section .social-smart-link-tab img {
    height: 26px;
    width: 100%
  }

  .leptop-device-preview-right-section .smart-link-mibile-bottom {
    padding-top: 28px
  }

  .smart-link-mibile-bottom {
    height: 376px
  }

  .post-box.smart-listing-page {
    width: 100% !important;
    margin-right: 0 !important
  }

  .jvAuOB .post-box.smart-listing-page:nth-child(10),
  .jvAuOB .post-box.smart-listing-page:nth-child(4) {
    margin: 0 0 30px !important
  }

  .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-man-row {
    max-width: 690px;
    margin: 50px auto 0;
    overflow: inherit;
    height: auto
  }

  .leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab img {
    height: 28px;
    width: 28px
  }

  .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-top-img img {
    width: 310px !important;
    height: 100%
  }

  .leptop-device-preview-row-desktop.viewpageurl .logo-man-preview {
    top: 60px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 761px
  }

  .leptop-device-preview-right-section .social-smart-link-tab {
    right: 42px
  }

  .leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab {
    padding: 17px 0
  }

  .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-right-section .smart-link-mibile-bottom {
    height: 627px
  }
}

.depecheMode button,
.upgrade-with-us,
.videoAddTop,
.videoAddTop button {
  letter-spacing: 2px;
  text-transform: uppercase
}

@media only screen and (max-width:975px) {
  .desktop-middle-img img {
    max-height: 434px
  }
}

@media only screen and (max-width:960px) {
  .desktop-middle-img img {
    max-height: 426px
  }
}

@media only screen and (max-width:943px) {
  .desktop-middle-img img {
    max-height: 417px
  }
}

@media only screen and (max-width:927px) {
  .desktop-middle-img img {
    max-height: 408px
  }
}

@media only screen and (max-width:910px) {
  .desktop-middle-img img {
    max-height: 402px
  }
}

@media only screen and (max-width:885px) {
  .desktop-middle-img {
    top: 23px
  }

  .leptop-device-preview-man-row {
    margin: 33px auto 0;
    height: 249px
  }

  .desktop-middle-img.black-bg {
    top: 25px
  }
}

@media only screen and (max-width:875px) {
  .desktop-middle-img img {
    max-height: 387px
  }
}

@media only screen and (max-width:862px) {
  .desktop-middle-img img {
    max-height: 380px
  }
}

@media only screen and (max-width:848px) {
  .desktop-middle-img img {
    max-height: 373px
  }
}

@media only screen and (max-width:834px) {
  .desktop-middle-img img {
    max-height: 365px
  }
}

@media only screen and (max-width:825px) {
  .desktop-middle-img.black-bg {
    top: 23px
  }

  .desktop-middle-img img {
    max-height: 363px
  }

  .leptop-device-preview-top-img img {
    max-height: 100%;
    height: 100%
  }
}

@media only screen and (max-width:816px) {
  .desktop-middle-img img {
    max-height: 357px
  }

  .leptop-device-preview-top-img {
    height: 364.3px !important
  }

  .leptop-device-preview-top-img img {
    max-height: 100%
  }
}

@media only screen and (max-width:803px) {
  .desktop-middle-img img {
    max-height: 349px
  }

  .leptop-device-preview-top-img img {
    max-height: 100% !important
  }
}

@media only screen and (max-width:788px) {
  .desktop-middle-img img {
    max-height: 342px
  }
}

@media only screen and (max-width:774px) {
  .desktop-middle-img img {
    max-height: 335px
  }
}

@media only screen and (max-width:767px) {
  .headerCta.NewheaderCta {
    top: 15px;
  }

  html .leftShifted {
    margin-right: 110px;
  }

  .videoAdd button.new,
  .videoCheckboxClass {
    margin-right: 9px !important;
  }

  .stepsNav {
    padding: 0 10px !important
  }

  html .cartBoxHeight {
    height: 220px
  }

  html .menuIcon {
    font-size: 17px
  }

  .how-it-works-contant-raw,
  .how-it-works-contant>.how-it-works-contant-raw:nth-child(odd),
  .videoAddTop {
    flex-direction: column
  }

  .videoAddTop .left button {
    padding-left: 0
  }

  .resultTabMain {
    flex-wrap: wrap
  }

  .resultTabMain button {
    width: 49%;
    margin: 5px 0
  }

  .resultShow .chart-box>div {
    padding: 0;
    position: relative;
    left: -10px
  }

  .dashBoardTop .dashBoardright,
  .resultShow .right-dashboard-action {
    min-height: initial
  }

  .resultShow .chart-box>div:first-child {
    margin-top: 0
  }

  .dashBoardTop .dashBoardleft,
  .dashBoardTop .dashBoardright,
  .subscription-buuton-right {
    width: 100%
  }

  html .dashBoardSocialTab button {
    min-width: 120px
  }

  .dashBoardSocialTab {
    flex-wrap: wrap;
    justify-content: flex-start
  }

  .shiftToTop {
    font-size: 20px
  }

  .headerCta a {
    padding: 4px 16px 7px
  }

  .insight-listing-content>div.insight-email>div {
    padding-left: 15px
  }

  body .layout-header,
  body .layout-right .layout-right-padding {
    padding-right: 30px !important
  }

  .sqrbx .anticon {
    left: 6px;
    bottom: 6px;
    font-size: 12px
  }

  .career-page-contant-section p {
    font-size: 26px;
    margin-bottom: 30px
  }

  .sqrbx {
    top: 13px
  }

  .ant-layout-sider-collapsed+.layout-right .leftSidebarPadding {
    padding-left: 30px !important;
    padding-right: 30px !important
  }

  body .layout-right .footer-main .footer-box {
    padding-top: 10px;
    width: 100% !important
  }

  .how-it-works-right span {
    padding: 15px 20px
  }

  body .leftSidebarPadding.withSidebar {
    padding-left: 0 !important
  }

  .how-it-works-contant .how-it-works-left,
  .how-it-works-contant .how-it-works-right {
    max-width: 100%;
    float: left;
    width: 100%
  }

  .how-it-works-contant-raw {
    margin-bottom: 30px
  }

  .full-width.how-it-works-left {
    margin: 0 0 30px;
    text-align: center
  }

  .how-it-works-contant-raw:first-child .how-it-works-left,
  .how-it-works-contant>.how-it-works-contant-raw:nth-child(2n) .how-it-works-right,
  .how-it-works-contant>.how-it-works-contant-raw:nth-child(odd) .how-it-works-left img {
    margin-left: 0
  }

  .how-it-works-contant-raw:first-child .how-it-works-right {
    width: 100%;
    margin-left: 0;
    max-width: 100%
  }

  .how-it-works-contant-raw:last-child {
    margin-bottom: 40px
  }

  .how-it-works-right h5 {
    font-size: 24px
  }

  .how-it-works-right p {
    font-size: 18px
  }

  .video-steps-tab-title.smart-link-page {
    margin: 60px 0 0
  }

  .preview-smart-ling,
  .video-steps-tab-title.smart-link-page button {
    top: -80px
  }

  .privacy-policy-mobile {
    bottom: 0
  }

  .select-smart-link {
    width: 100%;
    max-width: 100%
  }

  .smart-link-left h3 {
    font-size: 26px;
    line-height: 1.2
  }

  .social-icon-row img {
    margin-right: 15px;
    max-width: 56px;
    max-height: 56px
  }

  .social-iocn-top-row {
    display: inline-block;
    width: 100%
  }

  .smart-link-left .social-iocn-top-row .toggle {
    padding: 40px 0 0;
    float: right;
    margin-top: 0
  }

  .leptop-device-preview-row-desktop img,
  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-row-mibile img {
    visibility: hidden
  }

  .desktop-middle-img img {
    visibility: visible !important;
    width: 100%
  }

  .leptop-device-preview-row {
    overflow: inherit;
    display: none
  }

  .ant-modal.single-post-modal.how-it-work-light-box.smartlink-preview .ant-modal-body {
    padding-bottom: 120px
  }

  .desktop-middle-img.black-bg img {
    visibility: hidden !important
  }

  .desktop-middle-img.black-bg .leptop-device-preview-top-img img,
  .desktop-middle-img.black-bg .logo-man-preview img,
  .desktop-middle-img.black-bg .social-smart-link-tab img,
  .leptop-device-preview-right-section .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box img {
    visibility: visible !important
  }

  .leptop-device-preview-right-section .social-smart-link-tab img {
    visibility: visible !important;
    width: 70%;
    height: 36px
  }

  .desktop-middle-img.black-bg {
    background: #000;
    width: 100%;
    height: 100%
  }

  .logo-man-preview img {
    max-width: 120px
  }

  .about-new-page,
  .button-spotify-row {
    height: auto
  }

  .smart-link-right.mobile-view-show-preview {
    display: block
  }

  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img img {
    visibility: visible;
    border-radius: 20px 20px 0 0;
    width: 92.6%
  }

  .mobile-view-show-preview.viewpagelink-mobile-view .social-smart-link-tab img {
    width: 30px;
    height: 41px
  }

  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row img {
    width: 38px;
    height: 38px
  }

  .smart-link-right.mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-row {
    max-width: 100%
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 764px;
    border-radius: 30px 30px 25px 25px
  }

  .smart-link-right.mobile-view-show-preview.viewpagelink-mobile-view .smart-link-mibile-bottom {
    height: auto !important;
    margin-bottom: 40px
  }

  .free-trial-mobile-device-show {
    display: inline-block
  }

  .ant-drawer-body ul li:first-child {
    display: none
  }

  .right-section-dashboard {
    margin: 40px 0 0;
    width: 100%
  }
}

.singleLine {
  display: flex;
}

@media only screen and (max-width:600px) {
  .singleLine {
    flex-direction: column;
  }

  .detailsVideoText .big {
    min-width: 100%;
    width: 100%;
  }

  .separatorMain {
    flex-direction: column;
  }

  .detailsVideoText .mt-10 {
    margin-top: 10px;
  }

  .mobileTopSpace {
    margin-top: 12px
  }

  html .addVideoPopup .video-steps-tab-title button {
    width: 30%
  }

  html .addVideoPopup .video-steps-tab-title button:nth-child(4n):after {
    opacity: 1
  }

  html .addVideoPopup .video-steps-tab-title button:nth-child(3n):after {
    opacity: 0
  }

  .desktop-middle-img img {
    visibility: hidden !important
  }

  .leptop-device-preview-right-section .button-spotify-box img,
  .logo-man-preview img {
    visibility: visible !important
  }

  .leptop-device-preview-row-desktop {
    float: left;
    background: #000
  }

  .desktop-middle-img {
    position: static
  }

  .leptop-device-preview-row-desktop img {
    height: 0
  }

  .leptop-device-preview-top-img img {
    width: 100% !important;
    visibility: visible !important;
    height: auto
  }

  .leptop-device-preview-top-img {
    height: auto;
    width: 100%
  }

  .leptop-device-preview-man-row {
    position: static;
    display: block;
    height: auto
  }

  .leptop-device-preview-right-section .privacy-policy-mobile,
  .leptop-device-preview-right-section .smart-link-mibile-bottom {
    width: 100%
  }

  .ant-modal.single-post-modal.how-it-work-light-box.smartlink-preview .ant-modal-body {
    padding-left: 20px;
    padding-bottom: 50px;
    padding-right: 20px
  }

  .logo-man-preview img {
    height: 38px !important
  }

  .leptop-device-preview-man-row .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box a {
    font-size: 18px
  }
}

@media only screen and (max-width:575px) {
  html .pickerDivRight {
    margin: 10px 0 0 !important;
    align-items: flex-start !important
  }

  html .pickerDivLeft {
    margin: 0 0 15px !important
  }

  html body .action-dd-raw {
    margin-bottom: 0 !important
  }

  .form-field.action-dd-raw:first-child,
  html body .action-dd-raw.extra-added-field {
    margin-bottom: 5px !important
  }

  .action-dd-raw,
  html body .itune-change-div {
    flex-direction: column
  }

  html body .itune-change-left {
    width: 100%;
    margin: 0 0 10px
  }

  html body .action-dd-raw .biolink-delete .ant-btn {
    border-radius: 10px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }

  .action-dd-raw .dd1,
  .action-dd-raw .dd2,
  .action-dd-raw .dd3 {
    width: 100% !important;
    margin: 5px 0 !important
  }

  .action-dd-raw .dd1 {
    order: 2
  }

  .action-dd-raw .dd2 {
    order: 3
  }

  .action-dd-raw .dd3 {
    order: 4
  }

  html body .action-dd-raw .biolink-delete {
    margin: 0 0 10px !important;
    order: 1;
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important
  }

  html body .ColorPickerDiv .ColorPickerInner {
    top: 44px
  }

  html body .biolink-step-content-2 .ColorPickerDiv .ColorPickerInner {
    top: 9px
  }

  html body .large.ColorPickerDiv .ColorPickerInner {
    margin: 10px 0 0
  }

  .career-page-contant-section p {
    font-size: 24px;
    margin-bottom: 30px
  }

  .career-page-full-man {
    padding: 49px 0 30px;
    margin-top: 0
  }

  .how-it-works-contant .how-it-works-left,
  .how-it-works-contant .how-it-works-right {
    text-align: center
  }

  .how-it-works-page-button {
    margin: 0 auto
  }

  .amsrt-link-logo-row,
  .smart-link-form-box-link-setting textarea,
  .video-steps-tab-title.smart-link-page a,
  .video-steps-tab-title.smart-link-page button {
    width: 100%
  }

  .smart-link-form-box-link-setting .full-width.form-field {
    display: inline-block
  }

  .smart-link-form-box-link-setting .label {
    margin: 0 20px 18px 0 !important
  }

  .smart-link-form-box-link-setting input {
    float: left;
    width: 100%
  }

  .select-smart-link:after {
    top: -2px
  }

  .smart-link-new-listing-page-box {
    display: block
  }

  .smart-link-new-listing-page-box-left-img {
    height: auto;
    width: 100%;
    float: left;
    display: block;
    align-items: center;
    padding-right: 0
  }

  .smart-link-new-listing-page-box-right-contant {
    width: 100%;
    margin: 20px 0 10px
  }

  .smart-link-new-listing-page-box-right-contant a {
    font-size: 16px
  }

  .url-copy-row img {
    padding: 0 0 0 19px
  }

  .smart-link-new-listing-page-box-left-img img {
    height: 100%;
    max-height: 300px
  }
}

@media only screen and (max-width:440px) {
  .mobile-image-none .smart-link-mibile-bottom {
    height: 745px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 764px
  }

  .url-copy-row {
    display: block
  }

  .smart-link-new-listing-page-box-right-contant a {
    width: 100%
  }

  .url-copy-row img {
    padding: 10px 0 0
  }
}

@media only screen and (max-width:435px) {
  .button-spotify-box a {
    font-size: 17px
  }

  .button-spotify-box button {
    font-size: 16px;
    width: 100px
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
    height: auto
  }

  .smart-link-mibile-bottom {
    height: 346px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 365px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 735px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 754px !important
  }
}

@media only screen and (max-width:426px) {
  .mobile-image-none .smart-link-mibile-bottom {
    height: 718px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 737px !important
  }
}

@media only screen and (max-width:420px) {
  .button-spotify-box {
    padding: 5px 20px
  }

  .button-spotify-row {
    height: auto
  }

  .smart-link-mibile-bottom {
    height: 317px
  }

  .button-baselayout-box-amazonMusic a,
  .button-baselayout-box-appleMusic a,
  .button-baselayout-box-deezer a,
  .button-baselayout-box-itunes a,
  .button-baselayout-box-pandora a,
  .button-baselayout-box-spotify a,
  .button-baselayout-box-tidal a,
  .button-baselayout-box-youtubeMusic a {
    font-size: 16px
  }

  .button-baselayout-box-amazonMusic button,
  .button-baselayout-box-appleMusic button,
  .button-baselayout-box-deezer button,
  .button-baselayout-box-itunes button,
  .button-baselayout-box-pandora button,
  .button-baselayout-box-spotify button,
  .button-baselayout-box-tidal button,
  .button-baselayout-box-youtubeMusic button {
    padding: 11px 22px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 341px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 707px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 725px !important
  }
}

@media only screen and (max-width:410px) {
  .smart-link-mibile-bottom.secend-section-mibile {
    padding: 14px 25px 88px
  }

  .smart-link-mibile-bottom {
    height: 297px;
    padding: 24px 15px 80px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 321px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 686px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 706px !important
  }
}

@media only screen and (max-width:400px) {
  html .addVideoPopup .ant-modal-body {
    padding: 50px 20px 40px
  }

  html .addVideoPopup .video-steps-tab-title button {
    width: 46%
  }

  html .addVideoPopup .video-steps-tab-title button:nth-child(3n):after,
  html .addVideoPopup .video-steps-tab-title button:nth-child(4n):after {
    opacity: 1
  }

  html .addVideoPopup .video-steps-tab-title button:nth-child(2n):after {
    opacity: 0
  }

  html body .videoCheckboxClass {
    width: 100%;
    margin: 10px 0 !important;
    height: auto !important
  }

  .smart-link-right-mibile-top-img {
    top: 16px;
    left: 20px
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-box:nth-child(4) img {
    width: 62px;
    object-fit: contain
  }

  .smart-link-right-mibile-top-img img {
    max-height: 322px;
    object-fit: cover
  }

  .social-smart-link-tab img {
    width: 27px;
    height: 27px;
    margin: 16px 3px 0
  }

  .smart-link-mibile-bottom {
    height: 347px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 366px
  }

  .url-link-row a {
    font-size: 13px !important
  }

  .view-edit-delete-row img {
    width: 24px
  }

  .mobile-view-show-preview.viewpagelink-mobile-view .social-smart-link-tab img {
    width: 27px;
    height: 27px;
    margin: 16px 3px 11px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 668px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 688px !important
  }
}

@media only screen and (max-width:390px) {
  .smart-link-right-mibile-top-img img {
    max-height: 280px;
    object-fit: cover
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
    height: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    padding: 14px 10px 80px
  }

  .smart-link-mibile-bottom {
    height: 375px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 389px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 649px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 668px !important
  }

  .smart-link-new-listing-page-box-right-contant a {
    font-size: 14px
  }
}

@media only screen and (max-width:384px) {
  .button-spotify-row {
    height: auto
  }

  .smart-link-mibile-bottom {
    height: 363px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 376px
  }

  .mobile-image-none .smart-link-mibile-bottom {
    height: 637px;
    border-radius: 20px 20px 25px 25px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 657px !important
  }
}

@media only screen and (max-width:375px) {
  .smart-link-mibile-bottom {
    height: 347px;
    overflow-y: auto
  }

  .button-spotify-row {
    overflow: inherit;
    height: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 340px
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row {
    height: auto
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 360px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 620px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 639px !important
  }
}

.after-scroll-add,
.leptop-device-preview-right-section {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none
}

@media only screen and (max-width:368px) {
  .smart-link-mibile-bottom {
    height: 334px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 326px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 347px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 607px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 626px !important
  }
}

@media only screen and (max-width:362px) {
  .smart-link-mibile-bottom {
    height: 323px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 315px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 337px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 595px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 614px !important
  }
}

@media only screen and (max-width:355px) {
  .smart-link-mibile-bottom {
    height: 307px;
    overflow-y: auto
  }

  .smart-link-right-mibile-top-img {
    top: 16px;
    left: 17px
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 305px
  }

  .social-smart-link-tab img {
    width: 28px;
    height: 28px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 323px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 581px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 600px !important
  }
}

@media only screen and (max-width:350px) {
  .smart-link-mibile-bottom {
    height: 300px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 292px
  }

  .leptop-device-preview-right-section .social-smart-link-tab img {
    width: 64%;
    margin: 16px 1px 0;
    height: 34px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 311px
  }

  .url-link-row a {
    font-size: 12px !important
  }

  .url-edit-row {
    padding: 14px 8px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 571px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 591px !important
  }
}

@media only screen and (max-width:342px) {
  .smart-link-mibile-bottom {
    height: 283px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 276px
  }

  .button-spotify-box a {
    font-size: 15px
  }

  .button-spotify-box button {
    font-size: 14px;
    width: 84px
  }

  .social-smart-link-tab img {
    width: 25px;
    height: 25px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 295px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 555px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 574px !important
  }
}

@media only screen and (max-width:334px) {
  .smart-link-mibile-bottom {
    height: 261px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 263px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 280px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 539px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 559px !important
  }
}

@media only screen and (max-width:326px) {
  .smart-link-mibile-bottom {
    height: 244px;
    overflow-y: auto
  }

  .smart-link-mibile-bottom.secend-section-mibile {
    height: 254px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    height: 264px
  }

  .mobile-image-none .smart-link-mibile-bottom,
  .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: 524px
  }

  .mobile-view-show-preview .mobile-image-none .smart-link-mibile-bottom {
    height: 544px !important
  }
}

.select-smart-link select {
  background: url(https://drgourmet-web.api.openxcell.dev/select-down-arrow.7cf6a1f6aa4a4d170323.png) 100%/15px 15px no-repeat;
  -webkit-appearance: none;
  background-position: calc(100% - 15px)
}

.desktopBgPreviewBtn {
  height: 48px;
  border-radius: 10px;
  border: none !important;
  background: #2ea5c2;
  margin: 0 0 0 15px !important;
  padding: 0 36px;
  box-shadow: 0 6px 35px 0 rgb(0 0 0 / 15%)
}

.background-image-main.parallax-pb-40,
html body .pb-zero {
  padding-bottom: 0 !important
}

.parallax-pb-40-div {
  padding-bottom: 50px !important
}

.video-step-right.updated-full-width {
  margin-top: 10px !important;
  width: 100% !important;
  padding: 30px 20px 0 150px !important;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgb(201 201 201 / 69%)
}

.video-step-right.updated-full-width .ant-checkbox-group .ant-checkbox-wrapper {
  float: none;
  vertical-align: top;
  width: 33.3%;
  padding-right: 10px
}

.social-smart-link-tab.social-round-tab a {
  margin: 4px 5px 5px
}

.social-smart-link-tab.social-round-tab a img {
  border-radius: 100% !important;
  width: 35px;
  height: 35px;
  box-shadow: rgba(0, 0, 0, .35) 0 5px 15px;
  object-fit: cover
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-row>div {
  border-bottom: 1px solid #ccc;
  padding: 10px 0 20px
}

.smart-link-mibile-bottom.secend-section-mibile .button-spotify-row>div button {
  padding: 5px 19px;
  font-size: 16px;
  min-width: 100px
}

.preview-left,
.preview-right {
  width: 310px !important
}

.preview-left .bgimg-main {
  height: 100% !important;
  width: 100%
}

html .show-preview {
  height: 309px !important
}

@media (min-width:1300px) {
  .smart-link-right-row-mibile.mobile-device-scale-down {
    -webkit-transform: scale(.7);
            transform: scale(.7)
  }

  .smart-link-right-row {
    top: 0
  }
}

@media (max-width:1300px) and (min-width:992px) {
  .smart-link-right-row-mibile {
    -webkit-transform: scale(.65);
            transform: scale(.65)
  }

  .smart-link-right-row {
    top: -50px
  }
}

.pickerDivLeft {
  display: flex;
  align-items: center;
  position: relative
}

.smart-link-mibile-bottom.secend-section-mibile .smart-title-section {
  padding: 10px;
  float: left;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  background: #141414 !important;
  margin-bottom: 10px
}

.after-scroll-add {
  height: 758px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 60px
}

.after-scroll-add::-webkit-scrollbar {
  display: none
}

.after-scroll-add-bottom {
  background: #241e24;
  position: relative;
  width: 95% !important;
  min-height: 390px
}

.find-more-btn,
.toolInfo .toolText {
  background: #2ea5c2;
  text-align: center
}

.after-scroll-add-bottom .smart-link-mibile-bottom {
  height: auto;
  overflow: initial;
  padding-bottom: 150px
}

.new-listing-page.threeButton {
  display: flex;
  justify-content: center;
  align-items: center
}

.toolInfo {
  position: relative;
  margin: 0 5px;
  display: flex
}

.toolInfo .toolText {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  font-size: 12px;
  top: -42px;
  padding: 1px 10px;
  color: #fff;
  border-radius: 5px
}

.depecheMode button,
.videoAddTop button {
  background: 0 0 !important;
  border: 0;
  padding: 0
}

.toolInfo .toolText::after {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  width: 0;
  height: 0;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #027fc5
}

.leftSidebarMenu ul.ant-menu>li a {
  opacity: 0;
  -webkit-transform: translateX(-90px) !important;
          transform: translateX(-90px) !important
}

html body.animatedMenu.shortTransition .leftSidebarMenu ul.ant-menu>li a {
  transition: .4s !important
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li a {
  transition: .5s cubic-bezier(.03, .46, .31, .97) !important;
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:first-child a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:first-child a {
  transition-delay: 0.24s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(2) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(2) a {
  transition-delay: 0.44s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(3) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(3) a {
  transition-delay: 0.64s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(4) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(4) a {
  transition-delay: 0.84s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(5) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(5) a {
  transition-delay: 1.04s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(6) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(6) a {
  transition-delay: 1.2s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(7) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(7) a {
  transition-delay: 1.4s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(8) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(8) a {
  transition-delay: 1.6s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(9) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(9) a {
  transition-delay: 1.8s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(10) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(10) a {
  transition-delay: 2s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(11) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(11) a {
  transition-delay: 2.2s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(12) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(12) a {
  transition-delay: 2.4s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(13) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(13) a {
  transition-delay: 2.6s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(14) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(14) a {
  transition-delay: 2.8s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(15) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(15) a {
  transition-delay: 3s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(16) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(16) a {
  transition-delay: 3.2s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(17) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(17) a {
  transition-delay: 3.4s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(18) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(18) a {
  transition-delay: 3.6s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(19) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(19) a {
  transition-delay: 3.8s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(20) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(20) a {
  transition-delay: 4s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(21) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(21) a {
  transition-delay: 4.2s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(22) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(22) a {
  transition-delay: 4.4s !important;
  opacity: 1
}

.animatedMenu .leftSidebarMenu ul.ant-menu>li.menu-item-has-children a.active+ul li:nth-child(23) a,
.animatedMenu .leftSidebarMenu ul.ant-menu>li:nth-child(23) a {
  transition-delay: 4.6s !important;
  opacity: 1
}

.upgrade-with-us {
  display: flex;
  justify-content: center;
  padding: 0 0 25px
}

html body .upgrade-with-us a {
  font-size: 20px !important
}

.upgrade-with-us .fa {
  margin-right: 10px;
  font-size: 25px;
  color: #33ffff;
  max-width: 20px;
  position: relative;
  top: -2px
}

.videoAddTop {
  display: flex;
  padding: 10px 20px 3px;
  background: #141414;
  z-index: 1;
  margin-top: 5px;
  justify-content: space-between
}

.videoAddTop button {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 300;
}

.whiteInColor img,
html body .resultShow .ant-picker-active-bar {
  display: none
}

.videoAddTop button .fa {
  margin-right: 7px;
  font-size: 20px
}

.depecheMode button.ant-dropdown-open {
  border-color: #33ffff !important
}

.commonDD ul li a:hover,
html body .default-dropdown.commonDD ul li a:hover {
  color: #33ffff !important;
  background: #141414
}

.depecheMode button i,
.top-profile-layout i,
.videoAddTop button i {
  transition: .4s
}

.depecheMode button.ant-dropdown-open .fa-caret-down,
.top-profile-layout:hover i,
.videoAddTop button.ant-dropdown-open .fa-caret-down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.videoAddTop .right span {
  font-weight: 700;
}

.videoCheckboxClass {
  border: 2px solid #FF0000;
  background: #141414;
  height: 265px
}

.home-page .topHeaderClass+div {
  padding: 0 !important
}

.home-page .footer-main {
  display: none !important;
}

.videoCheckboxClass.checked {
  border-color: #33ffff !important;
}

.videoCheckboxClass.checked {
  border-color: red
}

.resultShow .chart-box {
  margin-top: 0;
  margin-bottom: 0
}

.resultShow .right-section-dashboard .label,
html body .resultShow .left-section-dashboard .label {
  letter-spacing: 7px;
  color: #fff !important;
  text-transform: uppercase;
  margin-bottom: 10px !important
}

.global-form.resultShow .single-line-field .ant-select-selection-item,
html body .resultShow .left-section-dashboard .ant-picker .ant-picker-input input {
  color: #fff !important;
  font-weight: 300
}

html body .resultShow .left-section-dashboard .ant-picker .ant-picker-input input::-webkit-input-placeholder {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 12px !important
}

html body .resultShow .ant-picker:hover {
  border-color: #2ea5c2;
  box-shadow: none
}

html body .resultShow .left-section-dashboard .ant-picker .ant-picker-input input::-moz-placeholder {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 12px !important
}

html body .resultShow .left-section-dashboard .ant-picker .ant-picker-input input:-ms-input-placeholder {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 12px !important
}

html body .resultShow .left-section-dashboard .ant-picker .ant-picker-input input:-moz-placeholder {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 12px !important
}

.resultShow .chart-box>div {
  width: 49%
}

.depecheMode {
  display: flex;
  margin: 0 0 30px
}

.depecheLeft,
.discussion-box.questions .box-content .question-comment>div {
  margin-right: 20px
}

.depecheMode button {
  min-width: 200px;
  font-size: 16px;
  text-align: right;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0;
  font-weight: 100;
}

.depecheMode button.depecheLeft {
  color: #33ffff !important;
  border-color: #33ffff !important
}

.depecheMode button .fa {
  font-size: 20px;
  margin-left: 10px
}

.fa-folder-magnifying-glass:before {
  content: "\e18b"
}

.addVideoPopup .ant-modal-close {
  top: 15px;
  right: 15px
}

html body .left-section-dashboard .exportData {
  position: relative;
  left: 20px
}

.addVideoPopup .ant-modal-close-x {
  font-size: 13px;
  background: #141414;
  color: #33ffff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 26px
}

.addVideoPopup .ant-modal-content {
  background: rgba(255, 255, 255, .95)
}

.addVideoPopup .video-steps-tab-title {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  position: relative
}

.video-step-content-inner {
  min-height: 300px
}

.addVideoPopup {
  width: 950px !important;
  max-width: 95% !important;
  font-family: 'Heebo', sans-serif !important;
  font-weight: 300 !important;
}

.addVideoPopup .video-steps-tab-title button.active {
  font-weight: 700;
}

.addVideoPopup .video-steps-tab-title button {
  display: flex;
  letter-spacing: 1px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  justify-content: center;
  background: 0 0;
  color: #141414;
  margin: 5px 0;
  align-items: center;
  width: 16.6%;
  cursor: unset;
  pointer-events: none;
}

.addVideoPopup .ant-modal-body {
  padding: 40px;
  float: left;
  width: 100%
}

.videoStepsContent {
  padding: 10px 20px
}

.stepsNav button {
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 4px 5px;
  background: 0 0;
  color: #141414;
  border: 1px solid #141414;
  min-width: 100px;
  font-weight: 700
}

.stepsNav {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 5px;
  padding: 0 88px
}

.cartBox {
  padding-bottom: 138px;
}

.cartBox .videoCheckboxClass {
  padding-top: 0px !important;
  border-color: #33ffff !important;
  position: relative;
  left: 0px !important;
}

.cartBox h5 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: -5px 0 10px !important
}

.full-width.mt-20 {
  margin-top: 30px !important
}

.cartBoxHeight {
  position: relative;
  height: 420px !important;
  max-height: none !important;
  overflow: auto;
  left: 0px !important;
}

.cartBox button.remove-video {
  position: absolute;
  right: 7px;
  top: 21px;
  background: 0 0;
  z-index: 1;
  color: #33ffff;
  font-size: 17px;
}

html .cartBoxHeight .scrollbarsDiv>div:last-child {
  right: 6px !important;
  width: 3px !important
}

.cartBox .videoCheckboxClass {
  width: 95%;
  border-left: 0;
  position: relative;
  border-bottom: 0;
  border-right: 0;
  text-align: center;
  margin: 0
}

.cartBox .videoCheckboxClass .budgetForm {
  padding-bottom: 5px;
}

.cartBox .videoCheckboxClass .budgetForm>div:first-child {
  border: 0;
  padding: 12px 0 0 !important
}

.cartBox .platform+div span {
  color: #fff !important;
}

.cartBox .platform {
  margin: 3px 0 3px !important
}

.cartBox .budgetFormTop {
  opacity: .8
}

.subTotalTitle {
  font-size: 17px;
  letter-spacing: 2px;
}

.cartBox .cartBoxBottom {
  padding: 9px 0 1px;
  text-align: center;
  background: #000723;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top: 1px solid #29faff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%
}

.cartBox .cartBoxBottom button {
  text-transform: uppercase;
  min-width: 175px;
  background: #33ffff;
  margin: 5px 0 0;
  color: #141414;
  font-size: 17px;
  letter-spacing: 2px;
  padding: 3px;
  font-weight: 900 !important;
}

.cartBox .cartBoxBottom .orText {
  color: #fff;
  font-weight: 300;
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px
}

.addVideoPopup .video-step-content {
  padding: 0 80px
}

.normalFont {
  font-weight: 400;
}

.addVideoPopup .video-step-content .ant-checkbox-wrapper {
  line-height: normal;
  color: #141414 !important;
  margin: 0 5px 5px 0;
  font-size: 13px;
  font-weight: 400;
}

.global-form .video-step-content-inner .ant-select-selection-overflow-item {
  margin: 0 5px 0 0 !important
}

.global-form .video-step-content-inner .ant-select-selection-item {
  line-height: 23px !important;
  background: #33ffff !important;
  color: #141414 !important;
  letter-spacing: 1px;
  font-weight: 400;
}

.addVideoPopup .ant-input-affix-wrapper,
.addVideoPopup .video-step-content .ant-input,
.video-step-content .ant-select-selector {
  text-align: center;
  background: #fff !important;
  padding: 8px 20px !important;
  box-shadow: none !important;
  border: 1px solid #141414 !important;
  color: #141414;
  border-radius: 0 !important;
  outline: 0 !important
}

.addVideoPopup .ant-input-affix-wrapper {
  display: flex;
  justify-content: center
}

.addVideoPopup .ant-input-affix-wrapper .ant-input {
  border: 0 !important;
  padding: 0 !important;
  height: auto !important;
  width: 50px;
  text-align: left
}

.addVideoPopup .video-step-content .ant-input::-webkit-input-placeholder {
  color: #141414;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300
}

.addVideoPopup.payment h5,
.video-step-content-inner .videoStepsContent h5 {
  letter-spacing: 1px;
  font-size: 16px;
  color: #141414;
  text-transform: uppercase;
  font-weight: 900 !important;
}

.video-step-content .ant-input::-moz-placeholder {
  color: #141414;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300
}

.paymentCta {
  margin-top: 25px !important;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center
}

.paymentCta .submit-btn {
  background: #141414 !important;
  color: #33ffff !important;
  letter-spacing: 1px
}

.video-step-content .ant-input:-ms-input-placeholder {
  color: #141414;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300
}

.video-step-content .ant-input:-moz-placeholder {
  color: #141414;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300
}

.addVideoPopup .global-form .ant-checkbox-checked .ant-checkbox-inner {
  background: #33ffff;
  border-color: #33ffff
}

.addVideoPopup .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #141414
}

.taglineStep .full-width.form-field.form-checkbox.checkbox-with-scroll.form-square-checkbox.top-margin {
  margin: 0;
  padding: 0;
  box-shadow: none
}

.taglineStep .full-width.form-field.form-checkbox.checkbox-with-scroll.form-square-checkbox.top-margin .ant-checkbox-group {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden
}

.taglineStep .ant-checkbox-group .ant-checkbox-wrapper {
  float: left;
  line-height: normal;
  color: #141414 !important;
  margin: 0 5px 5px 0;
  font-size: 13px
}

.taglineStep .video-step-left h5,
.taglineStep .video-step-right h5 {
  margin: 0 0 10px !important
}

.taglineStep .threeColumn .ant-checkbox-group .ant-checkbox-wrapper {
  width: 30%;
  float: left
}

.taglineStep .video-step-left {
  float: left;
  width: 50%
}

.taglineStep .video-step-right {
  float: left;
  width: 50%;
  padding-left: 30px
}

.taglineStep .video-step-right .form-checkbox>div::-webkit-scrollbar {
  display: none
}

.dailyBudget h5 {
  margin-bottom: 7px !important
}

.global-form .dailyBudgetBottom .ant-radio-inner::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 0;
  background: #33ffff !important
}

.global-form .dailyBudgetBottom .ant-radio-checked .ant-radio-inner:before {
  top: 50%;
  left: 21.5%;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #141414;
  border-top: 0;
  border-left: 0;
  opacity: 0;
  transition: .2s cubic-bezier(.12, .4, .29, 1.46) .1s, _ .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  content: ' ';
  position: absolute;
  display: table;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  content: ' ';
  z-index: 1
}

.global-form .dailyBudgetBottom .ant-radio-checked::after,
.global-form .dailyBudgetBottom .ant-radio-input {
  border-radius: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important
}

.dailyBudget,
.global-form .dailyBudget .ant-radio-wrapper {
  color: #141414 !important
}

.global-form .dailyBudget .ant-radio-wrapper {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400
}

.summaryMain>div>div:first-child {
  font-weight: 900;
  color: #141414;
  float: left;
  width: 18%;
  text-transform: uppercase
}

.summaryMain>div>div:last-child {
  float: left;
  padding-left: 15px;
  width: 82%
}

.summaryMain>div {
  margin-bottom: 5px;
  float: left;
  width: 100%;
  color: #141414
}

.whiteInColor .fa {
  color: #fff;
  font-size: 30px;
  cursor: pointer
}

.layout-right .video-steps-tab-title.smart-link-page a span {
  overflow: hidden
}

.srv-validation-message {
  float: left;
  width: 100%;
  margin: 7px 0 0
}

.discussion-box {
  float: left;
  margin: 10px 0 15px;
  letter-spacing: 1px;
  font-size: 14px;
  padding-right: 15px
}

.discussion-box .questions-author {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  margin-right: 0;
  border-radius: 100%
}

.discussion-box .questions-author-text {
  width: calc(100% - 40px);
  padding-left: 14px;
  font-size: 13px
}

.discussion-box.main-details {
  line-height: 24px;
  padding-right: 0
}

.discussion-box.main-details h5 {
  font-size: 30px
}

.discussion-box.main-details .details-text {
  margin: 10px 0;
  font-family: CambriaItalic;
  color: #000
}

.discussion-box.questions .box-content .add-questn-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start
}

.discussion-box.questions .box-content h5 {
  margin: 0 0 5px !important;
  color: #fff;
  font-size: 16px;
  letter-spacing: .5px
}

.discussion-box.questions .box-content h5 small {
  background: #25a5bd;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px 2px;
  position: relative;
  top: -2.8px;
  margin-left: 5px
}

.discussion-box.questions .box-content h5 small.green {
  background: #8eb980
}

.discussion-box.questions .box-content .by-raw.author {
  color: #fff;
  line-height: 24px;
  margin: 3px 0 0;
  letter-spacing: 1px;
  line-height: normal
}

.discussion-box.questions .box-content .by-raw span {
  font-family: CambriaItalic;
  color: #262626
}

.discussion-box.questions .box-content .by-raw a {
  color: #33ffff;
  font-style: initial;
  cursor: pointer;
  margin: 0 0 0 5px
}

.discussion-box.questions .box-content .question-comment {
  display: flex;
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  color: #000;
  margin-top: 10px
}

.discussion-box.questions .box-content .question-comment.border-none {
  border: 0;
  padding: 0;
  margin: 2px 0 0
}

.discussion-box.questions .box-content .question-comment img {
  max-width: 17px;
  margin-right: 4px
}

.discussion-box .box-content .people-replies {
  margin: 24px 0 29px
}

.discussion-box .box-content .people-replies>div {
  line-height: normal;
  display: flex;
  justify-content: center
}

.discussion-box .box-content .people-replies>div span {
  margin-right: 5px;
  font-family: CambriaBold
}

.discussion-box .box-content .people-replies>div img {
  margin-right: 7px;
  max-width: 20px
}

.resultShow .chart-box .graph-content h4 {
  margin: 5px 0 0 !important
}

.ant-modal-root {
  position: relative;
  z-index: 9999;
}

.global-form .dailyBudgetBottom .red-active .ant-radio-inner::after {
  background: #FF0000 !important;
}

.global-form .dailyBudgetBottom .red-active .ant-radio-checked .ant-radio-inner:before {
  border-color: #fff !important;
}

.topHeaderClass+div.home-page {
  padding: 71px 0 0;
}

span.white {
  color: #fff !important;
}

span.red {
  text-decoration: line-through !important;
  color: #f00 !important;
}

.budgetFormBottomNew {
  margin-top: 10px;
  font-size: 14px;
}

.deleteVdieoModal {
  text-align: center;
}

.addVideoPopup .submit-btn {
  border-radius: 0 !important;
}

.left-section-dashboard .apexcharts-menu-item {
  text-align: center;
  padding: 0;
  font-weight: 700;
}

.left-section-dashboard .apexcharts-menu {
  color: #141414;
  border-radius: 0 !important;
  background: #33ffff;
  border: 0;
  text-transform: uppercase;
  padding: 0 !important;
}

.left-section-dashboard .apexcharts-menu-item:hover {
  color: #33ffff !important;
  background: #141414 !important;
}

.cartBox.updated {
  padding-left: 0;
  padding-top: 11px;
  padding-right: 0;
}

.cartBox.updated h5 {
  margin-bottom: 5px !important;
  letter-spacing: 2px;
}

.cartBox.updated .videoCheckboxClass {
  border-width: 4px;
}

.remove-video img {
  width: 10px;
}

.cartBox.updated button.remove-video {
  top: 8px !important;
  right: 5px !important
}

.cartTitle {
  position: relative;
}

.cartTitle .close {
  position: absolute;
  cursor: pointer;
  left: 12px;
  top: -3px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-size: 9px;
}

.cartTitle .close:hover {
  color: #33ffff;
}

.summaryMain>div>div:first-child small {
  font-size: 100%;
  margin-left: 2px;
  position: relative;
  top: -1px;
}

@media screen and (min-width:768px) and (max-width:1000px) {
  .leptop-device-preview-man-row {
    top: 7%
  }
}

.mobile-social-icons {
  height: 45px;
  width: 150px
}

.mobile-social-icons img {
  object-fit: contain;
  margin: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important
}

.button-baselayout-box-appleMusic .mobile-social-icons img,
.button-spotify-box-appleMusic .mobile-social-icons img {
  max-width: 100px !important;
  max-height: 20px !important
}

.button-baselayout-box-spotify .mobile-social-icons img,
.button-spotify-box-spotify .mobile-social-icons img {
  max-width: 110px !important;
  max-height: 40px !important
}

.button-baselayout-box-deezer .mobile-social-icons img,
.button-baselayout-box-tidal .mobile-social-icons img,
.button-spotify-box-deezer .mobile-social-icons img,
.button-spotify-box-tidal .mobile-social-icons img {
  max-width: 120px !important;
  max-height: 40px !important
}

.button-baselayout-box-youtubeMusic .mobile-social-icons img,
.button-spotify-box-youtubeMusic .mobile-social-icons img {
  max-width: 110px !important;
  max-height: 35px !important
}

.mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.find-more-btn {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 15px !important;
  height: 42px !important;
  min-width: 170px;
  line-height: normal !important;
  transition: .8s;
  border-radius: 7px;
  border: 0 !important;
  position: relative
}

@media (min-width:992px) and (max-width:1100px) {
  .after-scroll-add {
    height: 757px
  }

  .after-scroll-add-bottom {
    min-height: 359px
  }

  .smart-link-right-row-mibile>img {
    height: 796px
  }
}

@media (min-width:992px) and (max-width:1020px) {
  .after-scroll-add {
    height: 687px
  }

  .smart-link-right-mibile-top-img {
    top: 50px
  }
}

@media (max-width:767px) {

  .videoAddTop .right,
  .videoAddTop button {
    letter-spacing: 1px;
  }

  html .cartBox button.remove-video {
    right: 5px;
    top: 5px;
  }

  .video-step-content-inner {
    min-height: 150px
  }

  html .layout-left .top-main-logo {
    margin-top: 0
  }

  .addVideoMain .scrollbarsDiv {
    height: 210px !important
  }

  .summaryMain>div>div:first-child {
    font-weight: 700;
    color: #141414;
    float: left;
    width: 29%;
    text-transform: uppercase
  }

  .summaryMain>div>div:last-child {
    float: left;
    padding-left: 15px;
    width: 71%
  }

  .taglineStep .threeColumn .ant-checkbox-group .ant-checkbox-wrapper {
    width: 48%
  }

  .eyePage .smart-link-right.mobile-view-show-preview.viewpagelink-mobile-view .smart-link-mibile-bottom,
  .stepsNav {
    margin-bottom: 0
  }

  .addVideoPopup .video-steps-tab-title button {
    width: 21%;
    padding: 7px 6px 4px;
    margin: 4px 5px;
    font-size: 12px
  }

  .addVideoPopup .video-steps-tab-title {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px
  }

  .addVideoPopup .ant-modal-body {
    padding: 40px 20px
  }

  .video-steps-tab-title button {
    color: #141414 !important;
    border: 1px solid #141414;
    background: #33ffff !important
  }

  .video-steps-tab-title button.active~button {
    color: #141414;
    background: 0 0 !important
  }

  .addVideoPopup .video-steps-tab-title button:after {
    position: absolute;
    top: 50%;
    z-index: 11;
    right: -16px;
    background: #141414;
    border-radius: 100%;
    width: 10px;
    height: 2px;
    content: "";
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%)
  }

  .addVideoPopup .video-steps-tab-title button:last-child:after,
  .addVideoPopup .video-steps-tab-title button:nth-child(4n):after,
  .mobile-menu .ant-menu li.ant-menu-item-selected:after {
    opacity: 0
  }

  .resultShow .chart-box {
    margin-bottom: 20px
  }

  .upgrade-with-us {
    padding: 0 0 13px
  }

  body .layout-left .top-section-inner {
    padding-bottom: 25px
  }

  html>body .upgrade-with-us a {
    font-size: 14px !important
  }

  .auth-copyrights.sidebarFooter a {
    font-size: 14px
  }

  html .upgrade-with-us .fa {
    font-size: 18px
  }

  .text-radio>.container {
    margin-right: 10px;
    width: 55px;
    min-width: 55px;
    font-size: 13px
  }

  .biolink-preivew-mobile-screen .smart-link-mibile-bottom {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding-bottom: 0 !important
  }

  .biolink-preivew-mobile-screen .smart-link-mibile-bottom .social-smart-link-tab {
    margin-top: auto;
    position: relative;
    margin-bottom: 0
  }

  .biolink-preivew-mobile-screen .smart-link-mibile-bottom .social-smart-link-tab:after {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #302d31;
    content: "";
    height: 100%;
    width: 238%;
    top: 0;
    left: 0
  }

  .biolink-preivew-mobile-screen .smart-link-mibile-bottom .have-social-links {
    position: relative;
    z-index: 1
  }

  .slider-left img.mb-15 {
    margin-bottom: 10px;
    max-width: 150px
  }

  .mobile-screen-scroll-main {
    overflow: auto
  }

  .mobile-screen-scroll {
    width: 640px
  }

  .close-button,
  .close-button-block {
    width: 40px;
    position: relative;
    overflow: hidden
  }

  .global-form .ant-select-arrow {
    right: 1px;
    top: 20px
  }

  .desktop-hide {
    display: inline-block
  }

  .desktop-show,
  .leftMenu,
  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn:before,
  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.mobile-hide,
  .rightMenu {
    display: none
  }

  .ant-menu .ant-menu-item.mobile-show {
    display: block !important;
    top: -1px
  }

  .ant-btn.barsMenu {
    display: inline-block !important;
    line-height: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
    padding: 0;
    background: 0 0 !important;
    font-size: 18px
  }

  .ant-drawer-left.ant-drawer-open,
  .ant-drawer-right.ant-drawer-open {
    z-index: 999999999
  }

  html body li.ant-menu-item.cta-btn {
    font-size: 16px !important
  }

  html body .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn {
    border-bottom: 1px solid #1d2a3b !important;
    background: 0 0 !important
  }

  .mobile-menu .ant-drawer-header {
    padding: 13px 24px
  }

  html body .mobile-menu .ant-drawer-header img {
    max-width: 86px
  }

  .mobile-menu .ant-drawer-body {
    padding: 0
  }

  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item,
  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-submenu {
    line-height: normal !important;
    height: auto !important;
    border-bottom: 1px solid #1d2a3b !important;
    padding: 10px 20px !important;
    margin: 0 !important;
    color: #fff !important
  }

  .ant-menu .ant-menu-item.right-align.login {
    right: 0 !important
  }

  html body .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item:last-child {
    border-bottom: 1px solid #1d2a3b !important
  }

  html body .mobile-menu .ant-drawer-body .close-menu {
    padding: 0 !important;
    background: 0 0;
    border: 0 !important;
    position: absolute;
    top: 7px;
    right: 12px;
    color: #fff;
    font-size: 35px
  }

  .close-button>div,
  html body .mobile-menu .ant-drawer-wrapper-body {
    position: relative
  }

  .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn {
    border-radius: 0 !important;
    border: 0 !important
  }

  .mobile-menu .ant-menu {
    border: 0
  }

  .mobile-menu .ant-menu li {
    float: left;
    width: 100%;
    height: auto !important;
    border-top: 1px solid #e8e8e8;
    margin: 0 !important
  }

  .mobile-menu .ant-menu li a {
    padding: 2px 0
  }

  .mobile-menu .ant-drawer-header {
    background: #000;
    border: 0;
    border-radius: 0 !important
  }

  .mobile-menu .ant-drawer-header img {
    max-width: 150px
  }

  .mobile-menu .ant-menu li.ant-menu-item-selected a,
  .mobile-menu .ant-menu-item>a:hover {
    color: #000
  }

  html body .mobile-menu .ant-menu li.ant-menu-item-selected {
    background: #000 !important;
    color: #fff !important;
    top: -.9999px
  }

  html body .mobile-menu .ant-drawer-body .ant-menu .ant-menu-item.cta-btn.ant-menu-item-selected {
    background: #000 !important
  }

  html body .mobile-menu .ant-menu li.ant-menu-item-selected a {
    color: #fff !important;
    background: 0 0 !important
  }

  .mobile-menu .close-menu {
    position: absolute;
    right: 10px;
    padding: 0;
    top: 11px;
    border: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  .close-button {
    display: block;
    height: 40px
  }

  .close-button-block {
    height: 20px
  }

  .close-button-block:after,
  .close-button-block:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(55% - 4px);
    display: block;
    width: 4px;
    height: 25px;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    background: #fff;
    transition: 280ms ease-out
  }

  .close-button-block:last-of-type {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .close-button .in .close-button-block:before {
    transition-delay: 280ms;
    -webkit-transform: translateX(20px) translateY(-20px) rotate(45deg);
    transform: translateX(20px) translateY(-20px) rotate(45deg)
  }

  .close-button .in .close-button-block:after {
    transition-delay: 280ms;
    -webkit-transform: translateX(-22px) translateY(-22px) rotate(-45deg);
    transform: translateX(-22px) translateY(-22px) rotate(-45deg)
  }

  .close-button .out {
    position: absolute;
    top: 0;
    left: 0
  }

  .close-button .out .close-button-block:before,
  .close-button:hover .in .close-button-block:before {
    -webkit-transform: translateX(-5px) translateY(5px) rotate(45deg);
    transform: translateX(-5px) translateY(5px) rotate(45deg)
  }

  .close-button .out .close-button-block:after,
  .close-button:hover .in .close-button-block:after {
    -webkit-transform: translateX(5px) translateY(5px) rotate(-45deg);
    transform: translateX(5px) translateY(5px) rotate(-45deg)
  }

  .close-button:hover .out .close-button-block:before {
    -webkit-transform: translateX(-20px) translateY(20px) rotate(45deg);
    transform: translateX(-20px) translateY(20px) rotate(45deg)
  }

  .close-button:hover .out .close-button-block:after {
    -webkit-transform: translateX(20px) translateY(20px) rotate(-45deg);
    transform: translateX(20px) translateY(20px) rotate(-45deg)
  }

  .eyePage .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img>img,
  .video-btn-added.flex-center>div {
    width: 100%
  }

  .find-more-btn {
    margin-top: 10px
  }

  html body .mobile-view-show-preview .smart-link-mibile-bottom {
    height: auto !important;
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
  }

  html body .mobile-view-show-preview .smart-link-right-mibile-top-img {
    height: 770px;
    overflow: auto;
    left: 26px
  }

  html body .mobile-view-show-preview .privacy-policy-mobile,
  html body .mobile-view-show-preview .social-smart-link-tab {
    position: relative;
    width: 100%
  }

  html body .mobile-view-show-preview .privacy-policy-mobile {
    position: relative;
    width: 95%
  }

  .eyePage .button-baselayout-box-itunes .mobile-social-icons img,
  .eyePage .button-spotify-box-itunes .mobile-social-icons img {
    max-width: 140px !important;
    max-height: 40px !important
  }

  html body .mobile-view-show-preview .light-theme-title {
    padding-bottom: 0 !important
  }

  html body .mobile-view-show-preview .light-theme-title .social-smart-link-tab {
    background: #302d31;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    position: relative
  }

  html body .mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile {
    padding-bottom: 0 !important;
    overflow-x: hidden
  }

  .eyePage .have-social-links:after,
  .mobile-preview-show .light-theme-title .top-title:after,
  html body .mobile-view-show-preview .light-theme-title .social-smart-link-tab:after {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #302d31;
    content: "";
    height: 100%;
    width: 218%;
    top: 0;
    left: 0
  }

  html body .eyePage .mobile-view-show-preview .social-smart-link-tab {
    margin-bottom: 0 !important
  }

  .mobile-preview-show .light-theme-title .top-title {
    background: #302d31 !important;
    position: relative;
    padding: 10px 0 5px
  }

  .mobile-preview-show .light-theme-title .top-title h5,
  .mobile-preview-show .light-theme-title .top-title p {
    position: relative;
    color: #fff !important;
    z-index: 1
  }

  .eyePage .smart-link-right-mibile-top-img,
  .eyePage .smart-link-right-mibile-top-img>img {
    border-radius: 0 !important
  }

  .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom,
  html body .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile {
    width: 100% !important;
    max-width: 100% !important;
    padding-top: 0 !important
  }

  .mobile-view-show-preview .social-smart-link-tab a {
    margin: 5px
  }

  .mobile-social-show1 {
    margin-top: 0 !important
  }

  .privacy-policy-mobile ul li a {
    font-size: 13px;
    padding: 10px
  }

  .eyePage .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img img {
    width: 93.5%
  }

  html body .eyePage .mobile-view-show-preview .smart-link-right-mibile-top-img {
    height: auto
  }

  html body .eyePage .mobile-view-show-preview .privacy-policy-mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%
  }
}

.light .after-scroll-add-bottom {
  background: #fff;
  width: 93% !important
}

.light .smart-link-right-mibile-top-img {
  left: 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 60px
}

@media (max-width:355px) {
  .light .smart-link-right-mibile-top-img {
    left: 17px
  }

  .light .after-scroll-add-bottom,
  .light .smart-link-right-mibile-top-img>img {
    width: 97% !important
  }

  .light .after-scroll-add-bottom {
    width: 93% !important
  }
}

.leptop-device-preview-row-desktop.viewpageurl .desktop-middle-img .mobile-social-icons img {
  object-fit: initial
}

html body .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-right-section .smart-link-mibile-bottom {
  height: 400px
}

html body .leptop-device-preview-row-desktop.viewpageurl .leptop-device-preview-top-img {
  height: 434px !important;
  width: 550px !important
}

html body .mobile-view-show-preview.viewpagelink-mobile-view .social-smart-link-tab img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 100% !important;
  margin: 7px
}

.leptop-device-preview-right-section .social-smart-link-tab {
  position: relative;
  margin: 0;
  left: 0;
  padding: 10px 15px 5px
}

.leptop-device-preview-right-section,
.leptop-device-preview-right-section.preview-right .leptop-device-preview-right-section {
  height: 309px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none
}

.leptop-device-preview-right-section {
  height: 434px;
  overflow-x: hidden
}

.leptop-device-preview-right-section.preview-right .leptop-device-preview-right-section::-webkit-scrollbar,
.leptop-device-preview-right-section::-webkit-scrollbar {
  display: none
}

.leptop-device-preview-man-row .smart-link-mibile-bottom,
.leptop-device-preview-right-section .smart-link-mibile-bottom {
  height: auto !important;
  padding-bottom: 10px
}

.leptop-device-preview-right-section .privacy-policy-mobile,
.leptop-device-preview-row-desktop.viewpageurl .social-smart-link-tab {
  bottom: initial
}

@media (max-width:400px) {
  html body .addVideoMain .scrollbarsDiv {
    height: 300px !important
  }

  .checkBoxVideoTop iframe,
  .checkBoxVideoTop video {
    height: 120px !important
  }

  .summaryMain>div>div:last-child {
    width: 62%;
    padding-left: 10px;
  }

  .summaryMain>div>div:first-child {
    width: 38%;
    letter-spacing: .5px;
  }

  html .shown-uploaded-image {
    height: 240px !important;
    max-height: initial !important;
    min-height: initial !important
  }

  .after-scroll-add {
    height: 757px
  }

  .after-scroll-add-bottom {
    min-height: 359px
  }

  .smart-link-right-row-mibile>img {
    height: 546px;
    max-width: 270px !important;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
  }

  html body .after-scroll-add {
    height: 519px;
    width: 254px
  }

  .smart-link-mibile-bottom.secend-section-mibile .button-spotify-row>div button {
    padding: 5px 8px;
    font-size: 14px;
    min-width: 75px
  }

  .light .smart-link-right-mibile-top-img {
    left: 55px
  }

  .mobile-view-show-preview .smart-link-right-mibile-top-img {
    width: 266px
  }

  .mobile-view-show-preview .smart-link-mibile-bottom {
    left: 28px
  }

  .mobile-view-show-preview .bioLink-desktopPreview-in-mobile.smart-link-mibile-bottom {
    left: 0
  }

  .mobile-preview-show .smart-link-right-mibile-top-img img,
  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-mibile-bottom,
  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img img {
    width: 100%
  }

  .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img {
    width: 100%;
    left: 0
  }

  .mobile-view-show-preview.viewpagelink-mobile-view.smart-link-right.mobile-view-show-preview.viewpagelink-mobile-view .smart-link-mibile-bottom {
    margin-bottom: 0;
    overflow-x: hidden
  }

  .mobile-view-show-preview.viewpagelink-mobile-view.smart-link-right.mobile-view-show-preview.viewpagelink-mobile-view .privacy-policy-mobile.link-view-page-privacy {
    width: 100%;
    border-radius: 0
  }

  .leptop-device-preview .mobile-view-show-preview.viewpagelink-mobile-view {
    margin-top: 0 !important
  }

  .eyePage-mobile-image {
    max-height: initial !important;
    height: auto !important;
    object-fit: contain !important;
    width: 100% !important
  }

  .light .after-scroll-add-bottom,
  .light .smart-link-right-mibile-top-img>img,
  html body .light .after-scroll-add-bottom {
    width: 98% !important
  }

  html body .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom,
  html body .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile {
    padding-top: 0 !important
  }

  .top-title {
    padding-top: 10px
  }

  html body .light-social-media-section {
    margin-bottom: 0 !important
  }

  html body .light .light-social-media-section+.privacy-policy-mobile {
    bottom: initial
  }

  .eyePage .mobile-view-show-preview.viewpagelink-mobile-view .smart-link-right-mibile-top-img {
    left: 50% !important;
    height: auto
  }

  .eyePage .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom {
    width: 100% !important
  }

  .smart-link-right-mibile-top-img.after-scroll-add {
    left: 52%;
    width: 251px;
    height: 519px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 20px 40px 20px 20px
  }

  html body .mobile-view-show-preview .smart-link-right-mibile-top-img {
    left: 50%;
    width: 237px;
    height: 519px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 20px
  }

  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom,
  .smart-link-right.mobile-view-show-preview .smart-link-mibile-bottom.secend-section-mibile {
    width: 236px !important;
    padding: 10px 15px;
    overflow-x: hidden
  }

  .light .after-scroll-add-bottom .mobile-social-icons,
  html body .mobile-view-show-preview .mobile-social-icons {
    margin-right: 10px !important
  }

  html body .mobile-view-show-preview .secend-section-mibile .privacy-policy-mobile {
    width: 100%;
    bottom: -3px;
    border-radius: 0
  }

  html body .mobile-view-show-preview .privacy-policy-mobile {
    bottom: 0;
    width: 100%;
    border-radius: 0
  }

  .social-smart-link-tab img {
    margin: 0 !important
  }

  .after-scroll-add-bottom .social-smart-link-tab {
    position: relative;
    width: 100%
  }

  .after-scroll-add-bottom .privacy-policy-mobile {
    position: relative;
    width: 95%;
    border-radius: 0
  }

  .light .after-scroll-add-bottom .privacy-policy-mobile {
    position: relative;
    width: 100%;
    border-radius: 0
  }

  html body .after-scroll-add-bottom .smart-link-mibile-bottom {
    padding-bottom: 5px
  }

  .light .smart-link-right-mibile-top-img.after-scroll-add {
    height: 512px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 240px;
    left: 51%
  }

  html body .light .privacy-policy-mobile {
    bottom: -7px
  }
}

@media (max-width:382px) {
  .light .mobile-image-none .smart-link-mibile-bottom.secend-section-mibile {
    height: auto
  }

  .mobile-view-show-preview .social-smart-link-tab a {
    margin: 5px
  }

  .mobile-view-show-preview .social-smart-link-tab a img {
    margin: 0
  }
}

.mobile-view-show-preview .social-smart-link-tab a {
  box-shadow: rgba(0, 0, 0, .35) 0 5px 15px;
  border-radius: 100%;
  width: 30px;
  height: 30px
}

.preview-light-theme .light-theme-title {
  padding: 10px;
  float: left;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  background: #141414 !important;
  margin-bottom: 10px;
  text-align: center
}

.form-input-height .form-field input,
.form-input-height .form-field textarea,
.form-input-height select,
.itune-change-left .ant-btn {
  color: #2b2b2b !important;
  font-size: 14px;
  font-weight: 700 !important
}

.preview-light-theme .light-theme-title p {
  margin-bottom: 0
}

.preview-light-theme .social-smart-link-tab a {
  box-shadow: rgba(0, 0, 0, .35) 0 5px 15px;
  border-radius: 100%;
  margin: 5px !important
}

.eyeView-light-right .preview-title,
html body .light-mobile-device.smart-link-mibile-bottom.secend-section-mibile .smart-title-section,
html body .preview-light-theme .light-theme-title {
  background: #302d31 !important;
  position: relative
}

.eyeView-light-right .preview-title.text-white h5,
.eyeView-light-right .preview-title.text-white p,
html body .light-mobile-device.smart-link-mibile-bottom.secend-section-mibile .smart-title-section h5,
html body .light-mobile-device.smart-link-mibile-bottom.secend-section-mibile .smart-title-section p,
html body .preview-light-theme .light-theme-title h5,
html body .preview-light-theme .light-theme-title p {
  position: relative;
  z-index: 1;
  color: #fff !important
}

.eyeView-light-right .preview-title:after,
html body .light-mobile-device.smart-link-mibile-bottom.secend-section-mibile .smart-title-section:after,
html body .preview-light-theme .light-theme-title:after {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #302d31;
  content: "";
  height: 100%;
  width: 218%;
  top: 0;
  left: 0
}

html body .preview-light-theme .light-theme-title:after {
  width: 230%
}

.light-social-media-section {
  background: #302d31 !important;
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 27px !important
}

.light-social-media-section .social-media-div {
  padding: 7px 2px;
  display: inline-block;
  line-height: normal
}

.light-social-media-section+.privacy-policy-mobile {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.preview-light-theme .social-smart-link-tab {
  background: #302d31;
  width: 100%
}

html body .have-social-links {
  padding: 12px 0 !important
}

.biolink-listingPage .smart-link-new-listing-page-box {
  flex-direction: column;
  text-align: center;
  padding: 30px;
  float: none;
  display: inline-block
}

.biolink-listingPage .smart-link-new-listing-page-box .smart-link-new-listing-page-box-left-img {
  padding: 0 0 15px
}

.biolink-listingPage .smart-link-new-listing-page-box-left-img,
.biolink-listingPage .smart-link-new-listing-page-box-right-contant {
  float: none;
  display: inline-block
}

.biolink-add-btn {
  margin: 0 0 30px
}

.biolink-add-btn .default-cta .fa {
  font-size: 18px;
  margin-right: 5px
}

.biolink-step-content-2 .smart-link-form-box {
  display: flex;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px
}

.biolink-step-content-2 .smart-link-form-box-left {
  width: 60px;
  min-width: 60px;
  background-color: #e5e6eb;
  display: flex;
  align-items: center;
  justify-content: center
}

.biolink-step-content-2 .smart-link-form-box-right {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  background-color: #fff;
  padding: 17px 17px 5px;
  width: 100%
}

.form-input-height .form-field input,
.form-input-height select {
  padding: 9px 15px
}

.form-input-height .select-smart-link:after {
  top: 50%;
  font-size: 24px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 7px
}

.form-input-height .form-field input::-webkit-input-placeholder,
.form-input-height .form-field textarea::-webkit-input-placeholder {
  color: #979797 !important
}

.form-input-height .form-field input::-moz-placeholder,
.form-input-height .form-field textarea::-moz-placeholder {
  color: #979797 !important
}

.form-input-height .form-field input:-ms-input-placeholder,
.form-input-height .form-field textarea:-ms-input-placeholder {
  color: #979797 !important
}

.form-input-height .form-field input:-moz-placeholder,
.form-input-height .form-field textarea:-moz-placeholder {
  color: #979797 !important
}

.action-dd-raw {
  display: flex;
  width: 100%;
  justify-content: space-between
}

.action-dd-raw .dd1 {
  width: 120px
}

.action-dd-raw .dd2 {
  width: 39%;
  margin: 0 10px
}

.action-dd-raw .dd3 {
  width: 39%
}

.action-dd-raw .biolink-delete {
  width: 50px !important;
  min-width: 50px !important;
  height: 50px !important;
  min-height: 50px !important;
  margin-left: 10px;
  position: relative;
  z-index: 1
}

.action-dd-raw .biolink-delete .ant-btn {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #ff5a5a;
  color: #fff;
  border: 0 !important;
  font-size: 18px;
  -webkit-transform: scale(.7);
          transform: scale(.7)
}

.ColorPickerDiv input,
.ColorPickerDiv textarea {
  width: 100% !important;
  padding-right: 80px !important
}

.ColorPickerDiv.itune-change-right input {
  padding-right: 150px !important
}

.ColorPickerDiv .ColorPickerInner {
  position: absolute;
  top: 9px;
  right: 10px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  display: flex;
  align-items: center;
  width: 60px;
  background: #e5e6eb;
  padding: 0;
  border-radius: 5px
}

.ColorPickerDiv .ColorPickerInner.box2 {
  right: 75px
}

.ColorPickerDiv .ColorPickerInner>div {
  width: 50%;
  text-align: center;
  font-size: 12px;
  color: #babbc1;
  padding: 0
}

.ColorPickerDiv .ColorPickerInner .rc-color-picker-wrap {
  height: 20px;
  -webkit-transform: scale(.6);
          transform: scale(.6);
  padding: 0
}

.ColorPickerDiv .rc-color-picker-trigger {
  border: 0;
  border-radius: 100%;
  box-shadow: none
}

.large.ColorPickerDiv .ColorPickerInner {
  position: relative;
  top: auto;
  right: auto;
  margin-left: 10px;
  background: #fff;
  height: 48px;
  padding: 0 5px;
  width: 70px
}

.itune-change-left {
  width: 150px;
  margin-right: 15px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  background-color: #fff;
  padding: 7px 7px 7px 15px;
  border-radius: 10px
}

.itune-change-left .avatar-upload img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  box-shadow: none;
  border-radius: 0
}

.itune-change-left .ant-btn {
  border: 0 !important;
  font-style: italic;
  padding-right: 0 !important;
  box-shadow: none !important
}

.biolink-listingPage .smart-link-new-listing-page-box-left-img img {
  object-fit: contain
}

.form-input-height select {
  color: #979797 !important;
  padding-right: 30px;
  overflow: hidden
}

@media (min-width:1200px) {
  .sqrbx {
    opacity: 0;
    display: none
  }

  body .leftSidebarPadding {
    padding-left: 270px !important
  }

  .banner-updated-section .banner-updated-slider-main.new .slick-slide {
    opacity: 1
  }

  .desktop-large-img .video-step-left {
    width: 35% !important
  }

  .desktop-large-img .video-step-right {
    width: 65% !important
  }

  .desktop-middle-img img {
    width: 61%
  }
}

@media only screen and (min-width:575px) {
  .extra-added-field {
    position: relative;
    left: 120px;
    margin-top: -65px !important;
    width: calc(100% - 170px) !important
  }

  .extra-added-field-inner {
    flex-direction: column
  }

  .extra-added-field.action-dd-raw .dd2 {
    width: 100%;
    max-width: 100%
  }
}

@media (min-width:576px) and (max-width:767px) {
  .extra-added-field {
    margin-top: -61px !important
  }
}

.step2-biolink-social-inner a {
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 0 0;
  font-size: 17px;
  font-weight: 700;
  align-items: center;
  display: flex
}

.step2-biolink-social-inner a img {
  width: 30px;
  min-width: 30px;
  height: 30px;
  object-fit: contain;
  -webkit-filter: none !important;
          filter: none !important;
  visibility: visible !important
}

.step2-biolink-social-inner a span {
  padding-left: 10px;
  line-height: normal
}

.step2-biolink-social-inner img {
  height: auto;
  max-height: initial;
  width: auto;
  z-index: 1;
  margin: 0
}

.step2-biolink-email-form input {
  width: 100% !important;
  border: 0 !important;
  background: #fff;
  height: 50px;
  margin: 0 !important;
  padding: 10px 110px 10px 10px
}

.step2-biolink-email-form.basic input {
  background: 0 0;
  border: 1px solid #818181 !important;
  color: #818181 !important
}

.step2-biolink-email-form .form-field {
  position: relative;
  margin: 0 !important;
  border-radius: 5px
}

.step2-biolink-email-form .form-field .ant-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: 0 !important;
  box-shadow: none !important;
  background: #027fc5 !important;
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center
}

.step2-biolink-email-form .form-field .ant-btn span {
  width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap
}

.step2-biolink-email-form.basic input::-webkit-input-placeholder {
  color: #818181 !important
}

.step2-biolink-email-form.basic input::-moz-placeholder {
  color: #818181 !important
}

.step2-biolink-email-form.basic input:-ms-input-placeholder {
  color: #818181 !important
}

.step2-biolink-email-form.basic input:-moz-placeholder {
  color: #818181 !important
}

.eyePage .have-social-links {
  margin-top: 25px !important
}

.image-note-main {
  flex-direction: column
}

.image-note-main.avatar-upload .avatar-edit {
  position: relative;
  top: -5px;
  left: -30px
}

.validation-raw .srv-validation-message {
  position: absolute;
  left: 0;
  top: 47px;
  font-size: 13px
}

.validation-raw textarea+.srv-validation-message {
  top: 142px
}

@media (max-width:992px) and (min-width:576px) {
  html body .validation-raw .srv-validation-message {
    left: 122px
  }

  html body .biolink-step-one .form-field .label,
  html body .smartlink-step-two .form-field .label {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    font-size: 17px
  }
}

@media (max-width:575px) {
  html .pickerDivLeft .srv-validation-message {
    bottom: -90px
  }

  .pickerDivLeft .ColorPickerInner {
    margin: 0 0 0 10px !important
  }

  .desktopBgPreviewBtn {
    margin: 10px 0 0 !important
  }

  html body .validation-raw .srv-validation-message {
    left: 0;
    top: 78px
  }

  html body .biolink-step-one .form-field .label,
  html body .smartlink-step-two .form-field .label {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 17px
  }

  html body .validation-raw textarea+.srv-validation-message {
    top: 178px
  }
}

.biolink-eyeview-laptop-screen .privacy-policy-mobile {
  margin-top: initial !important
}

.biolink-eyeview-laptop-screen .social-smart-link-tab {
  margin-top: auto !important
}

.biolink-eyeview-laptop-screen .smart-link-mibile-bottom {
  border-left: 0 !important
}

.biolink-eyeview-laptop-screen .preview-title,
.biolink-eyeview-mobile-screen .preview-title {
  background: 0 0 !important;
  padding-left: 10px;
  padding-right: 10px
}

.biolink-eyeview-laptop-screen .preview-title:after,
.biolink-eyeview-mobile-screen .preview-title:after,
.single-post-page .post-content p br {
  display: none
}

html body .overlay-match::after {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .45)
}

.text-underline {
  border-bottom: 1px solid #000 !important
}

.post-image.bgimg-main {
  background-size: contain !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-image: none !important;
  padding: 20px
}

.post-image.bgimg-main img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  height: auto !important;
  width: auto !important
}

.single-post-page .auth-copyrights.relative.single-post-social-icons {
  background: 0 0;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  padding: 0 0 0 20px !important;
  float: left;
  width: 45px;
  margin-left: -20px
}

.single-post-page .auth-copyrights.relative.single-post-social-icons ul {
  display: flex;
  justify-content: flex-end;
  flex-direction: column
}

.single-post-page .auth-copyrights.relative.single-post-social-icons ul li a {
  border-radius: 5px;
  margin: 0 0 7px !important
}

.single-post-right {
  width: calc(100% - 25px);
  padding-left: 30px
}

.auth-copyrights.relative ul li:first-child a:before,
.single-post-page .auth-copyrights.relative ul li:first-child a {
  color: #fff;
  background: #1877f2
}

.auth-copyrights.relative ul li:nth-child(2) a:before,
.single-post-page .auth-copyrights.relative ul li:nth-child(2) a {
  color: #fff;
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)
}

.auth-copyrights.relative ul li:nth-child(3) a:before,
.single-post-page .auth-copyrights.relative ul li:nth-child(3) a {
  color: #fff;
  background: #1da1f2
}

.auth-copyrights.relative ul li:nth-child(4) a:before,
.single-post-page .auth-copyrights.relative ul li:nth-child(4) a {
  color: #fff;
  background: #0a66c2
}

html body .single-post-page .post-box h1 {
  font-size: 35px;
  padding-bottom: 20px !important
}

.single-post-page .post-content h2 {
  font-size: 22px
}

.single-post-page .ql-editor {
  padding: 10px 0 0
}

.blog-listing-main .post-box {
  transition: .4s
}

.blog-listing-main .post-box:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px)
}

.blog-view-page.single-post-page .post-box .post-image img {
  max-height: 500px
}

.blog-listing-main .post-box .post-content h4 a:first-child {
  padding-right: 10px
}

.blog-listing-main .post-box .post-content h4 a:last-child {
  color: #027fc5;
  font-size: 18px;
  position: relative
}

.tooltip-main .tooltip-text {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
  min-width: 100px;
  text-align: center;
  padding: 5px;
  letter-spacing: 1px;
  right: 0;
  top: -35px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  border-radius: 5px;
  background: #2ea5c2
}

.tooltip-main .tooltip-text:after {
  width: 0;
  position: absolute;
  right: 1px;
  bottom: -6px;
  content: "";
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #43b7c1
}

.tooltip-main:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
  top: -40px
}

.single-post-modal.how-it-work-light-box.premium-plan-modal .anticon-close {
  top: 0 !important
}

.h4-bottom-border {
  border-bottom: 1px solid #132638 !important
}

.single-post-page .ql-editor p+p {
  margin-top: 15px !important
}

.single-post-page .ql-editor p {
  float: left !important;
  width: 100% !important
}

.titleWithCta {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  flex-wrap: wrap
}

.titleWithCta h3 {
  margin: 0 15px 0 0 !important
}

.titleWithCta .find-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.mb-20 {
  margin: 0 0 20px
}

.global-form .form-field.small .input-edit-btn.green {
  background: green;
  display: flex;
  justify-content: center;
  align-items: center
}

.input-edit-btn.green .ant-btn-loading-icon {
  -webkit-transform: scale(.7);
          transform: scale(.7)
}

.pickerDivLeft .srv-validation-message {
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 120%
}

.ColorPickerDiv.mb-20 {
  margin-bottom: 25px !important
}

.insight-bar.exportButton {
  margin-left: 10px;
  color: #fff;
  font-size: 13px
}

.buttonSpace .ant-btn {
  margin-left: 15px !important
}

html body .phone_number input::-webkit-inner-spin-button,
html body .phone_number input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important
}

html body .phone_number input {
  -moz-appearance: textfield !important
}

.client-says-section h1 span.badge,
html body .lightYellow {
  background-color: #fff4a3 !important;
  color: #000 !important
}

.topDiv .have-accnt {
  margin-bottom: -5px !important;
  margin-top: 2px !important
}

@media (max-width:500px) {
  html .cartTitle .close {
    top: -1px;
  }

  html .cartBox.updated h5 {
    margin-bottom: -3px !important;
  }

  html body .shiftToTop.leftSidebarPadding,
  .ant-layout-sider-collapsed+.layout-right .leftSidebarPadding.shiftToTop {
    padding-left: 35px !important;
  }
}

.leftSidebarMenu ul li a .menuText {
  font-weight: 300;
}

@media (min-width:1200px) {
  .leftSidebarMenu ul li a .menuText {
    font-size: 16px;
    letter-spacing: 2px;
  }

  .sidebarFooter.bottom a {
    font-size: 12px !important;
  }

  .upgrade-with-us {
    padding: 0 0 15px;
  }

  .menuIcon {
    min-width: 28px;
    margin-right: 15px;
    position: relative;
    top: -3px;
  }

  .menuIcon img {
    max-width: 30px;
  }
}

@media (min-width:1500px) {

  .resultShow .chart-box .graph-content h4,
  .resultShow .chart-box .graph-content h5,
  .resultShow .right-section-dashboard .label,
  html body .resultShow .left-section-dashboard .label {
    letter-spacing: 11px;
  }
}

@media (min-width:1500px) and (max-width:1600px) {

  .resultShow .chart-box .graph-content h5,
  html body .resultShow .left-section-dashboard .label {
    letter-spacing: 8px;
  }
}
